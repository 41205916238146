interface Props {
  icon?: string
  text: string
  value: string
  description: string
  difference?: IStatDifference
}

const StatCard = ({icon, text, value, description, difference}: Props) => (
  <div className="card card-body">
    <div className="d-flex align-items-center">
      <div className="avatar">
        <div className="avatar-title rounded bg-primary-subtle ">
          <i className={`bx ${icon ? icon : 'bx-check-shield'} font-size-24 mb-0 text-primary`}></i>
        </div>
      </div>
      <div className="flex-grow-1 ms-3"><h6 className="mb-0 font-size-15">{text}</h6></div>
    </div>
    <h4 className="mt-4 pt-1 mb-0 font-size-22">{value}
      {difference &&
        <span className={`${difference.type === 'INCREASE' ? 'text-success' : 'text-danger'} font-size-13`}>
          <i className={`bx ${difference.type === 'INCREASE' ? 'bx-caret-up' : 'bx-caret-down'}`}></i>
          {difference.value}%
        </span>
      }
    </h4>
    <div className="d-flex mt-1 bx-caret- align-items-end overflow-hidden">
      <div className="flex-grow-1"><p className="text-muted mb-0 font-size-12 text-truncate">{description}</p></div>
    </div>
  </div>
)

export default StatCard;
