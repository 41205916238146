import {XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Area, AreaChart} from 'recharts';
import {AxisDomain} from "recharts/types/util/types";

import CustomTooltip from "./CustomTooltip";
import {toTitleCase} from "../../utils/helpers/logicHelper";

interface Props {
  name: string
  data: any
  yDomain: AxisDomain
  yKey: IYKey[]
}

const AreaCharts = ({name, data, yDomain, yKey}: Props) => {

  const XAxisFormatter = (value: string): string => toTitleCase(value);

  const YAxisFormatter = (value: number): string => {
    return new Intl.NumberFormat("en-US", {notation: "compact", compactDisplay: "short"}).format(value)
  };

  return (
    <div className="card card-body pb-0">
      <h5 className="card-title mb-4">{name}</h5>

      {/*<div className="row mb-3">
        <div className="col-md-4">
          <h4 className="font-size-18">$23,590.00</h4>
        </div>
        <div className="col-md-8">
          <ul className="list-inline main-chart text-md-end mb-0">
            <li className="list-inline-item chart-border-left me-0 border-0">
              <h4 className="text-primary font-size-18">$584k
                <span className="text-muted d-inline-block font-size-14 align-middle ms-2">Incomes</span>
              </h4>
            </li>
            <li className="list-inline-item chart-border-left me-0">
              <h4 className="font-size-18">$497k
                <span className="text-muted d-inline-block font-size-14 align-middle ms-2">Expenses</span>
              </h4>
            </li>
          </ul>
        </div>
      </div>*/}

      <div style={{width: '100%', height: 300}}>
        <ResponsiveContainer>
          <AreaChart width={500} height={300} data={data} margin={{top: 10, right: 5, left: 5, bottom: 10}}>
            <defs>
              {yKey.map((eachKey: IYKey, index: number) =>
                <linearGradient key={index} id={eachKey.key} x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={eachKey.stroke} stopOpacity={0.8}/>
                  <stop offset="95%" stopColor={eachKey.stroke} stopOpacity={0}/>
                </linearGradient>
              )}
            </defs>
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="name" tickFormatter={(value) => XAxisFormatter(value)}/>
            <YAxis type="number" domain={yDomain} allowDataOverflow={false}
                   tickFormatter={(value) => YAxisFormatter(value)}/>
            <Tooltip content={<CustomTooltip/>}/>
            <Legend/>
            {yKey.map((eachKey: IYKey, index: number) =>
              <Area key={index} type="monotone" name={eachKey.name} dataKey={eachKey.key} stroke={eachKey.stroke}
                    fillOpacity={1} fill={`url(#${eachKey.key})`}/>
            )}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default AreaCharts;
