import {createRoot, Root} from 'react-dom/client';
import {Provider} from "react-redux";
import {GoogleOAuthProvider} from "@react-oauth/google";

import "./assets/scss/style.scss";

import App from './App';
import reportWebVitals from './reportWebVitals';
import {store} from "./store";
import {AppKeys} from "./utils/helpers/constants";
import {onRTKPersist} from "./store/slices/auth.slice";

onRTKPersist(store);

const AppRoot = () => (
  <Provider store={store}>
    <GoogleOAuthProvider clientId={AppKeys.GOOGLE_AUTH_KEY}>
      <App/>
    </GoogleOAuthProvider>
  </Provider>
);

const root: Root = createRoot(document.getElementById('root') as HTMLElement);
root.render(<AppRoot/>);
reportWebVitals();
