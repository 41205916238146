import {useState} from "react";
import {Params, useParams} from "react-router-dom";
import {PDFDownloadLink} from '@react-pdf/renderer';
import toast from "react-hot-toast";
import moment from "moment";

import PageLayout from "../../../components/layouts/PageLayout";
import MapHook from "../../../components/hooks/MapHook";
import AssignVehicleModal from "../../../components/modals/AssignVehicleModal";
import TripReceipt from "../../../components/renderers/TripReceipt";
import {formatAmountToString, formatTripDetails, toTitleCase} from "../../../utils/helpers/logicHelper";
import {MenuType} from "../../../utils/helpers/constants";
import {useGetTripQuery, useUpdateTripMutation} from "../../../store/slices/backoffice.slice";

const TripInfo = () => {
  const {reference}: Readonly<Params> = useParams()
  const {data: trip, isLoading, isSuccess} = useGetTripQuery(`${reference}`)
  const [assignRender, setAssignRender] = useState<boolean>(false)
  const [unassignDriver] = useUpdateTripMutation()

  const assignVehicle = () => setAssignRender(true)
  const unassignVehicle = async (): Promise<void> => {
    try {
      let filter: string = `partners/unassign-driver`, body: { trip_id: string } = {trip_id: trip?.data[0].id};
      await unassignDriver({filter, body}).unwrap()
    } catch (err: any) {
      toast.error(err.data.message)
    }
  }

  const GenerateInvoice = () => {
    const tripDetails: { [key: string]: string }[] = formatTripDetails(trip?.data[0]);
    return (
      <PDFDownloadLink document={<TripReceipt trip={trip?.data[0]} tripDetails={tripDetails}/>}
                       fileName="tripreceipt.pdf">
        {({url, loading}) => (
          <a href={url ?? ""} target="_blank" type="button" rel="noreferrer" className="btn btn-primary mt-2 me-1">
            <i className="bx bxs-file-pdf me-2"/>Generate Receipt {loading &&
            <i className="bx bx-loader-circle bx-spin"/>}
          </a>
        )}
      </PDFDownloadLink>
    )
  }

  const assignModal = assignRender && <AssignVehicleModal trip={trip?.data[0]} onchange={() => setAssignRender(false)}/>

  return (
    <PageLayout active={MenuType.TRIPS} loading={isLoading} backEnabled title={`Trip Information - ${reference}`}>
      {assignModal}
      {isSuccess &&
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-6"/>
            <div className="col-md-6">
              {trip.data[0].is_paid && !trip.data[0].is_completed && !trip.data[0].driver &&
                <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                  <button className="btn btn-primary" onClick={assignVehicle}>
                    <i className="bx bx-car me-1"></i> Assign Vehicle
                  </button>
                </div>
              }
              {trip.data[0].driver &&
                <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                  <button className="btn btn-primary" onClick={unassignVehicle}>
                    <i className="bx bx-car me-1"></i> Unassign Vehicle
                  </button>
                </div>
              }
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-5">
                      <MapHook source={trip.data[0].source} destination={trip.data[0].destination}/>
                    </div>
                    <div className="col-md-7">
                      <div className="mt-3 mt-xl-3 ps-xl-5">
                        <h4 className="font-size-20 mb-2">
                          {trip.data[0]?.category_size.category} trip - {trip.data[0].status}
                        </h4>
                        <h2 className="text-primary mt-4 py-2 mb-0">
                          {trip.data[0].quote.currency}{formatAmountToString(trip.data[0].quote.charge)}
                          {trip.data[0].quote.discount_amount &&
                            <del className="text-muted font-size-18 fw-medium ps-1">
                              {trip.data[0].quote.currency}{formatAmountToString(trip.data[0].quote.total_charge)}
                            </del>
                          }
                          {trip.data[0].quote.discount_amount &&
                            <span className="badge bg-danger font-size-10">20% Off</span>}
                        </h2>
                        <div className="row">
                          <div className="col-md-6 mt-3">
                            <h5 className="font-size-14">PickUp Location:</h5>
                            <div className="font-size-13">{trip.data[0].source.address}</div>
                          </div>
                          <div className="col-md-6 mt-3">
                            <h5 className="font-size-14">DropOff Location:</h5>
                            <div className="font-size-13">{trip.data[0].destination.address}</div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 mt-3">
                            <h5 className="font-size-14">Customer Details:</h5>
                            <ul className="list-unstyled font-size-13 ps-0 mb-0 mt-2">
                              <li className="text-muted mb-1 text-truncate">
                                Full Name:&nbsp;
                                {toTitleCase(trip.data[0].user.first_name)} {toTitleCase(trip.data[0].user.last_name)}
                              </li>
                              <li className="text-muted mb-1 text-truncate">Email: {trip.data[0].user.email}</li>
                              <li className="text-muted mb-1 text-truncate">Phone: {trip.data[0].user.phone}</li>
                              {(trip.data[0].confirmation.delivery || trip.data[0].confirmation.arrival) &&
                                <li className="text-muted mb-1">Confirmation
                                  Code: {trip.data[0].confirmation.delivery}
                                </li>
                              }
                            </ul>
                          </div>
                          <div className="col-md-6 mt-3">
                            <h5 className="font-size-14">Driver Details:</h5>
                            {trip.data[0].driver ?
                              <ul className="list-unstyled ps-0 mb-0 mt-2">
                                <li className="text-muted mb-1 text-truncate">
                                  Full Name:&nbsp;
                                  {toTitleCase(trip.data[0].driver?.user.first_name ?? '')} {toTitleCase(trip.data[0].driver?.user.last_name ?? '')}
                                </li>
                                <li className="text-muted mb-1 text-truncate">Email: {trip.data[0].user.email}</li>
                                <li className="text-muted mb-1 text-truncate">Phone: {trip.data[0].user.phone}</li>
                                {(trip.data[0].confirmation.delivery || trip.data[0].confirmation.arrival) &&
                                  <li className="text-muted mb-1">Confirmation
                                    Code: {trip.data[0].confirmation.delivery}
                                  </li>
                                }
                              </ul> :
                              <div className="font-size-13 text-muted">No Driver Assigned</div>
                            }
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 mt-3">
                            <h5 className="font-size-14">Trip Details:</h5>
                            <ul className="list-unstyled font-size-13 ps-0 mb-0 mt-2">
                              <li className="text-muted mb-1 text-truncate">
                                Scheduled Date: {moment(trip.data[0].scheduled_date).format('lll')}
                              </li>
                              <li className="text-muted mb-1 text-truncate">Trip Type: {trip.data[0].trip_type}</li>
                              <li className="text-muted mb-1 text-truncate">Pricing
                                Model: {trip.data[0].system_trip_type}</li>
                              <li className="text-muted mb-1">Vehicle Size: {trip.data[0].category_size.category}</li>
                              <li className="text-muted mb-1 text-truncate">Distance: {trip.data[0].distance} kms</li>
                              <li
                                className="text-muted mb-1 text-truncate">Stops: {trip.data[0].destination.stops?.number}</li>
                            </ul>
                          </div>
                          <div className="col-md-6 mt-3">
                            <h5 className="font-size-14">Payment Details:</h5>
                            <ul className="list-unstyled font-size-13 ps-0 mb-0 mt-2">
                              <li className="text-muted mb-1">
                                Insurance Value: {formatAmountToString(trip.data[0].quote.estimated_value)}
                              </li>
                              <li className="text-muted mb-1">
                                Insurance Premium: {formatAmountToString(trip.data[0].quote.estimated_insurance)}
                              </li>
                              <li className="text-muted mb-1">
                                Charge: {formatAmountToString(trip.data[0].quote.charge)}
                              </li>
                              <li className="text-muted mb-1">
                                Vehicle Commission: {formatAmountToString(trip.data[0].vehicle_payment.vehicle_payment)}
                                {trip.data[0].vehicle_payment.is_paid ?
                                  <span className="badge rounded-pill bg-primary mx-2 font-size-10">Paid</span> :
                                  <span className="badge rounded-pill bg-danger mx-2 font-size-10">Not Paid</span>
                                }
                              </li>
                              <li className="text-muted mb-1">
                                Platform Commission: {formatAmountToString(trip.data[0].quote.platform_fee)}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="d-grid mt-3 float-end">{GenerateInvoice()}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </PageLayout>
  )
}

export default TripInfo;
