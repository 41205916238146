import {ChangeEvent, useState} from "react";
import {NavigateFunction, useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
import Modal from 'react-bootstrap/Modal';

import {useCreateContentMutation} from "../../store/slices/backoffice.slice";

interface Props {
  topic: ITopics
  onchange: () => void
}

const NewContentModal = ({topic, onchange}: Props) => {

  const navigate: NavigateFunction = useNavigate();
  const [show, setShow] = useState<boolean>(true);
  const [title, setTitle] = useState<string>("");
  const [intro, setIntro] = useState<string>("");
  const [createContent, {isLoading}] = useCreateContentMutation()

  const handleClose = (): void => {
    setShow(false);
    onchange();
  }

  const addContent = async (): Promise<void> => {
    try {
      let body: IContentRequest = {topic: topic.topic, title, intro}
      let results = await createContent(body).unwrap()
      handleClose()
      navigate(`/documentation/content/${topic.topic}/${results.data.slug}`)
    } catch (err: any) {
      toast.error(err.data.message)
    }
  }

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton><Modal.Title>Add New Content</Modal.Title></Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12 mb-3">
            <label htmlFor="formrow-email-input" className="form-label">Content Title</label>
            <input type="text" className="form-control" placeholder="Enter Content Title"
                   value={title} onChange={(e: ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
            />
          </div>
          <div className="col-md-12 mb-3">
            <label htmlFor="formrow-email-input" className="form-label">Content Intro</label>
            <textarea rows={4} className="form-control" placeholder="Enter Content Intro"
                      value={intro} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setIntro(e.target.value)}
            />
          </div>
        </div>
        <div className="mt-4 float-end">
          <button onClick={addContent} className="btn btn-md btn-outline-primary ms-1">
            Create Content {isLoading && <i className="bx bx-loader bx-spin"/>}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default NewContentModal;
