import {ColumnDef, ColumnHelper, createColumnHelper} from "@tanstack/react-table";
import moment from "moment";

import {voucherStatusMap} from "../../utils/helpers/mappers";
import {formatAmountToString} from "../../utils/helpers/logicHelper";

const column: ColumnHelper<IVoucher> = createColumnHelper<IVoucher>()

export const VoucherColumns: ColumnDef<IVoucher, string>[] = [
  column.accessor('voucher_number', {
    header: () => <span>Code</span>,
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  column.accessor('payee', {
    header: () => <span>Payee</span>,
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  column.accessor('description.amount', {
    header: () => <span>Amount</span>,
    cell: info => {
      let totalNumber: number = 0;
      for (let description of info.row.original.description) totalNumber = totalNumber + Number(description.amount);
      return <span>NGN {formatAmountToString(totalNumber)}</span>
    },
    footer: info => info.column.id,
  }),
  column.accessor('payee_bank_account', {
    header: () => <span>Account No.</span>,
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  column.accessor('payee_bank', {
    header: () => <span>Bank</span>,
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  column.accessor('scheduled_date', {
    header: () => <span>Created On</span>,
    sortingFn: "datetime",
    cell: info => <span>{moment(info.getValue()).format('LL')}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('status', {
    header: () => <span>Status</span>,
    cell: info => <span>{voucherStatusMap[info.getValue()]['label']}</span>,
    footer: info => info.column.id,
  }),
];
