import {useEffect, useState} from "react";
import {Dispatch} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";

import PageLayout from "../../../components/layouts/PageLayout";
import LeaderboardCard from "../../../components/cards/LeaderboardCard";
import RewardEntryCard from "../../../components/cards/RewardEntryCard";
import AssignPointModal from "../../../components/modals/AssignPointModal";
import {dispatchLeaderboard} from "../../../store/slices/partner.slice";
import {MenuType} from "../../../utils/helpers/constants";
import {useGetPerformanceEntriesQuery} from "../../../store/slices/backoffice.slice";


const DriverPerformance = () => {
  const dispatch: Dispatch = useDispatch();
  const userProfile: IProfile = useSelector((state: IStoreState) => state.auth.userProfile)
  const loadedLeaderboard: boolean = useSelector((state: IStoreState) => state.partner.loadedLeaderboard)
  const leaderboard: ILeaderboard = useSelector((state: IStoreState) => state.partner.leaderboard)
  const date: Date = new Date(), year: number = date.getFullYear(), month: string = moment().format('MMMM')
  const [assignPointRender, setAssignPointRender] = useState<boolean>(false)
  const {data: entries, isLoading} = useGetPerformanceEntriesQuery('');

  useEffect(() => {
    if (!loadedLeaderboard) getLeaderboard().catch(err => console.log(err))
  }, []);

  const getLeaderboard = async (): Promise<void> => await dispatchLeaderboard(dispatch)
  const assignPointModal = assignPointRender && <AssignPointModal onchange={() => setAssignPointRender(false)}/>

  return (
    <PageLayout active={MenuType.SUPPLY} title="Supply Service - Drivers Reward System"
                loading={isLoading || !loadedLeaderboard}>
      {assignPointModal}
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-6"/>
          <div className="col-md-6">
            <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
              {userProfile.permissions?.edit_admin &&
                <button className="btn btn-primary pointer-event" onClick={() => setAssignPointRender(true)}>
                  <i className="bx bxs-bookmark-star me-1"></i> Award Points
                </button>
              }
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <LeaderboardCard title="All Time Leaderboard" performance={leaderboard.lifetime} keyType="total_points"/>
          </div>
          <div className="col-md-4">
            <LeaderboardCard title={`Q${moment().quarter()} Leaderboard`} performance={leaderboard.quarter}
                             keyType="thisQuarter"/>
          </div>
          <div className="col-md-4">
            <LeaderboardCard title={`${month} ${year} Leaderboard`} performance={leaderboard.month} keyType="month"/>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-12">
            <RewardEntryCard entries={entries?.data}/>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default DriverPerformance;
