import {formatAmountToString} from "../../utils/helpers/logicHelper";

interface Props {
  text: string
  data: any[]
}

const StatTableCard = ({text, data}: Props) => (
  <div className="card card-body card-h-100">
    <div className="d-flex align-items-start mb-2">
      <div className="flex-grow-1">
        <h5 className="card-title">{text}</h5>
      </div>
    </div>
    <div className="simplebar-mask">
      <div className="simplebar-offset">
        <div className="simplebar-content-wrapper">
          {data.map((each: any, index: number) =>
            <div key={index} className="d-flex product-box align-items-center rounded my-2">
              <div className="flex-shrink-0 avatar-sm">
                <i className={`bx bx-card font-size-24 avatar-title img-thumbnail ${each.fill} border-0`}/>
              </div>
              <div className="flex-grow-1 ms-3 overflow-hidden">
                <h5 className="mb-1 font-size-16 text-truncate">{each.name}</h5>
                <p className="font-size-12 text-muted fw-semibold mb-0 text-truncate">{each.sub}</p>
              </div>
              <div className="flex-shrink-0 text-end ms-3">
                <h5 className="font-size-16 mb-1">₦{formatAmountToString(each.value)}</h5>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
)

export default StatTableCard;
