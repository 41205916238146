import {ReactElement, useState} from "react";
import {Dispatch} from "@reduxjs/toolkit";
import {Navigate, NavigateFunction, NavLink, useNavigate} from "react-router-dom";
import {CredentialResponse, useGoogleLogin, useGoogleOneTapLogin} from "@react-oauth/google";
import {useDispatch} from "react-redux";
import toast from "react-hot-toast";
import axios, {AxiosResponse} from "axios";

import Logo from "../../assets/images/pngs/logo.png";
import TokenHelper from "../../utils/helpers/tokenHelper";
import {getBaseUrl} from "../../utils/services/axios.service";
import {onAuthentication} from "../../store/slices/auth.slice";


const Login = (): ReactElement => {

  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch = useDispatch(), isLoggedIn: boolean = TokenHelper.authenticationValid();
  const [loading, setLoading] = useState<boolean>(false)

  useGoogleOneTapLogin({
    onSuccess: (response: CredentialResponse): void => {
      authenticateGoogleAuth({token: response.credential, platform: 'GOOGLE_TAP'})
        .catch((err: Error) => console.trace(err))
    },
    onError: () => toast.error(`Error, cannot validate your work email`),
    cancel_on_tap_outside: false,
    use_fedcm_for_prompt: true
  })

  const googleCallbackResponse = useGoogleLogin({
    onSuccess: response => authenticateGoogleAuth({token: response['access_token'], platform: 'GOOGLE'}),
    onError: () => toast.error(`Error, cannot validate your work email`)
  })

  const authenticateGoogleAuth = async (payload: IGoogleLogin): Promise<void> => {
    setLoading(true);
    //TODO: GOOGLE ONE TAP KEEPS FAILING
    axios.post(`${getBaseUrl().cas}auth-service/workspace-auth`, payload)
      .then((response: AxiosResponse): void => {        
        navigate('/');
        onAuthentication(dispatch, response.data.data.access);
      })
      .catch((error: any) => toast.error(error.response.data.message));
    setLoading(false)
  }

  if (isLoggedIn) return <Navigate to={{pathname: "/"}}/>

  return (
    <div className="authentication-bg min-vh-100">
      <div className="bg-overlay bg-light"></div>
      <div className="container">
        <div className="d-flex flex-column min-vh-100 px-3 pt-4">
          <div className="row justify-content-center my-auto">
            <div className="col-md-8 col-lg-6 col-xl-5">

              <div className="mb-4 pb-2">
                <NavLink to="/" className="d-block auth-logo">
                  <img src={Logo} alt="" height="30" className="auth-logo-dark me-start"/>
                  <img src={Logo} alt="" height="30" className="auth-logo-light me-start"/>
                </NavLink>
              </div>

              <div className="card">
                <div className="card-body p-4">
                  <div className="text-center mt-2">
                    <h5>Welcome Back !</h5>
                    <p className="text-muted">Sign in to continue to truQ backoffice</p>
                  </div>

                  <div className="mt-4 text-center">
                    <div className="mt-2">
                      <button className="btn btn-primary w-100" type="button"
                              onClick={() => googleCallbackResponse()}>
                        Sign in with work email {loading && <i className="bx bx-loader-circle bx-spin"/>}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login;
