import {useEffect, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import Select, {SingleValue} from "react-select";
import toast from "react-hot-toast";

import {MockProfile} from "../../mocks/MockProfile";
import {useCreateSignatoryMutation} from "../../store/slices/backoffice.slice";
import {SelectStyles} from "../../utils/helpers/selectStyles";

interface Props {
  staff: IProfile[]
  signatories: ISignatory[]
  onchange: () => void
}

const CreateSignatoryModal = ({staff, signatories, onchange}: Props) => {

  const selectStyles: IDynamicFields = SelectStyles();
  const [show, setShow] = useState<boolean>(true);
  const [newSignatories, setNewSignatories] = useState<IProfile[]>([]);
  const [signatory, setSignatory] = useState<IProfile | null>(MockProfile);
  const [signature, setSignature] = useState<string | ArrayBuffer | null>('');
  const [createSignatoryMutation, {isLoading}] = useCreateSignatoryMutation();

  useEffect(() => {
    let newSignatories: IProfile[] = filterNewSignatories();
    setNewSignatories(newSignatories);
  }, [staff, signatories]);

  const handleClose = () => {
    setShow(false);
    onchange();
  }

  const filterNewSignatories = () => {
    let newSignatories: IProfile[] = []
    for (let eachStaff of staff) {
      let foundItem: ISignatory | undefined = signatories.find((each: ISignatory) => each.user_id === eachStaff.id)
      if (!foundItem) newSignatories.push(eachStaff)
    }
    return newSignatories
  }

  const onChange = (event: any): void => {
    event.preventDefault();
    let files = [];
    if (event.dataTransfer) files = event.dataTransfer.files;
    else if (event.target) files = event.target.files;
    const reader = new FileReader();
    reader.onload = () => setSignature(reader.result);
    reader.readAsDataURL(files[0]);
  }

  const createSignatory = async (): Promise<void> => {
    try {
      const base64File: string = typeof signature === 'string' ? signature.split(",")[1] : '';
      const newSignatory: IProfile = signatory ?? MockProfile
      const payload: ICreateSignatoryRequest = {
        user_id: newSignatory.id, first_name: newSignatory.first_name, last_name: newSignatory.last_name,
        email: newSignatory.email, signature_url: base64File
      }
      await createSignatoryMutation(payload).unwrap();
      onchange()
    } catch (err: any) {
      toast.error(err.data.message)
    }
  }


  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton><Modal.Title>Onboard Staff - Voucher Signatory</Modal.Title></Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <div className="mb-3">
              <label htmlFor="formrow-email-input" className="form-label">Select Signatory</label>
              <Select
                aria-label="signatory"
                options={newSignatories}
                getOptionLabel={(each: IProfile): string => `${each.first_name} ${each.last_name}`}
                isClearable={false}
                isSearchable={true}
                maxMenuHeight={250}
                styles={selectStyles}
                menuPlacement="bottom"
                onChange={(option: SingleValue<IProfile>) => setSignatory(option)}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label htmlFor="formrow-password-input" className="form-label">Signature Document</label>
              <input type="file" className="form-control" onChange={onChange}/>
            </div>
          </div>
        </div>
        <div className="mt-2 float-end">
          <button className="btn btn-primary w-md" onClick={createSignatory}>
            Onboard Signatory {isLoading && <i className="bx bx-loader bx-spin"/>}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default CreateSignatoryModal;
