import {Cell, Pie, PieChart, ResponsiveContainer, Tooltip} from "recharts";
import CustomTooltip from "./CustomTooltip";

interface Props {
  name: string
  data: IChart[]
}

interface LabelProps {
  cx: number
  cy: number
  midAngle: number
  innerRadius: number
  outerRadius: number
  percent: number
  index: number
}

const HalfPieCharts = ({name, data}: Props) => {

  const CustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent}: LabelProps) => {
    const RADIAN: number = Math.PI / 180;
    const radius: number = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x: number = cx + radius * Math.cos(-midAngle * RADIAN);
    const y: number = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div className="card card-body card-h-100 pb-0">
      <h5 className="card-title mb-4">{name}</h5>
      <div style={{width: '100%', height: '100%'}}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={300} height={100} margin={{top: 120, right: 25, left: 25, bottom: 10}}>
            <Pie dataKey="value" startAngle={180} endAngle={0} data={data} cx="50%" cy="50%" outerRadius={150}
                 labelLine={false} label={CustomizedLabel}>
              {data.map((entry: IChart, index: number) => <Cell key={index} fill={entry.fill} stroke={entry.fill}/>)}
            </Pie>
            <Tooltip content={<CustomTooltip/>}/>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default HalfPieCharts;
