import {ReactNode} from "react";
import Dropdown from 'react-bootstrap/Dropdown';

interface ItemProps {
  text: string
  loading?: boolean
  disabled?: boolean
  onclick: () => void
}

interface Props {
  buttonText: string
  buttonSize: "sm" | "lg"
  variant: string
  children: ReactNode
}

export const DropDownItem = ({text, loading, onclick, disabled}: ItemProps) => (
  <Dropdown.Item disabled={disabled} onClick={onclick}>
    {text} {loading && <i className="bx bx-loader-circle bx-spin"/>}
  </Dropdown.Item>
)

export const DropDown = ({buttonText, variant, buttonSize, children}: Props) => (
  <Dropdown>
    <Dropdown.Toggle variant={variant} size={buttonSize}>{buttonText}</Dropdown.Toggle>
    <Dropdown.Menu>{children}</Dropdown.Menu>
  </Dropdown>
)
