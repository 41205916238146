import {BaseQueryFn, createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

import TokenHelper from "../../utils/helpers/tokenHelper"
import {getBaseUrl} from "../../utils/services/axios.service"

const dodgeBaseQuery: BaseQueryFn = fetchBaseQuery({
  baseUrl: getBaseUrl()['dodge'],
  prepareHeaders: (headers: Headers) => {
    const token = TokenHelper.getToken()
    headers.set("Content-Type", 'application/json')
    if (token) headers.set("Authorization", `Bearer ${token}`)
    return headers
  }
})

export const dodgeQuery = createApi({
  baseQuery: dodgeBaseQuery,
  reducerPath: 'dodgeQuery',
  tagTypes: ['Country', 'Organisation', 'Service'],
  endpoints: builder => ({
    getCountries: builder.query({
      query: filter => `/locale-service//configs/countries/${filter}`,
      providesTags: ['Country']
    }),
    getOrganisations: builder.query({
      query: filter => `/locale-service/admin/organisations/${filter}`,
      providesTags: ['Organisation']
    }),
    getOrganisation: builder.query({
      query: filter => `/locale-service/tenants/organisation/${filter}`,
      providesTags: ['Organisation']
    }),
    getOrganisationMembers: builder.query({
      query: filter => `/locale-service/tenants/organisation-members/${filter}`,
      providesTags: ['Organisation']
    }),
    getOrganisationWallet: builder.query({
      query: filter => `/locale-service/wallets/${filter}`,
      providesTags: ['Organisation']
    }),
    getOrganisationWalletEntries: builder.query({
      query: filter => `/locale-service/wallets/entries/${filter}`,
      providesTags: ['Organisation']
    }),
    walletManualEntry: builder.mutation({
      query: (body) => ({url: `/locale-service/wallets/manual-entry`, method: 'POST', body}),
      invalidatesTags: ['Organisation']
    }),
    balanceWalletLedger: builder.mutation({
      query: (body) => ({url: `/locale-service/wallets/balance-ledger`, method: 'POST', body}),
      invalidatesTags: ['Organisation']
    }),
    getServices: builder.query({
      query: filter => `/locale-service/configs/services/${filter}`,
      providesTags: ['Service']
    }),
    updateService: builder.mutation({
      query: ({filter, body}) => ({url: `/locale-service/configs/services/update/${filter}`, method: 'PATCH', body}),
      invalidatesTags: ['Service']
    }),
  })
})

export const {
  useGetCountriesQuery,
  useGetOrganisationsQuery,
  useGetOrganisationQuery,
  useGetOrganisationMembersQuery,
  useGetOrganisationWalletQuery,
  useGetOrganisationWalletEntriesQuery,
  useWalletManualEntryMutation,
  useBalanceWalletLedgerMutation,
  useGetServicesQuery,
  useUpdateServiceMutation
} = dodgeQuery;
