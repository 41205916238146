export const MockLeadUpdates: ILeadUpdates = {
  message: "",
  status: "",
  time: "",
  updated_by: "",
}

export const MockDriverLead: IDriverLead = {
  id: "",
  email: "",
  first_name: "",
  last_name: "",
  phone: "",
  type: "",
  status: "",
  extras: {},
  acquisition_updates: [MockLeadUpdates],
  created_at: "",
  updated_at: "",
}