export const AppInfo = {
  APP_NAME: 'truQ Dashboard',
  ORG_NAME: 'truQ Services',
  APP_VERSION: 'v1',
  ORGANISATION: 'TRU_Z9WE',
  STAGING_ORGANISATION: 'TRU_H0D4',
  BACKOFFICE_BASE_URL: `https://backoffice-production-2ric45myja-uc.a.run.app/`,
  BACKOFFICE_STAGING_BASE_URL: `https://backoffice-staging-2ric45myja-uc.a.run.app/`,
  CAS_BASE_URL: `https://cas-production-2ric45myja-uc.a.run.app/api/v1/`,
  CAS_STAGING_BASE_URL: `https://cas-staging-2ric45myja-uc.a.run.app/api/v1/`,
  DODGE_BASE_URL: `https://dodge-apis-c2btvqbhlq-uc.a.run.app/`,
  DODGE_STAGING_BASE_URL: `https://dodge-apis-staging-c2btvqbhlq-uc.a.run.app/`,
  // BACKOFFICE_STAGING_BASE_URL: ` http://localhost:8001/`
}

export const AppKeys: IDynamicFields = {
  TOKEN: 'T7uQD45h604rD-0987Tt1V13wMT4-987to89nC0D30f152m8SCR36Gp9CUlpCyvNm8ak3KAxfC54r5hyP41JU',
  THEME: 'T7uQD45h604rD-0987Tt1V13wMT4-987to89D30f15r8SCCyvNmm4R329CUn6Gp85hyP4a1C0lpk3KfC5AxJU',
  GOOGLE_AUTH_KEY: "150388206789-nan9mpjg7q03q5uh21jah794l4iudibq.apps.googleusercontent.com",
  MAPS_KEY: "AIzaSyA3eMnMs4xqSo9Xl7VR0d3M_3YKSYmI1O8",
  COOKIE_EXPIRY_PERIOD: 30,
}

export const Loading: IDynamicFields = {
  ERROR: 'ERROR',
  FAILED: 'FAILED',
  CREATED: 'CREATED',
  SUCCESS: 'SUCCESS',
  FETCHING: 'FETCHING',
  NO_CONTENT: 204,
}

export const MenuType: IDynamicFields = {
  HOME: "home",
  PROFILE: "profile",
  TRIPS: "trips",
  SUPPLY: "supply",
  MESSAGING: "messaging",
  VOUCHER: "voucher",
  STAFF: "staff",
  SAAS: "saas",
  DOCS: "docs",
}
