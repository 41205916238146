import moment from "moment";
import {formatAmountToString} from "../../utils/helpers/logicHelper";

interface Props {
  vehicles: IVehiclePartner[]
  driverDocuments: IDocumentRenewal[]
  vehicleDocuments: IDocumentRenewal[]
}

const DocumentRenewalCard = ({vehicles, driverDocuments, vehicleDocuments}: Props) => {

  let documents: IDocumentRenewal[] = [...driverDocuments, ...vehicleDocuments];
  let renderVehicleInfo = (id: string): string => {
    let vehicle: IVehiclePartner | undefined = vehicles.find((vehicle: IVehiclePartner): boolean => vehicle.id === id)
    if (vehicle) return `VEHICLE - ${vehicle.extras.registration_number}`
    else return "VEHICLE"
  }

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="font-size-16 mb-4">Document Renewals</h5>
        <div className="table-responsive">
          <table className="table table-nowrap table-hover mb-1">
            <thead className="bg-light">
            <tr>
              <th scope="col">Processing Date</th>
              <th scope="col">Label</th>
              <th scope="col">Type</th>
              <th scope="col">Amount</th>
              <th scope="col">Status</th>
              <th scope="col">Completion Date</th>
            </tr>
            </thead>
            <tbody>
            {documents.map((item: IDocumentRenewal, index: number) =>
              <tr key={index}>
                <th scope="row">{moment(item.processing_date).format('LL')}</th>
                <td>{item.document.toUpperCase()}</td>
                <td>{item.type === 'DRIVER' ? 'DRIVER' : renderVehicleInfo(item.vehicle_id ?? '')}</td>
                <td>{formatAmountToString(item.amount)}</td>
                <td><span className="badge bg-primary-subtle text-primary font-size-12">{item.status}</span></td>
                <th scope="row">{item.completion_date ? moment(item.completion_date).format('LL') : '-'}</th>
              </tr>
            )}
            </tbody>
          </table>
        </div>

      </div>
    </div>
  )
}

export default DocumentRenewalCard;
