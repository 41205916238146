export const voucherStatusMap: IDynamicFields = {
  "INITIATED": {
    label: "Initiated",
    fill: "bg-success",
  },
  "PREPARED": {
    label: "Prepared",
    fill: "bg-success",
  },
  "APPROVED": {
    id: 3,
    label: "Approved",
    fill: "bg-success"
  },
  "RECEIVED": {
    id: 4,
    label: "Received",
    fill: "bg-success"
  },
  "PAID": {
    id: 5,
    label: "Paid",
    fill: "bg-success"
  },
  "REJECTED": {
    id: 3,
    label: "Rejected",
    fill: "bg-danger",
  },
}

export const orgFlowMap: IDynamicFields = {
  "DEMAND_FLOW": {
    label: "Demand Services",
    fill: "bg-success",
  },
  "SUPPLY_FLOW": {
    label: "Supply Services",
    fill: "bg-success",
  },
  "DEMAND_SUPPLY_FLOW": {
    id: 3,
    label: "Demand and Supply Services",
    fill: "bg-success"
  }
}