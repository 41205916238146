import {useState} from "react";
import {useDispatch} from "react-redux";
import {Dispatch} from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";

import ToolTipComponent from "../../../../components/modules/ToolTipComponent";
import DeleteEntityModal from "../../../../components/modals/DeleteEntityModal";
import {getBaseUrl} from "../../../../utils/services/axios.service";
import {dispatchCountry} from "../../../../store/slices/country.slice";
import TokenHelper from "../../../../utils/helpers/tokenHelper";

interface Props {
  country: ICountry
  services: ICountryService[]
}

const CountryServices = ({country, services}: Props) => {

  const dispatch: Dispatch = useDispatch();
  const initDeleteProps: IDeleteProps = {title: '', errorMsg: '', confirmMsg: '', confirmation: ''}
  const [loading, setLoading] = useState<boolean>(false)
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false)
  const [deleteRender, setDeleteRender] = useState<boolean>(false)
  const [deleteProps, setDeleteProps] = useState<IDeleteProps>(initDeleteProps)
  const [ref, setRef] = useState<string>('')

  const toggleStatus = async (item: ICountryService): Promise<void> => {
    try {
      setRef(item.service_ref);
      setLoading(true);
      let token = TokenHelper.getToken(), headers: IDynamicFields = {Authorization: `Bearer ${token}`}
      let url: string = `${getBaseUrl().dodge}locale-service/configs/services/country/update/${item.id}`
      let payload: IDynamicFields = {status: !item.status}
      await axios.patch(url, payload, {headers});
      await dispatchCountry(dispatch, country.country_code)
      setLoading(false);
    } catch (err: any) {
      toast.error(err.data.message)
    }
  }

  const deleteService = (item: ICountryService): void => {
    let deleteProps: IDeleteProps = {
      title: `Remove ${item.service_ref} service from ${country.name}`,
      errorMsg: `You are about to remove the selected service ${item.service_name} from ${country.name}'s configuration`,
      confirmMsg: 'Type in the service configuration reference to delete',
      confirmation: item.service_ref,
      property: item
    }
    setDeleteRender(true);
    setDeleteProps(deleteProps);
  }

  const deletePrompt = async (): Promise<void> => {
    try {
      setRef(deleteProps.property.service_ref);
      setDeleteLoading(true);
      let token = TokenHelper.getToken(), headers: IDynamicFields = {Authorization: `Bearer ${token}`}
      let url: string = `${getBaseUrl().dodge}locale-service/configs/services/country/delete/${deleteProps.property.id}`
      await axios.delete(url, {headers})
      await dispatchCountry(dispatch, country.country_code)
      setDeleteLoading(false);
    } catch (error: any) {
      toast.error(error.message);
    }
  }

  const deleteServiceModal = deleteRender &&
    <DeleteEntityModal deleteProps={deleteProps} onchange={() => setDeleteRender(false)} actionPrompt={deletePrompt}/>

  return (
    <div className="col-md-7">
      {deleteServiceModal}
      <div className="bg-light rounded px-4 py-3">
        <h6>COUNTRY SERVICES CONFIGURATION</h6>
        <p>Setup services configuration that applies for {country.name}</p>
        <div className="col-md-12">
          <div className="table-responsive">
            <table className="table table-striped mb-0">
              <thead>
              <tr>
                <th>Name</th>
                <th>Reference</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              {services.map((item: ICountryService, index: number) =>
                <tr key={index}>
                  <td>{item.service_name}</td>
                  <td>{item.service_ref}</td>
                  <td>{item.status ? 'Enabled' : 'Disabled'}</td>
                  <td>
                    <span className="text-center pointer-event">
                      <ToolTipComponent text={`Click to ${item.status ? 'disable' : 'enable'} service`} placement="top">
                        <i onClick={() => toggleStatus(item)}
                           className={`bx ${ref === item.service_ref && loading ? 'bx-loader-circle bx-spin' : item.status ? 'bx-check-circle text-success' : 'bx-block text-danger'}`}/>
                      </ToolTipComponent>
                      <ToolTipComponent text="Click to remove service" placement="top">
                          <i onClick={() => deleteService(item)}
                             className={`bx ${ref === item.service_ref && deleteLoading ? 'bx-loader-circle bx-spin' : 'bx-trash text-danger'} mx-1`}/>
                      </ToolTipComponent>
                    </span>
                  </td>
                </tr>
              )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CountryServices;
