import {useState} from "react";
import Select, {SingleValue} from "react-select";
import Modal from 'react-bootstrap/Modal';
import moment from "moment";

import {MonthRange, QuarterRange, TimeRange, YearRange} from "../../mocks/MockInsights";
import {SelectStyles} from "../../utils/helpers/selectStyles";

interface Props {
  onchange: () => void
  setFilterPayload: (filterPayload: IFilterPayload) => void
}

const FilterInsightsModal = ({onchange, setFilterPayload}: Props) => {
  const selectStyles: IDynamicFields = SelectStyles();
  const date: Date = new Date(), year: string = date.getFullYear().toString(), month: string = moment().format('MMMM')
  const [show, setShow] = useState<boolean>(true);
  const [customPreset, setCustomPreset] = useState<boolean>(false);
  const [payload, setPayload] = useState<IFilterPayload>({year});
  const [timeRange, selectTimeRange] = useState<IInsightsRange | null>(null)
  const [customRange, selectCustomRange] = useState<IInsightsRange | null>(null)

  const handleClose = () => {
    setShow(false);
    onchange();
  }

  const updateRangeParam = (option: SingleValue<IInsightsRange>): void => {
    setCustomPreset(false)
    selectCustomRange(null)
    if (option?.type === 'CUSTOM') setCustomPreset(true)
    else if (option?.value === 'current_year') setPayload({year})
    else if (option?.value === 'last_year') setPayload({year: moment().subtract('1', 'year').format('YYYY')})
    else if (option?.value === 'current_month') setPayload({year, month})
    else if (option?.value === 'last_month') setPayload({year, month: moment().subtract('1', 'month').format('MMMM')})
    selectTimeRange(option)
  }

  const updateCustomParam = (option: SingleValue<IInsightsRange>): void => {
    setPayload({year, [timeRange?.value ?? 'month']: option?.value})
    selectCustomRange(option)
  }

  const setFilter = (): void => {

    setFilterPayload(payload)
    handleClose()
  }

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton><Modal.Title>Filter Insights</Modal.Title></Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12 mb-3">
            <label htmlFor="formrow-email-input" className="form-label">Select Time Range</label>
            <Select options={TimeRange}
                    isClearable={false}
                    minMenuHeight={250}
                    styles={selectStyles}
                    menuPlacement="bottom"
                    name="timeRange"
                    placeholder="Select Time Range..."
                    onChange={(option: SingleValue<IInsightsRange>) => updateRangeParam(option)}/>
          </div>
          {customPreset &&
            <div className="col-md-12 mb-3">
              <label htmlFor="formrow-email-input" className="form-label">Select Custom Range</label>
              <Select
                options={timeRange?.value === 'month' ? MonthRange : timeRange?.value === 'quarter' ? QuarterRange
                  : timeRange?.value === 'half_year' ? YearRange : []}
                isClearable={false}
                minMenuHeight={250}
                menuPlacement="bottom"
                name="customRange"
                value={customRange}
                styles={selectStyles}
                placeholder="Select Custom Range..."
                onChange={(option: SingleValue<IInsightsRange>) => updateCustomParam(option)}/>
            </div>
          }
        </div>
        <div className="mt-2 float-end">
          <button className="btn btn-primary w-md" onClick={setFilter}>
            Filter
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default FilterInsightsModal;
