import {NavigateFunction, useNavigate} from "react-router-dom";
import moment from "moment/moment";
import Gravatar from "../../assets/images/pngs/gravatar.png";

interface Props {
  performance: IDriverPerformance[]
  title: string
  keyType: "total_points" | "year" | "thisQuarter" | "month"
}

const LeaderboardCard = ({performance, title, keyType}: Props) => {
  const navigate: NavigateFunction = useNavigate()
  const date: Date = new Date(), year: number = date.getFullYear(), month: string = moment().format('MMMM')

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex align-items-start mb-2">
          <div className="flex-grow-1">
            <h5 className="card-title">{title}</h5>
          </div>
        </div>
        <div className="mx-n4">
          {performance.map((each: IDriverPerformance, index: number) =>
            <div key={index} className="py-3 border-bottom loyal-customers-box">
              <div className="d-flex align-items-center">
                <img src={Gravatar} className="rounded-circle avatar img-thumbnail" alt=""/>
                <div onClick={() => navigate(`/supply/driver/${each.email}`, {state: each.id})}
                     className="flex-grow-1 ms-3 overflow-hidden pointer-event">
                  <h5 className="font-size-15 mb-1 text-truncate">{each.first_name} {each.last_name}</h5>
                  <p className="text-muted text-truncate mb-0">{each.email}</p>
                </div>
                <div className="flex-shrink-0 text-end">
                  {keyType === "total_points" &&
                    <h5 className="font-size-14 mb-0 text-truncate w-xs bg-light p-2 rounded text-center">
                      {each["total_points"]} <i className="bx bxs-star font-size-14 text-primary ms-1"></i>
                    </h5>
                  }
                  {keyType === "year" &&
                    <h5 className="font-size-14 mb-0 text-truncate w-xs bg-light p-2 rounded text-center">
                      {each.points[year]["total"]} <i className="bx bxs-star font-size-14 text-primary ms-1"></i>
                    </h5>
                  }
                  {keyType === "thisQuarter" &&
                    <h5 className="font-size-14 mb-0 text-truncate w-xs bg-light p-2 rounded text-center">
                      {each.points["thisQuarter"]} <i className="bx bxs-star font-size-14 text-primary ms-1"></i>
                    </h5>
                  }
                  {keyType === "month" &&
                    <h5 className="font-size-14 mb-0 text-truncate w-xs bg-light p-2 rounded text-center">
                      {each.points[year][month]} <i className="bx bxs-star font-size-14 text-primary ms-1"></i>
                    </h5>
                  }
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default LeaderboardCard;
