import { ColumnDef, flexRender, getCoreRowModel, getSortedRowModel, HeaderGroup, Table, useReactTable } from '@tanstack/react-table'

import OutlineSVG from "../../assets/images/pngs/outline.png";
import Pagination from "./Pagination";
import EmptyTable from "./EmptyTable";
import OpacityLoader from '../loaders/OpacityLoader';

interface Props<TData> {
  columns: ColumnDef<TData, string>[];
  data: TData[]
  size: number
  count: number
  pageIndex: number
  loadingState: boolean
  setPageIndex: (index: number) => void
}


const DataTable = <TData extends object>({ columns, data, size, count, pageIndex, loadingState, setPageIndex }: Props<TData>) => {

  const table: Table<any> = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return (
    <>
      {loadingState && <OpacityLoader/>}
      <div className="table-responsive table-responsive-md">
        <table className="table table-striped text-nowrap mt-2">
          <thead>
            {table.getHeaderGroups().map((headerGroup: HeaderGroup<any>) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th key={header.id} className={header.column.getCanSort() ? 'pointer-event' : ''}>
                    {header.isPlaceholder ? null : (
                      <span
                        onClick={header.column.getToggleSortingHandler()}
                        title={
                          header.column.getCanSort()
                            ? header.column.getNextSortingOrder() === 'asc' ? 'Sort ascending'
                              : header.column.getNextSortingOrder() === 'desc' ? 'Sort descending' : 'Clear sort' : ""
                        }>
                        {flexRender(header.column.columnDef.header, header.getContext())} {header.column.getCanSort()}
                        {header.column.getCanSort() ?
                          {
                            asc: <i className="bx bx-sort-up font-size-15 align-middle ps-1" />,
                            desc: <i className="bx bx-sort-down font-size-15 align-middle ps-1" />,
                          }[header.column.getIsSorted() as string] ?? <i className="bx bx-sort font-size-15 align-middle ps-1" /> : null}
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {(data && data.length > 0) ?
        <Pagination size={size} count={count} pageIndex={pageIndex} pageSize={data.length < size ? data.length : size}
          setPageIndex={setPageIndex} /> :
        <EmptyTable icon={OutlineSVG} description="No data or resources available" />
      }
    </>
  )
}

export default DataTable
