import {createSlice, Dispatch} from '@reduxjs/toolkit'
import toast from "react-hot-toast";
import {AxiosResponse} from "axios";
import InsightsService from "../../utils/services/insights.service";
import {MockOverview} from "../../mocks/MockInsights";


const initialState: IInsightsState = {
  loadedOverview: false,
  overview: MockOverview,
  finInsights: {
    transactions: {commission: [], gross: [], net: [], props: [], trip: [], vat: []},
    advanced_payments: {commission: [], volume: []},
    insurance: {commission: []},
    sms: {commission: []},
    withdrawals: {successful: [], failed: []}
  },
}

const insightsSlice = createSlice({
  name: 'insights',
  initialState,
  reducers: {
    setLoadingOverview: (state: IInsightsState): void => {
      state.loadedOverview = false
    },
    setOverview: (state: IInsightsState, {payload: overview}): void => {
      state.overview = overview
      state.loadedOverview = true
    },
    setFinancialOverview: (state: IInsightsState, {payload: overview}): void => {
      state.finInsights = overview
      state.loadedOverview = true
    }
  }
})

export const dispatchInsights = async (dispatch: Dispatch, payload: IFinanceOverviewRequest): Promise<void> => {
  try {
    dispatch(setLoadingOverview())
    const results: AxiosResponse[] = await Promise.all([InsightsService.getOverview(), InsightsService.getFinancialInsights(payload)])
    dispatch(setOverview(results[0]['data']['data']))
    dispatch(setFinancialOverview(results[1]['data']['data']))
  } catch (error: any) {
    toast.error(error.message);
  }
}

export const {setLoadingOverview, setOverview, setFinancialOverview} = insightsSlice.actions

export default insightsSlice.reducer;
