import {MouseEvent, useState} from "react";
import {Location, NavigateFunction, NavLink, Params, useLocation, useNavigate, useParams} from "react-router-dom";

import PageLayout from "../../../components/layouts/PageLayout";
import ProfileCard from "../../../components/cards/ProfileCard";
import EmptyCard from "../../../components/cards/EmptyCard";
import OwnedVehiclesCard from "../../../components/cards/OwnedVehiclesCard";
import RewardEntryCard from "../../../components/cards/RewardEntryCard";
import AssignedVehicleCard from "../../../components/cards/AssignedVehicleCard";
import DocumentRenewalCard from "../../../components/cards/DocumentRenewalCard";
import {MenuType} from "../../../utils/helpers/constants";
import {useGetDriverQuery} from "../../../store/slices/backoffice.slice";


const DriverProfile = () => {
  const {reference}: Readonly<Params> = useParams(), location: Location<any> = useLocation();
  const navigate: NavigateFunction = useNavigate();
  const navTabs: string[] = ['Assigned Vehicle', 'Owned Vehicles', 'Document Renewals']
  const [activeNav, setActiveNav] = useState<string>("Assigned Vehicle");
  const {data: driver, isLoading, isSuccess, isError} = useGetDriverQuery(location.state);
  if (!location.state || isError) navigate(-1)

  const switchActiveNav = (event: MouseEvent, selected: string): void => {
    event.preventDefault()
    setActiveNav(selected)
  }

  return (
    <PageLayout active={MenuType.SUPPLY} backEnabled title={`Driver Profile - ${reference}`} loading={isLoading}>
      {isSuccess &&
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-6"/>
            <div className="col-md-6"/>
          </div>
          <div className="row">
            <div className="col-md-4">
              <ProfileCard profile={driver.data.driver} points={driver.data.points}
                           creditProperties={driver.data.creditProperties}/>
            </div>
            <div className="col-md-8">
              <div className="card">
                <div className="card-body">
                  <ul className="nav nav-pills nav-justified">
                    {navTabs.map((item: string, index: number) =>
                      <li key={index} className="nav-item" role="presentation">
                        <NavLink className={`nav-link ${activeNav === item ? 'active' : ''}`} to="/"
                                 onClick={(event: MouseEvent<HTMLAnchorElement>) => switchActiveNav(event, item)}>
                          <span>{item}</span>
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              <div className="tab-content">
                <div className={`tab-pane ${activeNav === 'Assigned Vehicle' ? 'active show' : ''}`} role="tabpanel">
                  {driver.data.driver['assigned_vehicle'] ? <AssignedVehicleCard vehicle={driver.data.vehicle}/> :
                    <EmptyCard text="Partner Not Assigned a Vehicle"/>
                  }
                </div>
                <div className={`tab-pane ${activeNav === 'Owned Vehicles' ? 'active show' : ''}`} role="tabpanel">
                  {driver.data.vehicles.length > 0 ? <OwnedVehiclesCard vehicles={driver.data.vehicles}/> :
                    <EmptyCard text="No Owned Vehicle Onboarded"/>
                  }
                </div>
                <div className={`tab-pane ${activeNav === 'Document Renewals' ? 'active show' : ''}`} role="tabpanel">
                  {driver.data.driverRenewals.length === 0 && driver.data['vehicleRenewals'].length === 0 ?
                    <EmptyCard text="No Processed Document Renewals"/> :
                    <DocumentRenewalCard vehicles={driver.data.vehicles} driverDocuments={driver.data.driverRenewals}
                                         vehicleDocuments={driver.data.vehicleRenewals}/>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-12">
              <RewardEntryCard entries={driver?.data.performanceEntries} description/>
            </div>
          </div>
        </div>
      }
    </PageLayout>
  )
}

export default DriverProfile;
