import {createSlice, Dispatch} from '@reduxjs/toolkit'
import toast from "react-hot-toast";
import EditorService from "../../utils/services/editor.service";
import {MockTopic} from "../../mocks/MockEditor";


const initialState: IEditorState = {
  topic: MockTopic
}

const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    setTopic: (state: IEditorState, {payload: topic}): void => {
      state.topic = topic
    }
  }
})

export const dispatchTopic = async (dispatch: Dispatch, topic: string): Promise<void> => {
  try {
    const {data: responseData} = await EditorService.getTopics(topic);
    dispatch(setTopic(responseData.data))
  } catch (error: any) {
    toast.error(error.message);
  }
}

export const {setTopic} = editorSlice.actions

export default editorSlice.reducer;
