import {useState} from "react";
import {useSelector} from "react-redux";
import {NavigateFunction, useNavigate} from "react-router-dom";
import moment from "moment";

import TopBarSearch from "./TopBarSearch";
import TopBarDropdown from "./TopBarDropdown";
import TopBarTheme from "./TopBarTheme";
import {updateSideNav} from "../../utils/helpers/logicHelper";
import {MenuType} from "../../utils/helpers/constants";

interface Props {
  page: string
  title: string
  profile: IProfile
  backEnabled?: boolean
  searchType?: 'DOCUMENT' | 'VOUCHER' | 'ANY'
  navSearch?: ((params: string) => void) | undefined
  setThemeContext: (theme: string) => void
}

const TopBar = ({page, title, profile, backEnabled, searchType, navSearch, setThemeContext}: Props) => {

  const navigate: NavigateFunction = useNavigate()
  const weatherForecast: IWeatherState = useSelector((state: IStoreState) => state.auth.weatherForecast)
  const temperatureForecast: ITemperatureState = useSelector((state: IStoreState) => state.auth.temperatureForecast)

  const [sideNav, setSideNav] = useState<boolean>(false)
  const goBack = () => navigate(-1)
  const updateSideNavStatus = (status: boolean): void => {
    updateSideNav(status)
    setSideNav(status)
  }

  const getTitle = (): string => {
    let icon: string = weatherForecast.main === 'Clouds' ? `\u26C5` : weatherForecast.main === 'Rain' ? `\u2614`
      : weatherForecast.main === 'Sun' ? `\u2600` : '';
    let displayTypes: string[] = [
      `${title}`,
      `Weather today looks like ${weatherForecast.description} ${icon}`,
      `Feels like ${temperatureForecast.feels_like.toFixed()}\u00B0 today ${icon}`,
      `Current time in your location is ${moment().format('H:mm a')} 🕒`,
    ]
    return page === MenuType.HOME ? displayTypes[Math.floor(Math.random() * displayTypes.length)] : title
  }

  return (
    <header id="page-topbar" className="isvertical-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          <button type="button" onClick={() => updateSideNavStatus(!sideNav)}
                  className="btn btn-sm px-3 d-md-none font-size-24 header-item vertical-menu-btn">
            <i className="bx bx-menu align-middle"></i>
          </button>
          <div className="page-title-box align-self-center d-none d-md-block">
            <h4 className="page-title mb-0">
              {backEnabled &&
                <span onClick={goBack} className="pointer-event"><i className="bx bx-left-arrow-circle"/>&nbsp;</span>}
              {getTitle()}
            </h4>
          </div>
        </div>
        <div className="d-flex">
          {searchType && <TopBarSearch searchType={searchType} navSearch={navSearch}/>}
          <TopBarTheme setThemeContext={setThemeContext}/>
          <TopBarDropdown profile={profile}/>
        </div>
      </div>
    </header>
  );
};

export default TopBar;
