import {configureStore, EnhancedStore} from '@reduxjs/toolkit'
import {setupListeners} from "@reduxjs/toolkit/query";
import {createLogger} from 'redux-logger'

import {backOfficeQuery} from "./slices/backoffice.slice";
import {dodgeQuery} from "./slices/dodge.slice";
import authReducer from './slices/auth.slice';
import countryReducer from './slices/country.slice';
import editorReducer from './slices/editor.slice';
import insightsReducer from './slices/insights.slice';
import partnerReducer from './slices/partner.slice';
import {GetDefaultMiddleware} from "@reduxjs/toolkit/dist/getDefaultMiddleware";

const loggerMiddleware = () => {
  if (process.env.NODE_ENV === 'development') return [createLogger({collapsed: true})];
  return [];
};

export const store: EnhancedStore = configureStore({
  reducer: {
    [backOfficeQuery.reducerPath]: backOfficeQuery.reducer,
    [dodgeQuery.reducerPath]: dodgeQuery.reducer,
    auth: authReducer,
    country: countryReducer,
    editor: editorReducer,
    insights: insightsReducer,
    partner: partnerReducer,
  },
  middleware: (initMiddleware: GetDefaultMiddleware) => initMiddleware().concat(backOfficeQuery.middleware).concat(dodgeQuery.middleware).concat(loggerMiddleware()),
  devTools: false
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

setupListeners(store.dispatch);