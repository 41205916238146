import {MouseEvent, ReactElement} from "react";
import {NavigateFunction, NavLink, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

import Logo from "../../assets/images/pngs/logo.png";
import {removeAuthentication} from "../../store/slices/auth.slice";


const LockScreen = (): ReactElement => {

  const navigate: NavigateFunction = useNavigate();
  const userProfile: IProfile = useSelector((state: IStoreState) => state.auth.userProfile)

  const unlockScreen = (): void => navigate('/');
  const handleLogout = async (event: MouseEvent, type: string): Promise<void> => {
    event.preventDefault();
    await removeAuthentication(navigate, type)
  }

  return (
    <div className="authentication-bg min-vh-100">
      <div className="bg-overlay bg-light"></div>
      <div className="container">
        <div className="d-flex flex-column min-vh-100 px-3 pt-4">
          <div className="row justify-content-center my-auto">
            <div className="col-md-8 col-lg-6 col-xl-5">

              <div className="mb-4 pb-2">
                <NavLink to="/" className="d-block auth-logo">
                  <img src={Logo} alt="" height="30" className="auth-logo-dark me-start"/>
                  <img src={Logo} alt="" height="30" className="auth-logo-light me-start"/>
                </NavLink>
              </div>

              <div className="card">
                <div className="card-body p-4">
                  <div className="text-center mt-2">
                    <h5>Lock Screen</h5>
                    <p className="text-muted">Sign in to continue to truQ backoffice</p>
                  </div>

                  <div className="p-2 mt-4">
                    <div className="user-thumb text-center mb-4">
                      <img src={userProfile.picture} className="rounded-circle img-thumbnail avatar-lg"
                           alt="thumbnail"/>
                      <h5 className="font-size-15 mt-3">{userProfile.first_name} {userProfile.last_name}</h5>
                    </div>
                  </div>

                  <div className="mt-4 text-center">
                    <div className="mt-2">
                      <button className="btn btn-primary w-100" type="button"
                              onClick={unlockScreen}>Unlock Screen
                      </button>
                    </div>
                    <div className="mt-4 text-center">
                      <p className="mb-0">Not you?
                        <NavLink className="fw-medium text-primary" to="/logout"
                                 onClick={(event: MouseEvent) => handleLogout(event, 'ALL')}> Sign In
                        </NavLink>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LockScreen;
