import {ReactElement} from "react";
import {formatAmountToString, toTitleCase} from "../../utils/helpers/logicHelper";

interface TooltipProps {
  active?: boolean
  payload?: any[]
  label?: string
}

const CustomTooltip = ({active, payload, label}: TooltipProps): ReactElement => {
  if (active && payload && payload.length > 0) {
    return (
      <div className="custom-tooltip">
        <p className="label mt-2">{toTitleCase(label ?? "")}</p>
        {payload.map((item: any, index: number) =>
          <p key={index} className="label" style={{color: `${item.color}`}}>
            {`${item.name}: ${formatAmountToString(item.value)}`}
          </p>
        )}
      </div>
    );
  }
  return <div/>;
};


export default CustomTooltip;
