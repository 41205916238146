import {MutableRefObject, useContext, useRef} from 'react';
import {Editor} from "@tinymce/tinymce-react";
import {Editor as MCEEditor} from 'tinymce';
import {ThemeContext} from "../layouts/PageLayout";

interface Props {
  currentValue: string
  getContent: (data: string) => void
}

const TextEditor = ({getContent, currentValue}: Props) => {
  const contextValue: string = useContext(ThemeContext);
  const editorRef: MutableRefObject<MCEEditor | null> = useRef<MCEEditor | null>(null);
  const skinTheme: string = contextValue === 'dark' ? 'oxide-dark' : 'oxide';
  const contentTheme: string = contextValue === 'dark' ? 'dark' : 'default';

  return (
    <Editor
      apiKey="i1n0tna94g0jcwgwvo6310jonn849l38nei0sitjhb47zsj4"
      onInit={(_evt: any, editor: MCEEditor) => editorRef.current = editor}
      initialValue={currentValue}
      init={{
        force_br_newlines: true,
        height: 800,
        menubar: 'file edit mview insert format',
        plugins: [
          'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'code', 'codesample',
          'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
          'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
        ],
        toolbar: 'undo redo | blocks | bold italic forecolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent codesample | code removeformat | help',
        imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
        imagetools_cors_hosts: ['console.cloud.google.com', 'localhost:3000'],
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        skin: skinTheme,
        content_css: contentTheme
      }}
      onChange={() => getContent(editorRef.current ? editorRef.current.getContent() : '')}
    />
  )
};

export default TextEditor;
