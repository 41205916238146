import {ChangeEvent, useEffect, useState} from "react";
import {NavigateFunction, useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
import {useSendMessageMutation} from "../../../../store/slices/backoffice.slice";

interface Props {
  size?: string
  initProps?: { [key: string]: string }
}

const SendInApp = ({size, initProps}: Props) => {

  const navigate: NavigateFunction = useNavigate()
  const [email, setEmail] = useState<string>('');
  const [emails, setEmails] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [createMessagingMutation, {isLoading}] = useSendMessageMutation();
  const isValidated: boolean = !size || !subject || !content

  useEffect(() => {
    if (initProps && initProps.email && initProps.type === "APP") setEmail(initProps.email)
  }, []);

  const sendInAppNotification = async (): Promise<void> => {
    try {
      const type: "APP" = "APP", from: string = "CUSTOM", to: string = size === 'SINGLE' ? email : "CUSTOM";
      let filter: string = 'single', format: string = "ANNOUNCEMENT";
      let body: IMessagingRequest = {from, to, type, subject, content, format}
      if (size === 'BULK') {
        let recipients: IMessageRecipients[] = [], emailArray: string[] = emails.split(',');
        for (let i: number = 0; i < emailArray.length; i++) {
          emailArray[i] = emailArray[i].replace(/\s/g, '')
          recipients.push({email: emailArray[i]})
        }
        body.recipients = recipients;
        filter = 'bulk'
      }
      await createMessagingMutation({filter, body}).unwrap();
      navigate(`/messaging`)
    } catch (err: any) {
      toast.error(err.data.message)
    }
  }

  return (
    <>
      <div className="row">
        {size === 'BULK' ?
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="formrow-email-input" className="form-label">Email Address</label>
              <textarea value={emails} className="form-control"
                        placeholder="Enter email addresses, seperated with a comma"
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setEmails(e.target.value)}/>
            </div>
          </div> :
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="formrow-email-input" className="form-label">Email Address</label>
              <input type="text" value={email} className="form-control" placeholder="Enter Email Address"
                     onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}/>
            </div>
          </div>
        }
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="formrow-email-input" className="form-label">Notification Subject</label>
            <input type="text" value={subject} className="form-control" placeholder="Enter Notification Subject"
                   onChange={(e: ChangeEvent<HTMLInputElement>) => setSubject(e.target.value)}/>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="mb-3">
            <label htmlFor="formrow-email-input" className="form-label">Notification Content</label>
            <textarea value={content} className="form-control" placeholder="Enter message..."
                      onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setContent(e.target.value)}/>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-start gap-3 mt-4">
        <button disabled={isValidated} type="button" onClick={sendInAppNotification}
                className="btn btn-primary w-sm ms-auto">
          Send message {isLoading && <i className="bx bx-loader-circle bx-spin"/>}
        </button>
      </div>
    </>
  )
}

export default SendInApp;
