import {ChangeEvent, useState} from "react";
import {useDispatch} from "react-redux";
import Modal from 'react-bootstrap/Modal';
import toast from "react-hot-toast";
import Select, {SingleValue} from "react-select";

import PartnerService from "../../utils/services/partner.service";
import {AssignSearchParams} from "../../mocks/MockTrip";
import {Loading} from "../../utils/helpers/constants";
import {MockPartner} from "../../mocks/MockProfile";
import {useAwardPerformancePointsMutation} from "../../store/slices/backoffice.slice";
import {dispatchLeaderboard} from "../../store/slices/partner.slice";
import {SelectStyles} from "../../utils/helpers/selectStyles";

interface Props {
  onchange: () => void
}

const AssignPointModal = ({onchange}: Props) => {

  const dispatch = useDispatch(), selectStyles: IDynamicFields = SelectStyles();
  const [show, setShow] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams, setSearchParam] = useState<ITripSearchParams | null>()
  const [value, setValue] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [points, setPoints] = useState<string>('')
  const [entity, setEntity] = useState<IPartnerProfile>(MockPartner)
  const [awardPerformancePoints, {isLoading}] = useAwardPerformancePointsMutation()
  let isValidated: boolean = !entity.id || !points || !description

  const handleClose = () => {
    setShow(false);
    onchange();
  }

  const updateSearchParam = (option: SingleValue<ITripSearchParams>): void => setSearchParam(option)

  const searchAgain = (): void => {
    setValue('')
    setEntity(MockPartner)
  }

  const findAssignment = async (): Promise<void> => {
    setLoading(true)
    let payload: ISearchRequest = {searchModel: "DRIVER", searchType: searchParams?.value, searchValue: value}
    const {data: responseData} = await PartnerService.searchDriver(payload);
    if (responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
    else if (responseData.data.length === 0) toast.error('No partner with information found..');
    else setEntity(responseData.data[0])
    setLoading(false)
  }

  const awardPoints = async (): Promise<void> => {
    try {
      let body: { driver_id: string, points: string, description: string } = {driver_id: entity.id, points, description}
      await awardPerformancePoints(body).unwrap()
      await dispatchLeaderboard(dispatch)
      handleClose()
    } catch (err: any) {
      toast.error(err.data.message)
    }
  }

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton>
        <Modal.Title>Award Performance Points {entity.id && `to ${entity.user.first_name}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row mb-2">
          <div className="col-md-12">
            <div className="mb-3">
              <label htmlFor="formrow-email-input" className="form-label">Select Search Parameter</label>
              <Select
                aria-label="signatory"
                options={AssignSearchParams}
                getOptionLabel={(each: ITripSearchParams): string => `${each.label} (${each.description})`}
                isClearable={false}
                isSearchable={true}
                maxMenuHeight={250}
                styles={selectStyles}
                menuPlacement="bottom"
                onChange={(option: SingleValue<ITripSearchParams>) => updateSearchParam(option)}
              />
            </div>
          </div>
          {searchParams &&
            <>
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="formrow-password-input" className="form-label">{searchParams?.label}</label>
                  <div className="position-relative auth-pass-inputgroup input-custom-icon">
                    <input type="text" className="form-control" placeholder={searchParams?.description} value={value}
                           onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}/>
                    {entity.id && <i className="bx bx-check-circle text-success position-absolute right-10 top-12"/>}
                  </div>
                </div>
              </div>
              {entity.id &&
                <>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label htmlFor="formrow-password-input" className="form-label">Points</label>
                      <input type="number" className="form-control" placeholder="Enter Points" value={points}
                             onChange={(e: ChangeEvent<HTMLInputElement>) => setPoints(e.target.value)}/>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label htmlFor="formrow-password-input" className="form-label">Reason for awarding points</label>
                      <textarea className="form-control" placeholder="" value={description}
                                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setDescription(e.target.value)}/>
                    </div>
                  </div>
                </>
              }
            </>
          }
        </div>
        <div className="mt-2 float-end">
          {entity.id ?
            <>
              <button className="btn btn-info w-md" onClick={searchAgain}>Search Again</button>
              <button disabled={isValidated} className="btn btn-success w-md mx-1" onClick={awardPoints}>
                Award Points {isLoading && <i className="bx bx-loader bx-spin"/>}
              </button>
            </> :
            <button className="btn btn-primary w-md" onClick={findAssignment}>
              Find Partner {loading && <i className="bx bx-loader bx-spin"/>}
            </button>
          }
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AssignPointModal;
