import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch} from "@reduxjs/toolkit";


import PageLayout from "../../../components/layouts/PageLayout";
import Topics from "./Topics";
import NewTopicModal from "../../../components/modals/NewTopicModal";
import {dispatchTopic} from "../../../store/slices/editor.slice";
import {MenuType} from "../../../utils/helpers/constants";
import {useGetTopicsQuery} from "../../../store/slices/backoffice.slice";


const Docs = () => {

  const dispatch: Dispatch = useDispatch();
  const topic: ITopics = useSelector((state: IStoreState) => state.editor.topic)
  const [loading, setLoading] = useState<boolean>(false)
  const [addRender, setAddRender] = useState<boolean>(false)
  const [loadingTopic, setLoadingTopic] = useState<string>("")
  const {data: docs, isLoading} = useGetTopicsQuery("");

  const selectTopic = async (newTopic: ITopics): Promise<void> => {
    setLoading(true);
    setLoadingTopic(newTopic.topic);
    if (newTopic.topic !== topic.topic) await dispatchTopic(dispatch, newTopic.topic)
    setLoading(false);
  }

  const addContentModal = addRender && <NewTopicModal onset={selectTopic} onchange={() => setAddRender(false)}/>

  return (
    <PageLayout active={MenuType.DOCS} title="Documentations - Topics" loading={isLoading}>
      <div className="container-fluid">
        {addContentModal}
        <div className="row align-items-center">
          <div className="col-md-6"/>
          <div className="col-md-6">
            <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
              <button className="btn btn-primary pointer-event" onClick={() => setAddRender(true)}>
                <i className="bx bx-book-open me-1"></i> New Topic
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="card card-body">
              <div className="table-responsive">
                <table className="table table-nowrap align-middle mb-0">
                  <tbody>
                  {docs?.data.map((item: ITopics, index: number) =>
                    <tr key={index}>
                      <td>
                        <h5 className="text-truncate font-size-14 m-0">{item.name}</h5>
                      </td>
                      <td>
                        <button className="btn btn-sm btn-primary" onClick={() => selectTopic(item)}>
                          More info {loading && loadingTopic === item.topic && <i className="bx bx-loader bx-spin"/>}
                        </button>
                      </td>
                    </tr>
                  )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <Topics initTopic={topic}/>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default Docs;
