import {ChangeEvent, ReactElement, useEffect, useState} from "react";
import toast from "react-hot-toast";

import NewContentModal from "../../../components/modals/NewContentModal";
import NewTopicActionModal from "../../../components/modals/NewTopicActionModal";
import ContentList from "./ContentList";
import {useUpdateTopicMutation} from "../../../store/slices/backoffice.slice";

interface Props {
  initTopic: ITopics
}

const Topics = ({initTopic}: Props) => {
  const [topic, setTopic] = useState<ITopics>(initTopic);
  const [addRender, setAddRender] = useState<boolean>(false)
  const [actionRender, setActionRender] = useState<boolean>(false)
  const [updateTopicMutation, {isLoading}] = useUpdateTopicMutation();

  useEffect((): void => {
    setTopic(initTopic)
  }, [initTopic]);

  const setValue = (value: string, key: 'name' | 'intro'): void => {
    let initTopic: ITopics = {...topic};
    setTopic({...initTopic, [key]: value})
  }

  const confirmUpdate = async (): Promise<void> => {
    try {
      const body: IDynamicFields = {name: topic.name, intro: topic.intro}
      let result = await updateTopicMutation({filter: topic.topic, body}).unwrap()
      toast.success(result.message)
    } catch (error: any) {
      toast.error(error.data.message)
    }
  }

  const addContentModal = addRender &&
    <NewContentModal topic={topic} onchange={() => setAddRender(false)}/>

  const addActionModal = actionRender &&
    <NewTopicActionModal topic={topic} onchange={() => setActionRender(false)}/>

  return (
    <div className="card card-body">
      {addActionModal}
      {addContentModal}
      <div className="row mb-4">
        <div className="col-md-4">
          <h5 className="font-size-16 mb-0">{topic.name ? topic.name : 'Select a topic'}</h5>
          <small className="text-muted">{topic.topic}</small>
        </div>

        {topic.topic &&
          <div className="col-md-8">
            <div className="btn-toolbar float-end">
              <button onClick={() => setActionRender(true)} className="btn btn-info me-2 mb-2 mb-sm-0">
                <i className="bx bx-plus"/> New Action
              </button>
              <button onClick={() => setAddRender(true)} className="btn btn-primary me-2 mb-2 mb-sm-0">
                <i className="bx bxs-edit"/> New Content
              </button>
              <button onClick={confirmUpdate} className="btn btn-success me-2 mb-2 mb-sm-0">
                <i className="bx bx-save"/> Update {isLoading && <i className="bx bx-loader bx-spin"/>}
              </button>
            </div>
          </div>
        }
      </div>

      {topic.topic &&
        <div>
          <div className="row mt-4 border-bottom">
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="formrow-email-input" className="form-label">Name</label>
                <input type="text" value={topic.name} className="form-control" placeholder="Enter Topic Name"
                       onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value, 'name')}/>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="formrow-email-input" className="form-label">Topic Intro</label>
                <textarea value={topic.intro} rows={4} className="form-control" placeholder="Enter Topic Intro"
                          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setValue(e.target.value, 'intro')}/>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <h5 className="font-size-16 mb-0">Topic Actions</h5>
            <div className="col-md-6 mb-3">
              <p className="text-muted">Button Actions</p>
              {topic.actions.map((item: ITopicActions, index: number): ReactElement => (
                <div key={index} className="event-card bg-success pointer-event">
                  {item.title} - {item.type}
                </div>
              ))}
            </div>
            <div className="col-md-6 mb-3">
              <p className="text-muted">Media Actions</p>
              {topic.media_action.map((item: ITopicActions, index: number): ReactElement => (
                <div key={index} className="event-card bg-success pointer-event">
                  {item.title} - {item.type}
                </div>
              ))}
            </div>
          </div>
          <div className="row mt-4">
            <h5 className="font-size-16 mb-0">Topic Contents</h5>
            <ContentList initSidebarTree={topic.sidebarTree} topic={topic}/>
          </div>
        </div>
      }
    </div>
  )
}

export default Topics;
