export const MockTrip: ITrip = {
  id: "",
  user: {
    email: "",
    first_name: "",
    id: "",
    last_name: "",
    phone: ""
  },
  reference: "",
  waybill_number: "",
  organisation: "",
  is_interoperability: false,
  interoperability_details: {},
  is_advanced_payment: false,
  advanced_payment: {},
  driver: null,
  category_size: {
    category: "",
    id: "",
    tonnage: 3
  },
  coupon: {
    code: "",
    key: ""
  },
  trip_type: "",
  system_trip_type: "",
  source: {
    address: "",
    latitude: 0,
    longitude: 0,
    state: ""
  },
  destination: {
    address: "",
    latitude: 0,
    longitude: 0,
    state: "",
    stops: {number: 0},
  },
  insurance_details: {},
  extras: {},
  is_transit: false,
  is_completed: false,
  distance: 0,
  helper_info: {},
  created_at: "",
  status: "",
  system_status: "",
  is_insured: false,
  estimated_value: "",
  insurance_status: false,
  is_paid: false,
  quote: {
    base_fare: "",
    base_platform: "",
    charge: "",
    checkout_url: "",
    cost_per_kilometer: "",
    currency: "",
    estimated_insurance: "",
    estimated_value: 0,
    insurance_premium: "",
    payment_option: "",
    platform_commission_type: "",
    platform_commission_value: "",
    platform_fee: "",
    reference: "",
    saas_charge: "",
    saas_vat: "",
    sub_total: "",
    total_charge: "",
    total_vat: "",
    vat_type: "",
    vat_value: "",
    waybill_number: ""
  },
  confirmation: {},
  vehicle_payment: {
    currency: "",
    is_paid: false,
    vehicle_payment: "",
    vehicle_registration_number: null
  },
  user_journey: [
    {
      latitude: null,
      longitude: null,
      message: "",
      status: "",
      system_status: "",
      time: ""
    }
  ],
  business_tripset: null,
  is_ims: false,
  is_bulk_ims: false,
  ims_orderset: null,
  is_updated_analytics: false,
  is_paid_saas_commission: false,
  is_paid_organisation_commission: false,
  scheduled_date: "2024-04-25T13:00:00Z",
  updated_at: "2024-04-25T13:00:00Z"
}

export const AssignSearchParams: ITripSearchParams[] = [
  {label: "Email", value: "EMAIL", description: "Find user by email address"},
  {label: "Driver License", value: "DRIVER_LICENSE", description: "Find user by driver license document"},
  {label: "Registration Number", value: "VEHICLE_REGISTRATION", description: "Find user by vehicle plate number"},
]
