import MaintenanceImage from "../../assets/images/svgs/development-cuate.svg";

interface Props {
  pageName: string
}

const ComingSoon = ({pageName}: Props) => (
  <div className="mt-5">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="text-center">
            <div className="row justify-content-center mt-5">
              <div className="col-sm-6">
                <div className="maintenance-img">
                  <img src={MaintenanceImage} alt="" className="img-fluid mx-auto d-block"/>
                </div>
              </div>
            </div>
            <h4 className="mt-5">The {pageName} page is in development</h4>
            <p className="text-muted">We are working on optimising this page. Updates on this page would be rolled out
              soon!
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default ComingSoon;
