export const MockProfile: IProfile = {
  created_at: "",
  email: "",
  first_name: "",
  id: "",
  is_active: true,
  is_superadmin: false,
  last_login: "",
  last_name: "",
  phone: "",
  picture: "",
  permissions: {
    edit_admin: false,
    edit_blog: false,
    edit_business: false,
    edit_insight: false,
    edit_permission: false,
    edit_trip: false,
    edit_supply: false,
    edit_finance: false,
    view_admin: false,
    view_blog: false,
    view_business: false,
    view_insight: false,
    view_permission: false,
    view_trip: false,
    view_supply: false,
    view_finance: false,
  }
}

export const MockPermission: IProfilePermissions = {
  edit_admin: false,
  edit_blog: false,
  edit_business: false,
  edit_insight: false,
  edit_permission: false,
  edit_trip: false,
  edit_supply: false,
  edit_finance: false,
  view_admin: false,
  view_blog: false,
  view_business: false,
  view_insight: false,
  view_permission: false,
  view_trip: false,
  view_supply: false,
  view_finance: false,
}

export const MockWeather: IWeatherState = {
  id: "",
  main: "",
  icon: "",
  description: "",
}

export const MockTemperature: ITemperatureState = {
  feels_like: 33,
  grnd_level: 1006,
  humidity: 59,
  pressure: 1006,
  sea_level: 1006,
  temp: 33,
  temp_max: 33,
  temp_min: 33
}

export const MockPartner: IPartnerProfile = {
  id: "",
  organisation: "TRU_H0D4",
  user: {
    id: "",
    email: "",
    first_name: "",
    last_name: "",
    phone: ""
  },
  referrer: null,
  is_assigned_vehicle: true,
  assigned_vehicle: {
    id: "",
    organisation: "TRU_H0D4",
    dynamic_fields: {registration_number: ""},
    category_size: {
      id: "",
      category: "",
      tonnage: 0
    },
    is_interoperability: false
  },
  dynamic_fields: {licence_number: ""},
  is_interoperability: false,
  completed_trips: 0,
  total_revenue: 0,
  created_at: "",
  updated_at: ""
}