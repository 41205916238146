import {MouseEvent, useState} from "react";
import {NavLink} from "react-router-dom";

interface Props {
  updateView: (view: 'GRID' | 'LIST') => void
}

const ViewSwitcher = ({updateView}: Props) => {
  const [view, setView] = useState<'GRID' | 'LIST'>('GRID')

  const switchView = (event: MouseEvent, selected: 'GRID' | 'LIST'): void => {
    event.preventDefault()
    setView(selected)
    updateView(selected)
  }

  return (
    <div className="row align-items-center">
      <div className="col-md-6 mb-3"/>
      <div className="col-md-6">
        <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
          <ul className="nav nav-pills">
            <li className="nav-item">
              <NavLink onClick={(e: MouseEvent<HTMLAnchorElement>) => switchView(e, 'LIST')}
                       className={`nav-link pointer-event ${view === 'LIST' ? 'active' : ''}`} to="/">
                <i className="bx bx-list-ul"></i>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink onClick={(e: MouseEvent<HTMLAnchorElement>) => switchView(e, 'GRID')}
                       className={`nav-link pointer-event ${view === 'GRID' ? 'active' : ''}`} to="/">
                <i className="bx bx-grid-alt"></i>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ViewSwitcher;
