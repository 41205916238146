import {AppInfo} from "../../utils/helpers/constants";

const Footer = () => (
  <footer className="footer">
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-6"/>
        <div className="col-sm-6">
          <div className="text-sm-end d-none d-sm-block">
            {new Date().getFullYear()} © <a href="https://mytruq.com" target="_blank"
                                            rel="noreferrer">{AppInfo.APP_NAME}</a>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer;
