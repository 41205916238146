import {Page, Text, Image, Document, StyleSheet, Styles, Font, View, Line, Svg} from '@react-pdf/renderer';
import moment from "moment";

import {formatAmountToString, getYear, toTitleCase} from "../../utils/helpers/logicHelper";
import Logo from "../../assets/images/pngs/logo.png";

interface Props {
  trip: ITrip
  tripDetails: { [key: string]: string }[]
}

const styles: Styles = StyleSheet.create({
  page: {paddingTop: 25, paddingBottom: 65, paddingHorizontal: 15, fontFamily: 'Helvetica'},
  header: {marginBottom: 10, top: 25, position: 'relative'},
  logo: {width: '15%', position: 'absolute', left: 25, textAlign: 'left'},
  title: {fontSize: 24, position: 'absolute', right: 25, textAlign: 'right', fontFamily: 'Courier-Bold'},
  body: {marginVertical: 40, marginHorizontal: 25, top: 50, position: 'relative'},
  subHeader: {marginTop: 30, textAlign: 'left', fontFamily: 'Helvetica-Bold', fontSize: 12, fontWeight: 'bold'},
  pretext: {marginVertical: 10},
  textNude: {color: "#696969", fontSize: 9, fontWeight: 'bold', fontFamily: 'Helvetica-Bold', paddingBottom: 10},
  text: {fontSize: 9, textAlign: 'left', fontFamily: 'Helvetica'},
  bodyText: {fontSize: 10, fontFamily: 'Helvetica'},
  flexContainer: {display: 'flex', flexDirection: 'row'},
  pageNumber: {position: 'absolute', fontSize: 8, bottom: 10, left: 5, right: 0, textAlign: 'left', color: 'grey'},
  footerBrand: {position: 'absolute', fontSize: 8, bottom: 10, left: 0, right: 15, textAlign: 'right', color: 'grey'}
});

const TripReceipt = ({trip, tripDetails}: Props) => {

  Font.register({family: 'Oswald', src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'})

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image style={styles.logo} src={Logo}/>
          <Text style={styles.title} fixed>RECEIPT</Text>
        </View>
        <View style={styles.body}>
          <View style={styles.flexContainer}>
            <Text style={styles.textNude}>CUSTOMER</Text>
            <Text style={[styles.text, {marginLeft: 50}]}>
              {toTitleCase(trip.user.first_name)} {toTitleCase(trip.user.last_name)}
            </Text>
          </View>
          <View style={styles.flexContainer}>
            <Text style={styles.textNude}>Email Address</Text>
            <Text style={[styles.text, {marginLeft: 38}]}>{trip.user.email}</Text>
          </View>
          <View style={styles.flexContainer}>
            <Text style={styles.textNude}>Contact Details</Text>
            <Text style={[styles.text, {marginLeft: 36}]}>{trip.user.phone}</Text>
          </View>

          <Text style={styles.subHeader}>Trip Information</Text>
          <Svg height="5" width="520" style={styles.pretext}>
            <Line x1="0" y1={0} x2="595 - 2 * 40" y2={0} strokeWidth={1} stroke="#000"/>
          </Svg>

          <View style={[styles.flexContainer, {justifyContent: "flex-start", marginBottom: 20}]}>
            <View>
              <Text style={styles.textNude}>PickUp Location</Text>
              <Text style={styles.text}>{trip.source.address}</Text>
            </View>
            <View style={{marginLeft: 60}}>
              <Text style={styles.textNude}>DropOff Location</Text>
              <Text style={styles.text}>{trip.destination.address}</Text>
            </View>
          </View>
          <View style={[styles.flexContainer, {justifyContent: "flex-start", marginBottom: 20}]}>
            <View>
              <Text style={styles.textNude}>Scheduled Date</Text>
              <Text style={styles.text}>{moment(trip.scheduled_date).format('Do MMMM YYYY')}</Text>
            </View>
            <View style={{marginLeft: 200}}>
              <Text style={styles.textNude}>Trip Reference</Text>
              <Text style={styles.text}>{trip.reference}</Text>
            </View>
          </View>
          <View style={[styles.flexContainer, {justifyContent: "flex-start", marginBottom: 20}]}>
            <View>
              <Text style={styles.textNude}>Trip Type</Text>
              <Text style={styles.text}>{trip.trip_type}</Text>
            </View>
            <View style={{marginLeft: 215}}>
              <Text style={styles.textNude}>Estimated Value of Item</Text>
              <Text style={styles.text}>
                {formatAmountToString(trip.quote.estimated_value ?? '')} {trip.quote.currency}
              </Text>
            </View>
          </View>

          <Text style={styles.subHeader}>Charges and Billing</Text>
          <Svg height="5" width="520" style={styles.pretext}>
            <Line x1="0" y1={0} x2="595 - 2 * 40" y2={0} strokeWidth={1} stroke="#000"/>
          </Svg>

          {tripDetails.map((each: { [key: string]: string }, index: number) =>
            <View key={index} style={[styles.flexContainer, {marginBottom: 20}]}>
              <Text style={styles.bodyText}>{Object.keys(each)}</Text>
              <Text style={[styles.bodyText, {position: "absolute", right: 150}]}>{Object.values(each)}</Text>
            </View>
          )}

        </View>
        <Text style={styles.footerBrand} render={(): string => (`truQ Dynamic Services - \u00A9 ${getYear()}`)} fixed/>
      </Page>
    </Document>
  )
}

export default TripReceipt;
