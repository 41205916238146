import {useState, MouseEvent} from "react";
import {NavigateFunction, NavLink, useNavigate} from "react-router-dom";
import moment from "moment/moment";

import Gravatar from "../../assets/images/pngs/gravatar.png";
import {removeAuthentication} from "../../store/slices/auth.slice";

interface Props {
  profile: IProfile
}

const TopBarDropdown = ({profile}: Props) => {
  const navigate: NavigateFunction = useNavigate();
  const [dropdownStatus, setDropdownStatus] = useState<boolean>(false);

  const handleLockScreen =(event: MouseEvent): void => {
    event.preventDefault();
    navigate('/auth/lock-screen')
  }

  const handleLogout = async (event: MouseEvent, type: string): Promise<void> => {
    event.preventDefault();
    await removeAuthentication(navigate, type)
  }

  return (
    <div className="dropdown d-inline-block">
      <button onClick={() => setDropdownStatus(!dropdownStatus)} type="button"
              className="btn header-item user text-start d-flex align-items-center show"
              id="page-header-user-dropdown-v" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <img className="rounded-circle header-profile-user" src={profile.picture ?? Gravatar} alt="Avatar"/>
        <span className="d-none d-xl-inline-block ms-2 fw-medium font-size-15">
          {profile.first_name} {profile.last_name}
        </span>
      </button>
      {dropdownStatus &&
        <div className="dropdown-menu dropdown-menu-end pt-0 show">
          <div className="p-3 text-center border-bottom">
            <h6 className="mb-0">{profile.first_name} {profile.last_name}</h6>
            <p className="mb-0 font-size-10 mt-2 text-muted">{profile.email}</p>
            <p className="mb-0 font-size-10 text-muted">Last Login: {moment(profile.last_login).format('lll')}</p>
          </div>
          <NavLink className="dropdown-item" to="/lock-screen" onClick={(event: MouseEvent) => handleLockScreen(event)}>
            <i className="bx bx-lock text-muted font-size-16 align-middle me-2"></i>
            <span className="align-middle me-3">Lock Screen</span>
            <span className="badge bg-success-subtle text-success ms-auto">New</span>
          </NavLink>
          <div className="dropdown-divider"/>
          <NavLink className="dropdown-item" to="/logout" onClick={(event: MouseEvent) => handleLogout(event, '')}>
            <i className="bx bx-log-out text-muted font-size-16 align-middle me-2"></i>
            <span className="align-middle">Logout</span>
          </NavLink>
          <NavLink className="dropdown-item" to="/logout" onClick={(event: MouseEvent) => handleLogout(event, 'ALL')}>
            <i className="bx bx-log-out-circle text-muted font-size-16 align-middle me-2"></i>
            <span className="align-middle">Logout (All Devices)</span>
          </NavLink>
        </div>
      }
    </div>
  )
}


export default TopBarDropdown;
