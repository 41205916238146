import {createSlice, Dispatch} from '@reduxjs/toolkit'
import axios, {AxiosResponse} from "axios";
import toast from "react-hot-toast";

import {MockCountry} from "../../mocks/MockCountry";
import TokenHelper from "../../utils/helpers/tokenHelper";
import {getBaseUrl} from "../../utils/services/axios.service";

const initialState: ICountryState = {
  country: MockCountry,
  services: []
}

const countrySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {
    setCountry: (state: ICountryState, {payload: {country, services}}): void => {
      state.country = country
      state.services = services
    },
  }
})

export const dispatchCountry = async (dispatch: Dispatch, filter: string): Promise<void> => {
  try {
    let token = TokenHelper.getToken(), headers: IDynamicFields = {Authorization: `Bearer ${token}`}
    let url: string = `${getBaseUrl()['dodge']}/locale-service/configs/countries/${filter}`;
    let servUrl: string = `${getBaseUrl()['dodge']}/locale-service/configs/services/country/${filter}`;
    let countryReq: AxiosResponse = await axios.get(url, {headers}) as AxiosResponse;
    let servicesReq: AxiosResponse = await axios.get(servUrl, {headers}) as AxiosResponse;
    axios.all([countryReq, servicesReq]).then(axios.spread((...res: AxiosResponse[]): void => {
      dispatch(setCountry({country: res[0].data.data, services: res[1].data.data}));
    }));
  } catch (error: any) {
    toast.error(error.message);
  }
}

export const {setCountry} = countrySlice.actions

export default countrySlice.reducer;
