import {ChangeEvent, useState} from "react";
import {NavigateFunction, useNavigate} from "react-router-dom";
import Select, {SingleValue} from "react-select";
import toast from "react-hot-toast";
import moment from "moment";

import DatePickerHook from "../../../components/hooks/DatePickerHook";
import PageLayout from "../../../components/layouts/PageLayout";
import {SelectStyles} from "../../../utils/helpers/selectStyles";
import {MenuType} from "../../../utils/helpers/constants";
import {Banks} from "../../../mocks/Banks";
import {useCreateVoucherMutation, useGetSignatoriesQuery} from "../../../store/slices/backoffice.slice";


const CreateVoucher = () => {
  const navigate: NavigateFunction = useNavigate(), selectStyles: IDynamicFields = SelectStyles();
  const {data: signatories, isLoading: loadingSignatories, isSuccess} = useGetSignatoriesQuery('');
  const description: IVoucherDescription[] = [{details: "", amount: "", currency: "NGN"}];

  const [payee, setPayee] = useState<string>('');
  const [payeeAccount, setPayeeAccount] = useState<string>('');
  const [date, setDate] = useState<Date>(new Date());
  const [payeeBank, setPayeeBank] = useState<string | null>();
  const [approvedBy, setApprovedBy] = useState<string | null>()
  const [receivedBy, setReceivedBy] = useState<string | null>()
  const [tableData, setTableData] = useState<IVoucherDescription[]>(description);
  const [createVoucherMutation, {isLoading}] = useCreateVoucherMutation();
  const isValidated: boolean = !payee || !payeeAccount || !payeeBank || !date || !approvedBy || !receivedBy;

  const handleSetAccountNumber = (event: ChangeEvent<HTMLInputElement>): void => {
    const {maxLength} = event.target;
    const value: string = event.target.value.replace(/(?!\.)\D/g, "").slice(0, maxLength);
    setPayeeAccount(value)
  }

  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>, name: 'details' | 'amount', index: number): void => {
    const tableInput: IVoucherDescription[] = [...tableData], entity: IVoucherDescription = tableInput[index]
    entity[name] = event.target.value
    tableInput[index] = entity
    setTableData(tableInput)
  }

  const onAddNewRow = (index: number): void => {
    const tableInput: IVoucherDescription = {details: "", amount: "", currency: "NGN"};
    tableData.splice(index, 0, tableInput);
    setTableData([...tableData]);
  }

  const onRemoveRow = (index: number): void => {
    const rows: IVoucherDescription[] = [...tableData];
    rows.splice(index, 1);
    setTableData(rows);
  }

  const createVoucher = async (): Promise<void> => {
    try {
      let payload: IVoucherRequest = {
        payee, payee_bank: payeeBank, payee_bank_account: payeeAccount,
        scheduled_date: moment(date).format(), description: tableData,
        approved_by: approvedBy, received_by: receivedBy
      }
      let results = await createVoucherMutation(payload).unwrap();
      navigate(`/vouchers/${results.data.voucher_number}`)
    } catch (err: any) {
      toast.error(err.data.message)
    }
  }

  return (
    <PageLayout active={MenuType.VOUCHER} backEnabled title="Create Voucher" loading={loadingSignatories}>
      {isSuccess &&
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">New Voucher</h4>
                  <p className="card-title-desc">Create a new payment voucher, and forward for approval..</p>
                </div>
                <div className="card-body">
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="formrow-email-input" className="form-label">Payment to</label>
                          <input type="text" value={payee} className="form-control" placeholder="Enter Payee Name"
                                 onChange={(e: ChangeEvent<HTMLInputElement>) => setPayee(e.target.value)}/>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="formrow-password-input" className="form-label">Account Number</label>
                          <input type="text" value={payeeAccount} className="form-control"
                                 placeholder="Enter payee account number" maxLength={10}
                                 onChange={(e: ChangeEvent<HTMLInputElement>) => handleSetAccountNumber(e)}/>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="formrow-email-input" className="form-label">Bank</label>
                          <Select
                            aria-label="bank"
                            options={Banks}
                            getOptionLabel={(each: IBank): string => `${each['name']}`}
                            isClearable={false}
                            isSearchable={true}
                            styles={selectStyles}
                            maxMenuHeight={250}
                            menuPlacement="bottom"
                            onChange={(option: SingleValue<IBank>) => setPayeeBank(option?.name)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="formrow-email-input" className="form-label">Scheduled date</label>
                          <DatePickerHook placeholder="Select scheduled date" dateFormat="MMMM d, yyyy"
                                          selected={date} getDate={setDate} dropDownMode="select"
                                          setMinDate={new Date()} className="form-control form-lightblue"/>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label htmlFor="formrow-email-input" className="form-label">Payment Description</label>
                          <div className="table-responsive">
                            <table className="table table-bordered">
                              <thead>
                              <tr>
                                <th>#</th>
                                <th>Details of Payment</th>
                                <th>Amount</th>
                                <th>Action</th>
                              </tr>
                              </thead>
                              <tbody>
                              {tableData.map((description: IVoucherDescription, index: number) => (
                                <tr key={index}>
                                  <th scope="row">{index + 1}</th>
                                  <td>
                                    <input
                                      type="text" value={description.details} name="details"
                                      onChange={(e: ChangeEvent<HTMLInputElement>) => handleDescriptionChange(e, 'details', index)}
                                      className="form-control border-0 w-100"
                                      data-testid="details"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number" value={description.amount} name="amount"
                                      onChange={(e: ChangeEvent<HTMLInputElement>) => handleDescriptionChange(e, 'amount', index)}
                                      className="form-control border-0 w-100"
                                      data-testid="amount"
                                    />
                                  </td>
                                  <td>
                                    <button className="btn btn-sm btn-primary mx-1" type="button"
                                            onClick={() => onAddNewRow(index + 1)}
                                            data-testid="add-btn"><i className="bx bx-plus"/>
                                    </button>
                                    {index > 0 && (
                                      <button className="btn btn-sm btn-danger mx-1" type="button"
                                              onClick={() => onRemoveRow(index)}
                                              data-testid="add-btn"><i className="bx bx-minus"/>
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              ))}
                              </tbody>
                            </table>
                          </div>

                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="formrow-email-input" className="form-label">Approval By</label>
                          <Select
                            aria-label="approved-by"
                            options={signatories.data}
                            getOptionLabel={(each: ISignatory): string => `${each['first_name']} ${each['last_name']}`}
                            isClearable={false}
                            isSearchable={true}
                            styles={selectStyles}
                            maxMenuHeight={250}
                            menuPlacement="top"
                            onChange={(option: SingleValue<ISignatory>) => setApprovedBy(option?.id)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="formrow-email-input" className="form-label">Received By</label>
                          <Select
                            aria-label="received-by"
                            options={signatories.data.filter((each: ISignatory) => each.accounts)}
                            getOptionLabel={(each: ISignatory): string => `${each['first_name']} ${each['last_name']}`}
                            isClearable={false}
                            isSearchable={true}
                            styles={selectStyles}
                            maxMenuHeight={250}
                            menuPlacement="top"
                            onChange={(option: SingleValue<ISignatory>) => setReceivedBy(option?.id)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="d-flex align-items-start gap-3 mt-4">
                      <button disabled={isValidated} type="button" onClick={createVoucher}
                              className="btn btn-primary w-sm ms-auto">
                        Create Voucher {isLoading && <i className="bx bx-loader-circle bx-spin"/>}
                      </button>
                    </div>
                  </form>
                </div>

              </div>
            </div>
          </div>
        </div>
      }
    </PageLayout>
  )
}

export default CreateVoucher;
