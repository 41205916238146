import {useEffect, useMemo, useState} from "react";
import {NavigateFunction, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch} from "@reduxjs/toolkit";
import {CellContext, ColumnDef, ColumnHelper, createColumnHelper} from "@tanstack/react-table";

import PageLayout from "../../../components/layouts/PageLayout";
import SijuInsights from "./misc/SijuInsights";
import DataTable from "../../../components/dataTable/DataTable";
import {dispatchInsights} from "../../../store/slices/insights.slice";
import {MenuType} from "../../../utils/helpers/constants";
import {useGetOrganisationsQuery} from "../../../store/slices/dodge.slice";
import {OrganisationColumns} from "../../../components/dataTable/OrgColumn";


const Organisations = () => {
  const dispatch: Dispatch = useDispatch(), navigate: NavigateFunction = useNavigate();
  const date: Date = new Date(), year: string = date.getFullYear().toString();
  const [size] = useState<number>(20);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const loadedOverview: boolean = useSelector((state: IStoreState) => state.insights.loadedOverview)
  const overview: any = useSelector((state: IStoreState) => state.insights.overview)
  const {data: org, isLoading, isSuccess} = useGetOrganisationsQuery(`?size=${size}&page=${pageIndex}`)
  const column: ColumnHelper<IOrganisation> = createColumnHelper<IOrganisation>()
  const actionColumn: ColumnDef<IOrganisation, string> = (column.accessor('id', {
    header: () => <span className="text-center">Action</span>,
    cell: (info: CellContext<IOrganisation, string>) => {
      let reference: string = info.row.original.reference
      return (
        <span className="text-center pointer">
          <button className="btn btn-sm btn-primary"
                  onClick={() => navigate(`/saas/organisation/${reference}`, {state: reference})}>
            View Organisation
          </button>
        </span>
      )
    }
  }))

  useEffect((): void => {
    if (!loadedOverview) getOverview().catch(err => console.log(err))
  }, [])

  const getOverview = async (): Promise<void> => await dispatchInsights(dispatch, {year})
  const columns: ColumnDef<IOrganisation, string>[] = useMemo(() => [...OrganisationColumns, actionColumn], []);

  return (
    <PageLayout active={MenuType.SAAS} title="Siju by truQ - Organisations" loading={isLoading || !loadedOverview}>
      {isSuccess &&
        <div className="container-fluid">
          <SijuInsights overview={overview}/>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Onboarded Organisations</h4>
                  <p className="card-title-desc">List of organisations onboarded on Siju by truQ</p>
                </div>
                <div className="card-body">
                  <DataTable columns={columns} data={isLoading ? [] : org.data} pageIndex={pageIndex} size={size}
                             count={isLoading ? 0 : org.count} loadingState={!isSuccess}
                             setPageIndex={(index: number) => setPageIndex(index)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </PageLayout>
  )
}

export default Organisations;
