import {createSlice, Dispatch} from '@reduxjs/toolkit'
import toast from "react-hot-toast";
import PartnerService from "../../utils/services/partner.service";


const initialState: IPartnerState = {
  leaderboard: {lifetime: [], year: [], quarter: [], month: []},
  loadedLeaderboard: false
}

const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    setLeaderboard: (state: IPartnerState, {payload: leaderboard}): void => {
      state.leaderboard = leaderboard
      state.loadedLeaderboard = true
    }
  }
})

export const dispatchLeaderboard = async (dispatch: Dispatch): Promise<void> => {
  try {
    const {data: responseData} = await PartnerService.getLeaderboard();
    dispatch(setLeaderboard(responseData.data))
  } catch (error: any) {
    toast.error(error.message);
  }
}

export const {setLeaderboard} = partnerSlice.actions

export default partnerSlice.reducer;
