import {ChangeEvent, useState} from "react";
import Select, {SingleValue} from "react-select";
import axios, {AxiosResponse} from "axios";
import toast from "react-hot-toast";

import {PaymentPartnerTypes, PayOutTypes} from "../../../../mocks/MockCountry";
import TokenHelper from "../../../../utils/helpers/tokenHelper";
import {getBaseUrl} from "../../../../utils/services/axios.service";
import {SelectStyles} from "../../../../utils/helpers/selectStyles";

interface Props {
  country: ICountry
}

const CountryConfigs = ({country}: Props) => {

  const selectStyles = SelectStyles();
  const [loading, setLoading] = useState<boolean>(false)
  const [config, setConfig] = useState<ICountryConfig>(country.config);

  const setValue = (value: string, type: 'payment_config' | 'trip_config', opts: string): void => {
    let initConfig: ICountryConfig = {...config}, exp: string | number = type === 'trip_config' ? Number(value) : value;
    if (type === 'trip_config' && Number(exp) > 100) exp = 100
    else if (type === 'trip_config' && Number(exp) < 0) exp = 0
    let newConfig: ICountryConfig = {...initConfig, [type]: {...initConfig[type], [opts]: exp}}
    setConfig(newConfig)
  }

  const confirmUpdates = async (): Promise<void> => {
    try {
      setLoading(true)
      const payload: IDynamicFields = {
        payment_channel: config['payment_config']['payment_channel'],
        payout_channel: config['payment_config']['payout_channel'],
        saas_commission: config['trip_config']['saas_commission'], vat: config['trip_config']['vat']
      };
      let token = TokenHelper.getToken(), headers: IDynamicFields = {Authorization: `Bearer ${token}`}
      let url: string = `${getBaseUrl()['dodge']}/locale-service/configs/countries/update-configs/${country.country_code}`
      let responseData: AxiosResponse = await axios.patch(url, payload, {headers}) as AxiosResponse
      toast.success(responseData.data.message)
      setLoading(false)
    } catch (error: any) {
      toast.error(error.message);
    }
  }

  return (
    <div className="col-md-5">
      <div className="bg-light rounded px-4 py-3 mb-2">
        <h6>TRIP CONFIGURATION</h6>
        <p>Setup orders and booking configuration for {country.name}</p>
        <div className="col-md-12">
          <div className="mb-3">
            <label htmlFor="formrow-password-input" className="form-label">
              Siju by truQ Commission (%)
            </label>
            <input type="number" className="form-control" placeholder="" value={config.trip_config.saas_commission}
                   onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value, 'trip_config', 'saas_commission')}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="formrow-password-input" className="form-label">VAT (%)</label>
            <input type="number" className="form-control" placeholder="" value={config.trip_config.vat}
                   onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value, 'trip_config', 'vat')}
            />
          </div>
        </div>
      </div>
      <div className="bg-light rounded px-4 py-3">
        <h6>PAYMENT CONFIGURATION</h6>
        <p>Setup payment and orders configuration for {country.name}</p>
        <div className="col-md-12">
          <div className="mb-3">
            <label htmlFor="formrow-password-input" className="form-label">Payment Channel</label>
            <Select options={PaymentPartnerTypes}
                    value={PaymentPartnerTypes.filter((option: IDynamicFields): boolean => option.value === config.payment_config["payment_channel"])}
                    isClearable={false}
                    minMenuHeight={250}
                    styles={selectStyles}
                    menuPlacement="bottom"
                    name="payment_channel"
                    placeholder="Select Payment Partner..."
                    onChange={(option: SingleValue<IDynamicFields>) => setValue(option?.value, 'payment_config', 'payment_channel')}/>
          </div>
          <div className="mb-3">
            <label htmlFor="formrow-password-input" className="form-label">Payout Channel</label>
            <Select options={PayOutTypes}
                    value={PayOutTypes.filter((option: IDynamicFields): boolean => option.value === config.payment_config["payout_channel"])}
                    isClearable={false}
                    minMenuHeight={250}
                    styles={selectStyles}
                    menuPlacement="bottom"
                    name="payout_channel"
                    placeholder="Select Payout Partner..."
                    onChange={(option: SingleValue<IDynamicFields>) => setValue(option?.value, 'payment_config', 'payout_channel')}/>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <button onClick={confirmUpdates} className="btn btn-primary float-end">
          Confirm Updates {loading && <i className="bx bx-loader bx-spin"/>}
        </button>
      </div>
    </div>
  )
}

export default CountryConfigs;
