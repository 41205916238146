interface Props {
  text?: string
  description: string
  icon?: boolean
  type: 'success' | 'info' | 'warning' | 'danger'
  borderPlacement?: 'top' | 'left'
  dismissible: boolean
}

const Alerts = ({text, description, type, icon, borderPlacement, dismissible}: Props) => {

  const dismissAlert = (): void => {
    const alertElement: HTMLElement | null = document.getElementById("alert-ex");
    if (alertElement) alertElement.classList.add("d-none");
  }

  let alertType: string = type === 'info' ? 'alert-info' : type === 'warning' ? 'alert-warning' : type === 'danger' ? 'alert-danger' : 'alert-success'
  let alertIcon: string = type === 'info' ? 'bx-info-circle' : type === 'warning' ? 'bx-bolt-circle' : type === 'danger' ? 'bx-block' : 'bx-check-circle'
  let border: string = borderPlacement === 'top' ? 'alert-top-border' : borderPlacement === 'left' ? 'alert-border-left' : ''

  return (
    <div className={`alert ${alertType} ${border} alert-dismissible fade show mb-0`} id="alert-ex" role="alert">
      {icon && <i className={`bx ${alertIcon} me-2`}></i>}<strong>{text}</strong> {description}
      {dismissible && <button type="button" className="btn-close" data-bs-dismiss="alert" onClick={dismissAlert}
                              aria-label="Close"></button>}
    </div>
  )
}

export default Alerts;
