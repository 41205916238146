import {ReactElement} from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

interface Props {
  text: string
  placement: 'left' | 'right' | 'top' | 'bottom'
  children: ReactElement
}

const ToolTipComponent = ({text, placement, children}: Props) => {

  const renderTooltip = (props: any) => <Tooltip id="button-tooltip" {...props}>{text}</Tooltip>

  return <OverlayTrigger placement={placement} delay={{show: 250, hide: 400}}
                         overlay={renderTooltip}>{children}</OverlayTrigger>
}

export default ToolTipComponent;
