export const MockTopic: ITopics = {
  id: "",
  name: "",
  intro: "",
  topic: "",
  actions: [],
  media_action: [],
  breadcrumbs: [],
  sidebarTree: []
}

export const MediaActionTypes: IDynamicFields[] = [
  {label: 'Action Button', value: 'BUTTON', description: "Call-to-action button to redirect to a content or topic"},
  {label: 'Media Link', value: 'MEDIA', description: "Action to add a media image or link"},
  {label: 'YouTube Link', value: 'YOUTUBE', description: "Action to add a youtube preview video"},
];

export const MediaActionFill: IDynamicFields[] = [
  {label: 'Success', value: 'bg-success', description: ""},
  {label: 'Primary', value: 'bg-primary', description: ""},
  {label: 'Danger', value: 'bg-danger', description: ""},
  {label: 'Info', value: 'bg-info', description: ""},
  {label: 'Secondary', value: 'bg-secondary', description: ""},
];
