import {AxiosResponse} from "axios";
import {backOfficeInstance as Axios} from './axios.service';

interface IInsightsService {
  getOverview(): Promise<AxiosResponse>,

  getFinancialInsights(payload: IFinanceOverviewRequest): Promise<AxiosResponse>,
}

const InsightsService: IInsightsService = {

  getOverview(): Promise<AxiosResponse> {
    return Axios({
      method: 'GET',
      url: `integration-service/analytics/overview`
    });
  },

  getFinancialInsights(payload): Promise<AxiosResponse> {
    return Axios({
      method: 'POST',
      url: `integration-service/analytics/financials`,
      data: payload
    });
  },
};

export default InsightsService;
