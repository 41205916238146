import {ChangeEvent, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import toast from "react-hot-toast";
import {useUpdateReceiptMutation} from "../../store/slices/backoffice.slice";

interface Props {
  voucher: IVoucher
  onchange: () => void
}

const UploadVoucherReceiptModal = ({voucher, onchange}: Props) => {

  const [show, setShow] = useState<boolean>(true);
  const [amount, setAmount] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [receipt, setReceipt] = useState<string | ArrayBuffer | null>('');
  const [updateReceiptMutation, {isLoading}] = useUpdateReceiptMutation();
  const isValidated = !amount || !receipt;

  const handleClose = () => {
    setShow(false)
    onchange()
  }

  const onChange = (event: any): void => {
    event.preventDefault();
    let files: File[] = [], sizeLimit: number = 2097152, reader: FileReader = new FileReader();
    let allowedExt: string[] = ["jpg", "jpeg", "png", "pdf", "JPG", "JPEG", "PNG", "PDF"];
    if (event.dataTransfer) files = event.dataTransfer.files
    else if (event.target && event.target.files.length > 0) files = event.target.files
    let selectedFileSize: number = files[0].size || 0, fileExtension: string = files[0].name.split(".").pop() || '';

    if (event.target.files[0] && !allowedExt.includes(fileExtension)) toast.error("File must be an image");
    else if (selectedFileSize > sizeLimit) toast.error("File size too large");
    else {
      reader.onload = () => setReceipt(reader.result)
      reader.readAsDataURL(files[0])
    }
  }

  const createReceipt = async (): Promise<void> => {
    try {
      const base64File: string = typeof receipt === 'string' ? receipt.split(",")[1] : '';
      const filter: string = voucher.voucher_number, body: IUploadReceiptRequest = {base64File, amount, description}
      await updateReceiptMutation({filter, body}).unwrap();
      onchange()
    } catch (err: any) {
      toast.error(err.data.message)
    }
  }

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton><Modal.Title>Upload Receipt - {voucher.voucher_number}</Modal.Title></Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <div className="mb-3">
              <label htmlFor="formrow-email-input" className="form-label">Enter Amount on Receipt</label>
              <input type="text" className="form-control" placeholder="Amount on receipt" value={amount}
                     onChange={(e: ChangeEvent<HTMLInputElement>) => setAmount(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="formrow-email-input" className="form-label">Description</label>
              <textarea className="form-control" placeholder="Add a receipt description..." value={description}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setDescription(e.target.value)}/>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label htmlFor="formrow-password-input" className="form-label">Receipt Document</label>
              <input type="file" className="form-control" onChange={onChange}/>
              <div className="text-muted font-size-10">Permitted file extensions; PDFs, JPGs and PNGs</div>
            </div>
          </div>
        </div>
        <div className="mt-2 float-end">
          <button disabled={isValidated} className="btn btn-primary w-md" onClick={createReceipt}>
            Upload Receipt {isLoading && <i className="bx bx-loader bx-spin"/>}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default UploadVoucherReceiptModal;
