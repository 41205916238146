const InnerLoader = () => (
  <div className="lds-ellipsis">
    <div/>
    <div/>
    <div/>
    <div/>
  </div>
);

export default InnerLoader;
