import {useState, useEffect} from 'react';

interface Props {
  value: any
  delay: number
}

export const DebounceHook = ({value, delay}: Props) => {
  const [debouncedValue, setDebouncedValue] = useState<any>(value);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value]);

  return debouncedValue;
}
