import {useState} from "react";
import {Cell, Pie, PieChart, ResponsiveContainer, Sector, Tooltip} from "recharts";
import CustomTooltip from "./CustomTooltip";
import {formatAmountToString} from "../../utils/helpers/logicHelper";

interface Props {
  name: string
  data: IChart[]
}

const renderActive = (props: any) => {
  const RADIAN: number = Math.PI / 180;
  const {cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value} = props;
  const sin: number = Math.sin(-RADIAN * midAngle);
  const cos: number = Math.cos(-RADIAN * midAngle);
  const sx: number = cx + (outerRadius + 10) * cos;
  const sy: number = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor: "start" | "end" = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
      <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle}
              endAngle={endAngle} fill={fill}/>
      <Sector cx={cx} cy={cy} startAngle={startAngle} endAngle={endAngle} innerRadius={outerRadius + 6}
              outerRadius={outerRadius + 10} fill={fill}/>
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">
        {`${formatAmountToString(value)}`}
      </text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const PieCharts = ({name, data}: Props) => {

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const updateActive = (index: number) => setActiveIndex(index)

  return (
    <div className="card card-body card-h-100 pb-0">
      <h5 className="card-title mb-4">{name}</h5>
      <div style={{width: '100%', height: '100%'}}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={400} height={300} margin={{top: 10, right: 20, left: 20, bottom: 10}}>
            <Pie dataKey="value" data={data} cx="50%" cy="50%" innerRadius={60} activeIndex={activeIndex}
                 outerRadius={80} labelLine={false} activeShape={renderActive}
                 onMouseEnter={(_: any, index: number) => updateActive(index)}>
              {data.map((entry: IChart, index: number) => <Cell key={index} fill={entry.fill} stroke={entry.fill}/>)}
            </Pie>
            <Tooltip content={<CustomTooltip/>}/>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default PieCharts;
