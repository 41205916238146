export const MockOrganisation: IOrganisation = {
  bio: "",
  favicon: "",
  id: "",
  is_active: false,
  is_verified: false,
  logo: "",
  name: "",
  org_flow: "",
  reference: "",
  support_email: "",
  support_phone: "",
  supported_country: "",
  team_size: 1,
  created_at: "",
  updated_at: "",
  manager_id: "",
}

export const MockOrganisationWallet: IOrganisationWallet = {
  available_balance: "",
  ledger_balance: "",
  credit_balance: "",
  currency: "",
  name: "",
  org_flow: "",
  reference: "",
  wallet_id: "",
  status: false,
  withdrawal_configs: {
    can_withdraw: false
  },
  advanced_payment_configs: {
    advanced_payment_status: false,
    interest_rate: 0,
    outstanding_cap: 0,
  }
}

export const PaymentTypes: IPaymentType[] = [
  {name: "CREDIT", value: "CREDIT"},
  {name: "DEBIT", value: "DEBIT"},
]