import {ChangeEvent, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import toast from "react-hot-toast";

import {useAddStaffMutation} from "../../store/slices/backoffice.slice";

interface Props {
  onchange: () => void
}

const CreateStaffModal = ({onchange}: Props) => {

  const [show, setShow] = useState<boolean>(true);
  const [first_name, setFirstName] = useState<string>('');
  const [last_name, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [createStaffMutation, {isLoading}] = useAddStaffMutation();

  const handleClose = () => {
    setShow(false);
    onchange();
  }

  const createStaffProfile = async (): Promise<void> => {
    try {
      const callbackUrl = `${window.location.origin}/auth/reset-password`;
      const payload: ICreateStaffRequest = {callbackUrl, first_name, last_name, email, phone}
      await createStaffMutation(payload).unwrap();
      onchange()
    } catch (err: any) {
      toast.error(err.data.message)
    }
  }

  return (
    <Modal show={show} onHide={handleClose} size="lg" backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton><Modal.Title>Add Staff</Modal.Title></Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="formrow-email-input" className="form-label">First Name</label>
              <input type="text" className="form-control" placeholder="Enter First Name" value={first_name}
                     onChange={(e: ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="formrow-password-input" className="form-label">Last Name</label>
              <input type="text" className="form-control" placeholder="Enter Last Name" value={last_name}
                     onChange={(e: ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="formrow-email-input" className="form-label">Email Address</label>
              <input type="text" className="form-control" placeholder="Enter Email Address" value={email}
                     onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="formrow-password-input" className="form-label">Phone Number</label>
              <input type="text" className="form-control" placeholder="Enter Phone Number" value={phone}
                     onChange={(e: ChangeEvent<HTMLInputElement>) => setPhone(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="mt-2 float-end">
          <button className="btn btn-primary w-md" onClick={createStaffProfile}>
            Add Staff {isLoading && <i className="bx bx-loader bx-spin"/>}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default CreateStaffModal;
