import moment from "moment";

import NumberCard from "../../../../components/cards/NumberCard";
import {formatAmountToString} from "../../../../utils/helpers/logicHelper";

interface Props {
  overview: IOverview
}

const SijuInsights = ({overview}: Props) => {
  const date: Date = new Date(), year: number = date.getFullYear(), month: string = moment().format('MMMM')
  return (
    <div className="row">
      <div className="col-md-3">
        <NumberCard text={`${month} ${year} Balance`} icon="bxs-credit-card"
                    value={`₦${formatAmountToString(overview.finance.balance)}`}/>
      </div>
      <div className="col-md-3">
        <NumberCard text="Organisations" value={overview.organisations.total ?? 0} icon="bxs-business"/>
      </div>
      <div className="col-md-3">
        <NumberCard text="Onboarded Fleets" value={overview.fleet.total ?? 0} icon="bxs-truck"/>
      </div>
      <div className="col-md-3">
        <NumberCard text="Onboarded Drivers" value={overview.drivers.total ?? 0} icon="bxs-user-badge"/>
      </div>
    </div>
  )
}

export default SijuInsights;
