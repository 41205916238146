interface Props {
  text: string
}

const EmptyCard = ({text}: Props) => (
  <div className="card">
    <div className="card-body">
      <h5 className="font-size-16">{text}</h5>
    </div>
  </div>
)

export default EmptyCard;
