import {AxiosResponse} from "axios";
import {backOfficeInstance as Axios} from './axios.service';

interface IEditorService {
  getTopics(reference: string): Promise<AxiosResponse>
}

const EditorService: IEditorService = {

  getTopics(reference: string): Promise<AxiosResponse> {
    return Axios({
      method: 'GET',
      url: `/docs-service/directory/topics/${reference}`
    });
  }

};

export default EditorService;
