export const MockCountry: ICountry = {
  id: "0",
  name: "Nigeria",
  country_code: "NGA",
  currency: "Naira",
  currency_code: "NGN",
  status: true,
  config: {
    payment_config: {payment_channel: "", payout_channel: ""},
    trip_config: {vat: 0, saas_commission: 0}
  }
}

export const PaymentPartnerTypes: IDynamicFields[] = [
  {label: "Paystack Integration", value: "PAYSTACK"}
];

export const PayOutTypes: IDynamicFields[] = [
  {label: "Paystack Withdrawal", value: "PAYSTACK"},
  {label: "Payaza Withdrawal", value: "PAYAZA"},
];