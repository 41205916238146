import axios, {AxiosHeaders, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig} from 'axios';
import TokenHelper from "../helpers/tokenHelper";
import CookieHelper from "../helpers/cookieHelper";
import {AppInfo, AppKeys} from "../helpers/constants";

export const LOCALHOST: string = "localhost";
export const BETA_BASEURL: string = "dashboard-beta.mytruq.com";
export const PROD_BASEURL: string = "dashboard.mytruq.com";

export const getBaseUrl = (): IBaseUrl => {
  let organisation: string = AppInfo.ORGANISATION, backoffice: string = AppInfo.BACKOFFICE_BASE_URL;
  let dodge: string = AppInfo.DODGE_BASE_URL, cas: string = AppInfo.CAS_BASE_URL;
  if (window.location.hostname === LOCALHOST || window.location.hostname === BETA_BASEURL) {
    cas = AppInfo.CAS_STAGING_BASE_URL
    backoffice = AppInfo.BACKOFFICE_STAGING_BASE_URL
    dodge = AppInfo.DODGE_STAGING_BASE_URL
    organisation = AppInfo.STAGING_ORGANISATION
  } else if (window.location.hostname === PROD_BASEURL) {
    cas = AppInfo.CAS_BASE_URL
    backoffice = AppInfo.BACKOFFICE_BASE_URL
    dodge = AppInfo.DODGE_BASE_URL
    organisation = AppInfo.ORGANISATION
  }
  return {backoffice, organisation, cas, dodge}
}

const headers: IDynamicFields = {"Content-Type": "application/json"}
const backOfficeInstance: AxiosInstance = axios.create({baseURL: getBaseUrl()['backoffice'], headers})


backOfficeInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  let token = TokenHelper.getToken(), headers = {Authorization: `Bearer ${token}`, ...config.headers}
  if (TokenHelper.authenticationValid()) config.headers = headers as AxiosHeaders['headers'];
  else config.headers = {...config.headers} as AxiosHeaders['headers'];
  return config;
}, (error: AxiosInstance) => Promise.reject(error))

backOfficeInstance.interceptors.response.use((response: AxiosResponse) => response, error => {
  let token = TokenHelper.getToken();
  if (error.response.status === 401 && token) {
    CookieHelper.remove(AppKeys.TOKEN);
    window.location.href = '/auth/login';
  } else return error.response;
})


export {backOfficeInstance};
