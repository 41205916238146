import {ChangeEvent, useState} from "react";
import toast from "react-hot-toast";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Select, {SingleValue} from "react-select";

import {SelectStyles} from "../../utils/helpers/selectStyles";
import {toTitleCase} from "../../utils/helpers/logicHelper";
import {PaymentTypes} from "../../mocks/MockOrganisation";
import {useBalanceWalletLedgerMutation, useWalletManualEntryMutation} from "../../store/slices/dodge.slice";

interface Props {
  organisation: string
  onchange: () => void
}

interface IRequest {
  organisation: string
  type: string
  amount: string
  description: string
}

const WalletTransactionCanvas = ({organisation, onchange}: Props) => {
  const selectStyles = SelectStyles();
  const [show, setShow] = useState<boolean>(true);
  const [amount, setAmount] = useState<string>("")
  const [description, setDescription] = useState<string>("")
  const [type, setType] = useState<IPaymentType | null>(null)
  const isValidated: boolean = !type || !amount || !description;

  const [manualEntry, {isLoading}] = useWalletManualEntryMutation()
  const [balanceLedger, {isLoading: loadingBalance}] = useBalanceWalletLedgerMutation()

  const handleClose = (): void => {
    setShow(false);
    onchange();
  }

  const balanceWalletLedger = async (): Promise<void> => {
    try {
      let payload: IRequest = {type: type?.name ?? "", amount, description, organisation}
      await balanceLedger(payload).unwrap()
      handleClose()
    } catch (err: any) {
      toast.error(err.data.message)
    }
  }

  const walletTransaction = async (): Promise<void> => {
    try {
      let payload: IRequest = {type: type?.name ?? "", amount, description, organisation}
      await manualEntry(payload).unwrap()
      handleClose()
    } catch (err: any) {
      toast.error(err.data.message)
    }
  }

  return (
    <Offcanvas show={show} onHide={handleClose} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Organisation Wallet Transactions</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="row">
          <div className="col-md-12 mb-3">
            <label htmlFor="formrow-email-input" className="form-label">Select Payment Type</label>
            <Select
              aria-label="payment-type"
              options={PaymentTypes}
              getOptionLabel={(each: IPaymentType): string => `${each['name']}`}
              isClearable={false}
              isSearchable={true}
              maxMenuHeight={250}
              styles={selectStyles}
              menuPlacement="bottom"
              onChange={(option: SingleValue<IPaymentType>) => setType(option)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mb-3">
            <label htmlFor="formrow-input" className="form-label">Amount</label>
            <input type="text" className="form-control" placeholder="Enter Amount" value={amount}
                   onChange={(event: ChangeEvent<HTMLInputElement>) => setAmount(event.target.value)}/>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mb-3">
            <label htmlFor="formrow-input" className="form-label">Description</label>
            <textarea className="form-control" placeholder="Enter Description..." rows={4} value={description}
                      onChange={(event: ChangeEvent<HTMLTextAreaElement>) => setDescription(event.target.value)}/>
          </div>
        </div>
        {type &&
          <div className="mt-2 float-end">
            <button disabled={isValidated} className="btn btn-info w-md mx-1" onClick={balanceWalletLedger}>
              Balance Ledger {loadingBalance && <i className="bx bx-loader bx-spin"/>}
            </button>
            <button disabled={isValidated} className="btn btn-primary w-md mx-1" onClick={walletTransaction}>
              {`${toTitleCase(type?.name)} Wallet`} {isLoading && <i className="bx bx-loader bx-spin"/>}
            </button>
          </div>
        }
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default WalletTransactionCanvas;
