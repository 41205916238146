import {ColumnDef, ColumnHelper, createColumnHelper} from "@tanstack/react-table";
import moment from "moment";
import {formatAmountToString} from "../../utils/helpers/logicHelper";

const column: ColumnHelper<IOrganisationWalletEntries> = createColumnHelper<IOrganisationWalletEntries>()

export const WalletEntriesColumns: ColumnDef<IOrganisationWalletEntries, string>[] = [
  column.accessor('invoice_ref', {
    header: () => <span>Reference</span>,
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  column.accessor('type', {
    header: () => <span>Type</span>,
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  column.accessor('channel', {
    header: () => <span>Channel</span>,
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  column.accessor('amount', {
    header: () => <span>Amount</span>,
    cell: info => <span>{formatAmountToString(info.getValue())}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('ledger_balance', {
    header: () => <span>Balance</span>,
    cell: info => <span>{formatAmountToString(info.getValue())}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('date', {
    header: () => <span>Date</span>,
    cell: info => <span>{moment(info.getValue()).format('LL')}</span>,
    footer: info => info.column.id,
  })
];
