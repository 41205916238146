import {ChangeEvent, useEffect, useState} from "react";
import {Params, useParams} from "react-router-dom";
import toast from "react-hot-toast";

import PageLayout from "../../../components/layouts/PageLayout";
import TextEditor from "../../../components/modules/TextEditor";
import {MenuType} from "../../../utils/helpers/constants";
import {
  useGetContentQuery,
  useUpdateContentMutation,
  useUpdateContentStatusMutation
} from "../../../store/slices/backoffice.slice";



const Content = () => {

  const {topic, slug}: Readonly<Params> = useParams()
  const [title, setTitle] = useState<string>("");
  const [intro, setIntro] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const {data, isLoading, isSuccess} = useGetContentQuery(`${topic}/${slug}`)
  const [updateContentMutation, {isLoading: loading}] = useUpdateContentMutation();
  const [updateContentStatus, {isLoading: loadingStatusUpdate}] = useUpdateContentStatusMutation();

  const isValidated: boolean = !title || !intro || !content;

  useEffect((): void => {
    if (isSuccess) {
      setTitle(data.data.title ?? '')
      setIntro(data.data.intro ?? '')
      setContent(data.data.content ?? '')
    }
  }, [isSuccess]);

  const setEditorContent = (value: string): void => setContent(value);

  const updateContent = async (): Promise<void> => {
    try {
      const body: IDynamicFields = {title, intro, content}
      let result = await updateContentMutation({filter: `${topic}/${slug}`, body}).unwrap();
      toast.success(result.message)
    } catch (err: any) {
      toast.error(err.data.message)
    }
  }

  const changeTopicStatus = async (): Promise<void> => {
    try {
      const body: IDynamicFields = {is_active: !data.data.is_active};
      let result = await updateContentStatus({filter: `${topic}/${slug}`, body}).unwrap();
      toast.success(result.message);
    } catch (err: any) {
      toast.error(err.data.message);
    }
  }

  return (
    <PageLayout active={MenuType.DOCS} backEnabled title={`Documentations - Content - ${data?.data.title ?? ''} `}
                loading={isLoading}>
      {isSuccess &&
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-6"/>
            <div className="col-md-6">
              <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                <button onClick={changeTopicStatus}
                        className={`btn ${data?.data['is_active'] ? 'bg-danger' : 'bg-success'} pointer-event text-white`}>
                  <i className={`bx ${data?.data['is_active'] ? 'bx-block' : 'bx-check-double'} me-1`}/>
                  {data?.data['is_active'] ? 'Disable' : 'Enable'} Content
                  {loadingStatusUpdate && <i className="bx bx-loader bx-spin"/>}
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card card-body">
                <h5 className="font-size-16 mb-2">{data.data.title}</h5>
                <div className="row mt-3">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="formrow-email-input" className="form-label">Content Title</label>
                    <textarea rows={4} className="form-control" placeholder="Enter Content Title" value={title}
                              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setTitle(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="formrow-email-input" className="form-label">Content Intro</label>
                    <textarea rows={4} className="form-control" placeholder="Enter Content Intro"
                              value={intro}
                              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setIntro(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mt-3 mb-4">
                  <div className="col-md-12">
                    <TextEditor getContent={setEditorContent} currentValue={content}/>
                  </div>
                </div>
                <div className="mt-3">
                  <button disabled={isValidated} className="btn btn-primary float-end" onClick={updateContent}>
                    Update Docs Content {loading && <i className="bx bx-loader bx-spin"/>}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </PageLayout>
  )
}

export default Content;
