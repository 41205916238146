import moment from "moment";
import {NavigateFunction, useNavigate} from "react-router-dom";
import Gravatar from "../../assets/images/pngs/gravatar.png";
import {toTitleCase} from "../../utils/helpers/logicHelper";

interface Props {
  entries: IPerformanceEntry[]
  description?: boolean
}

const RewardEntryCard = ({entries, description}: Props) => {
  const navigate: NavigateFunction = useNavigate()

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex flex-wrap align-items-center mb-3">
          <h5 className="card-title me-2">Performance Reward Entries</h5>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-centered align-middle table-nowrap mb-0 table-check">
            <thead>
            <tr>
              <th>Reference</th>
              <th>Driver</th>
              {description && <th>Description</th>}
              <th className="text-center">Points</th>
              <th className="text-center">Total Points</th>
              <th>Type</th>
              <th>Date</th>
              <th>Status</th>
            </tr>
            </thead>
            <tbody>
            {entries.slice(0, 30).map((entry: IPerformanceEntry, index: number) => (
              <tr key={index}>
                <td className="fw-semibold">{entry.reference}</td>
                <td>
                  <div onClick={() => navigate(`/supply/driver/${entry.email}`, {state: entry.id})}
                       className="d-flex align-items-center pointer-event">
                    <img className="rounded-circle avatar-sm" src={Gravatar} alt=""/>
                    <div className="flex-grow-1 ms-3">
                      {toTitleCase(entry.first_name)} {toTitleCase(entry.last_name)}
                    </div>
                  </div>
                </td>
                <td>{entry.description}</td>
                <td className="text-center">{entry.points}</td>
                <td className="text-center">{entry.total_points}</td>
                <td>{entry.type}</td>
                <td>{moment(entry.date).format("D MMM")}</td>
                <td>
                  <div className="badge bg-success-subtle text-success font-size-12">{entry.status}</div>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default RewardEntryCard;
