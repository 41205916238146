import CookieHelper from "./cookieHelper";
import {AppKeys} from "./constants";

export const SelectStyles = () => {
  let initTheme: string = CookieHelper.get(AppKeys.THEME) ?? 'light';
  let backgroundColor: string = initTheme === 'dark' ? '#202224' : '#FFFFFF';
  let color: string = initTheme === 'dark' ? '#FFFFFF' : '#202224';
  let primaryColor: string = '#86a3e1'

  return {
    container: (provided: any) => ({...provided, width: "100%", color, backgroundColor}),
    singleValue: (provided: any) => ({...provided, overflow: "none", color, backgroundColor}),
    control: (provided: any) => ({
      ...provided,
      color,
      backgroundColor,
      padding: "0 10px 0 0",
      borderColor: backgroundColor
    }),
    menu: (provided: any) => ({...provided, color, backgroundColor}),
    placeholder: (provided: any) => ({...provided, color, backgroundColor}),
    option: (provided: any, {isFocused}: any) => ({
      ...provided,
      color,
      backgroundColor: isFocused ? primaryColor : backgroundColor,
      fontSize: "14px",
      cursor: "pointer",
      borderColor: 'red'
    })
  }
};