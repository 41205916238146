import {ChangeEvent, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import toast from "react-hot-toast";
import {useUpdateDriverLeadMutation} from "../../store/slices/backoffice.slice";

interface Props {
  driverLead: IDriverLead
  onchange: () => void
}

interface ILeadInfo {
  first_name: string
  last_name: string
  email: string
  phone: string
  extras: IDynamicFields
}

const UpdateLeadModal = ({driverLead, onchange}: Props) => {

  const [show, setShow] = useState<boolean>(true);
  const [first_name, setFirstName] = useState<string>(driverLead.first_name ?? '');
  const [last_name, setLastName] = useState<string>(driverLead.last_name ?? '');
  const [email, setEmail] = useState<string>(driverLead.email ?? '');
  const [phone, setPhone] = useState<string>(driverLead.phone ?? '');
  const [city, setCity] = useState<string>(driverLead.extras.city ?? '');
  const [state, setState] = useState<string>(driverLead.extras.state ?? '');
  const [license_number, setLicense] = useState<string>(driverLead.extras.license_number ?? '');

  const [updateLeadMutation, {isLoading}] = useUpdateDriverLeadMutation();

  const handleClose = () => {
    setShow(false)
    onchange()
  }

  const updateLeadInfo = async (): Promise<void> => {
    try {
      let filter: string = `update/${driverLead.id}`;
      let extras: IDynamicFields = {city, state, license_number}
      let body: ILeadInfo = {first_name, last_name, email, phone, extras}
      await updateLeadMutation({filter, body}).unwrap();
      handleClose();
    } catch (err: any) {
      toast.error(err.data.message)
    }
  }

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" size="lg" keyboard={false} centered>
      <Modal.Header closeButton><Modal.Title>Update Lead Info - {driverLead.phone} </Modal.Title></Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="formrow-email-input" className="form-label">First Name</label>
              <input type="text" className="form-control" placeholder="Enter First Name" value={first_name}
                     onChange={(e: ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}/>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="formrow-password-input" className="form-label">Last Name</label>
              <input type="text" className="form-control" placeholder="Enter Last Name" value={last_name}
                     onChange={(e: ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}/>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="formrow-email-input" className="form-label">Email Address</label>
              <input type="text" className="form-control" placeholder="Enter Email Address" value={email}
                     onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}/>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label className="form-label">Phone Number</label>
              <input type="text" className="form-control" placeholder="Enter Phone Number" value={phone}
                     onChange={(e: ChangeEvent<HTMLInputElement>) => setPhone(e.target.value)}/>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label className="form-label">City</label>
              <input type="text" className="form-control" placeholder="Enter City of Location" value={city}
                     onChange={(e: ChangeEvent<HTMLInputElement>) => setCity(e.target.value)}/>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label className="form-label">State</label>
              <input type="text" className="form-control" placeholder="Enter State of Location" value={state}
                     onChange={(e: ChangeEvent<HTMLInputElement>) => setState(e.target.value)}/>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label className="form-label">License Number</label>
              <input type="text" className="form-control" placeholder="Enter Verified License Number"
                     value={license_number} onChange={(e: ChangeEvent<HTMLInputElement>) => setLicense(e.target.value)}
              />
            </div>
          </div>

        </div>
        <div className="mt-2 float-end">
          <button className="btn btn-primary w-md" onClick={updateLeadInfo}>
            Update Lead Information {isLoading && <i className="bx bx-loader bx-spin"/>}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default UpdateLeadModal;
