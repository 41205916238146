import axios, {AxiosResponse} from "axios";
import {backOfficeInstance as Axios} from './axios.service';

interface IProfileService {
  getProfile(): Promise<AxiosResponse>,

  getWeatherInfo(): Promise<AxiosResponse>,

  getPermissionMatrices(filter: string): Promise<AxiosResponse>

  toggleMatrix(id: string, payload: IPermissionUpdateRequest): Promise<AxiosResponse>
}

const ProfileService: IProfileService = {

  getProfile(): Promise<AxiosResponse> {
    return Axios({
      method: 'GET',
      url: `user-service/profile`
    });
  },

  getWeatherInfo(): Promise<AxiosResponse> {
    return axios({
      method: 'post',
      url: 'https://api.openweathermap.org/data/2.5/weather?lat=6.602110&lon=3.412110&appid=266a8ca65b8f4ce490781b61cd04711c&units=metric'
    });
  },

  getPermissionMatrices(filter: string): Promise<AxiosResponse> {
    return Axios({
      method: 'GET',
      url: `/user-service/permissions/${filter}`,
    });
  },

  toggleMatrix(id: string, payload: IPermissionUpdateRequest): Promise<AxiosResponse> {
    return Axios({
      method: 'PATCH',
      url: `/user-service/permissions/update/${id}`,
      data: payload
    });
  },

};

export default ProfileService;
