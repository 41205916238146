import {verifyTimestamp} from "../../../../utils/helpers/logicHelper";
import moment from "moment/moment";

interface Props {
  listIndex: number
  document: IDriverPartner
  type: "expiredDriversDocs" | "expiringDriversDocs" | "expiredVehicleDocs" | "expiringVehicleDocs"
  loading: boolean
  actionIndex: number
  sendNotification: (document: IDriverPartner, docType: string, badgeText: string, key: number) => void
}

const DocumentExpiryList = ({listIndex, document, type, loading, actionIndex, sendNotification}: Props) => {
  const date: Date = new Date();
  const badgeBg: string = type === 'expiredDriversDocs' || type === 'expiredVehicleDocs' ? 'bg-danger-subtle' : 'bg-warning-subtle';
  const badgeColor: string = type === 'expiredDriversDocs' || type === 'expiredVehicleDocs' ? 'text-danger' : 'text-warning';
  const badgeText: string = type === 'expiredDriversDocs' || type === 'expiredVehicleDocs' ? 'EXPIRED' : 'EXPIRING';
  const docType: string = type === 'expiredDriversDocs' || type === 'expiringDriversDocs' ? 'license_number' : 'registration_number';

  return (
    <tr key={listIndex}>
      <td>{document.first_name} {document.last_name}</td>
      <td>
        <span className={`badge ${badgeBg} ${badgeColor} mb-0`}>{badgeText} - {document.extras[docType]}</span>
      </td>
      <td>
        {docType === 'license_number' &&
          <p className="mb-0 mt-2">
            <i className="bx bx-file font-size-15 align-middle pe-2 text-primary"></i> LICENSE NUMBER
          </p>
        }
        {docType === 'registration_number' && verifyTimestamp(document.extras.insurance_document_expiry_date ?? '') < date &&
          <p className="mb-0 mt-2">
            <i className="bx bx-file font-size-15 align-middle pe-2 text-primary"></i>INSURANCE DOCUMENT
          </p>
        }
        {docType === 'registration_number' && verifyTimestamp(document.extras.hackney_permit_document_expiry_date ?? '') < date &&
          <p className="mb-0 mt-2">
            <i className="bx bx-file font-size-15 align-middle pe-2 text-primary"></i>HACKNEY PERMIT DOCUMENT
          </p>
        }
        {docType === 'registration_number' && verifyTimestamp(document.extras.road_worthiness_document_expiry_date ?? '') < date &&
          <p className="mb-0 mt-2">
            <i className="bx bx-file font-size-15 align-middle pe-2 text-primary"></i>ROAD WORTHINESS DOCUMENT
          </p>
        }
      </td>
      <td>{moment(document.extras.license_expires_on).format('LL')}</td>
      <td>{document.email}</td>
      <td>{document.phone}</td>
      <td>
        <span className="text-center pointer">
          <button disabled type="button" className="btn btn-subtle-primary mx-1 btn-sm w-50">
            Renew Document
          </button>
          <button type="button" onClick={() => sendNotification(document, docType, badgeText, listIndex)}
                  className="btn btn-subtle-primary mx-1 btn-sm w-50">
            Notify Driver {loading && listIndex === actionIndex && <i className="bx bx-loader bx-spin"/>}
          </button>
        </span>
      </td>
    </tr>
  )
}

export default DocumentExpiryList;
