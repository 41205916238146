interface Props {
  searchFn: (params: string) => void
}

const TableFilter = ({searchFn}: Props) => (
  <div className="row mb-2">
    <div className="col-xl-4 col-md-6">
      <div className="pb-3 pb-xl-0">
        <form className="table-search">
          <div className="position-relative">
            <input type="text" className="form-control bg-light" placeholder="Search..."
                   onChange={event => searchFn(event.target.value.toUpperCase())}/>
            <span className="bx bx-search font-size-18"></span>
          </div>
        </form>
      </div>
    </div>
  </div>
)

export default TableFilter
