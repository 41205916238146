import {AxiosResponse} from "axios";
import {backOfficeInstance as Axios} from './axios.service';

interface IPartnerService {
  getLeaderboard(): Promise<AxiosResponse>,

  searchDriver(payload: ISearchRequest): Promise<AxiosResponse>,
}

const PartnerService: IPartnerService = {

  getLeaderboard(): Promise<AxiosResponse> {
    return Axios({
      method: 'GET',
      url: `partners-service/performance/leaderboard`
    });
  },

  searchDriver(payload: ISearchRequest): Promise<AxiosResponse> {
    return Axios({
      method: 'POST',
      url: `/integration-service/partners/search`,
      data: payload
    });
  },

};

export default PartnerService;
