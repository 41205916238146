import {useState} from "react";
import {AppKeys} from "../../utils/helpers/constants";
import CookieHelper from "../../utils/helpers/cookieHelper";

interface Props {
  setThemeContext: (theme: string) => void
}

const TopBarTheme = ({setThemeContext}: Props) => {

  const initTheme = CookieHelper.get(AppKeys.THEME);
  const [theme, setTheme] = useState<string>(initTheme)

  const changeAppTheme = (): void => {
    let newTheme: string = theme === 'light' ? 'dark' : 'light', body: HTMLElement = document.body
    CookieHelper.set(AppKeys.THEME, newTheme, AppKeys.COOKIE_EXPIRY_PERIOD);
    if (body) body.setAttribute('data-bs-theme', newTheme);
    setThemeContext(newTheme)
    setTheme(newTheme)
  }

  return (
    <div className="dropdown d-inline-block">
      <button type="button" onClick={changeAppTheme} className="btn header-item noti-icon"
              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i className={`bx ${theme === 'dark' ? 'bx-moon' : 'bx-sun'} icon-sm align-middle`}></i>
      </button>
    </div>
  )
}


export default TopBarTheme;
