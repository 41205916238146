import {ReactElement} from "react";
import {BrowserRouter as Router, Navigate, Outlet, Route, Routes} from "react-router-dom";
import {Toaster} from "react-hot-toast";

import Login from "./pages/authentication/Login";
import LockScreen from "./pages/authentication/LockScreen";
import Home from "./pages/dashboard/home/Home";
import SijuHome from "./pages/dashboard/home/SijuHome";
import Standard from "./pages/dashboard/trips/Standard";
import TripInfo from "./pages/dashboard/trips/TripInfo";
import DriverProfile from "./pages/dashboard/supply/DriverProfile";
import DriverLeads from "./pages/dashboard/supply/DriverLeads";
import DriverPerformance from "./pages/dashboard/supply/DriverPerformance";
import DocumentExpiry from "./pages/dashboard/supply/DocumentExpiry";
import Messaging from "./pages/dashboard/messaging/Messaging";
import CreateMessaging from "./pages/dashboard/messaging/CreateMessaging";
import Voucher from "./pages/dashboard/voucher/Voucher";
import VoucherInfo from "./pages/dashboard/voucher/VoucherInfo";
import CreateVoucher from "./pages/dashboard/voucher/CreateVoucher";
import Docs from "./pages/dashboard/docs/Docs";
import Content from "./pages/dashboard/docs/Content";
import Staff from "./pages/dashboard/staff/Staff";
import Signatories from "./pages/dashboard/staff/Signatories";
import SupportedCountries from "./pages/dashboard/saas/SupportedCountries";
import Organisations from "./pages/dashboard/saas/Organisations";
import OrganisationProfile from "./pages/dashboard/saas/OrganisationProfile";
import Services from "./pages/dashboard/saas/Services";
import ServiceInfo from "./pages/dashboard/saas/misc/ServiceInfo";
import TenantPermissions from "./pages/dashboard/saas/TenantPermissions";
import Error404 from "./pages/misc/Error404";


import TokenHelper from "./utils/helpers/tokenHelper";


const App = () => {

  const AuthenticatedRoutes = (): ReactElement => {
    if (TokenHelper.authenticationValid()) return <Outlet/>
    else return <Navigate to="auth/login" replace/>;
  }

  return (
    <Router>
      <Toaster containerStyle={{zIndex: '99999999'}}/>
      <Routes>
        <Route path="auth/login" element={<Login/>}/>
        <Route element={<AuthenticatedRoutes/>}>
          {['/', 'home', 'dashboard'].map((path: string) => <Route path={path} key={path} element={<Home/>}/>)}
          <Route path="siju" element={<SijuHome/>}/>
          <Route path="trips">
            <Route path="standard" element={<Standard/>}/>
            <Route path=":reference" element={<TripInfo/>}/>
          </Route>
          <Route path="supply">
            <Route path="driver/:reference" element={<DriverProfile/>}/>
            <Route path="leads" element={<DriverLeads/>}/>
            <Route path="performance" element={<DriverPerformance/>}/>
            <Route path="expiring-documents" element={<DocumentExpiry/>}/>
          </Route>
          <Route path="saas">
            <Route path="supported-countries" element={<SupportedCountries/>}/>
            <Route path="organisations" element={<Organisations/>}/>
            <Route path="organisation/:reference" element={<OrganisationProfile/>}/>
            <Route path="services" element={<Services/>}/>
            <Route path="service/:reference" element={<ServiceInfo/>}/>
            <Route path="permissions" element={<TenantPermissions/>}/>
          </Route>
          <Route path="messaging">
            <Route path="" element={<Messaging/>}/>
            <Route path="create" element={<CreateMessaging/>}/>
          </Route>
          <Route path="documentation">
            <Route path="topics" element={<Docs/>}/>
            <Route path="content/:topic/:slug" element={<Content/>}/>
          </Route>
          <Route path="vouchers">
            <Route path="" element={<Voucher/>}/>
            <Route path=":reference" element={<VoucherInfo/>}/>
            <Route path="create" element={<CreateVoucher/>}/>
          </Route>
          <Route path="staff" element={<Staff/>}/>
          <Route path="signatories" element={<Signatories/>}/>

          <Route path="auth/lock-screen" element={<LockScreen/>}/>
        </Route>
        <Route path="*" element={<Error404/>}/>
      </Routes>
    </Router>
  );
}

export default App;
