import {NavLink} from "react-router-dom";

const Error404 = () => (
  <div className="min-vh-100">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="text-center py-5 mt-5">
            <div className="position-relative">
              <h1 className="error-title error-text mb-0">404</h1>
              <h4 className="error-subtitle text-uppercase mb-0">Page not found</h4>
            </div>
            <div className="mt-4 text-center">
              <NavLink className="btn btn-primary" to="/">Back to Home</NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Error404;
