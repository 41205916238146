import {ChangeEvent, useEffect, useState} from "react";
import {Params, useParams} from "react-router-dom";
import toast from "react-hot-toast";

import PageLayout from "../../../../components/layouts/PageLayout";
import TextEditor from "../../../../components/modules/TextEditor";
import {MenuType} from "../../../../utils/helpers/constants";
import {useGetServicesQuery, useUpdateServiceMutation} from "../../../../store/slices/dodge.slice";


const ServiceInfo = () => {

  const {reference}: Readonly<Params> = useParams();
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [contentData, setContentData] = useState<string>("");
  const {data: service, isLoading, isSuccess} = useGetServicesQuery(reference)
  const [updateServiceMutation, {isLoading: loading}] = useUpdateServiceMutation();
  const isValidated: boolean = !name || !description || !contentData;

  useEffect((): void => {
    if (isSuccess) {
      setName(service.data.name ?? '')
      setDescription(service.data.description ?? '')
      setContentData(service.data.overview ?? '')
    }
  }, [isSuccess]);

  const setEditorContent = (value: string): void => setContentData(value);

  const updateService = async (): Promise<void> => {
    try {
      const body: IDynamicFields = {name, description, overview: contentData}
      let result = await updateServiceMutation({filter: reference, body}).unwrap();
      toast.success(result.message)
    } catch (err: any) {
      toast.error(err.data.message)
    }
  }

  return (
    <PageLayout active={MenuType.SAAS} backEnabled title={`Siju by truQ - ${reference} Service`} loading={isLoading}>
      {isSuccess &&
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-body">
                <h5 className="font-size-16 mb-2">{service.data.name}</h5>
                <div className="row mt-3">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="formrow-email-input" className="form-label">Service Name</label>
                    <input type="text" className="form-control" placeholder="Enter Service Name" value={name}
                           onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="formrow-email-input" className="form-label">Service Description</label>
                    <input type="text" className="form-control" placeholder="Enter Service Description"
                           value={description}
                           onChange={(e: ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mt-3 mb-4">
                  <div className="col-md-12">
                    <TextEditor getContent={setEditorContent} currentValue={contentData}/>
                  </div>
                </div>
                <div className="mt-3">
                  <button disabled={isValidated} className="btn btn-primary float-end" onClick={updateService}>
                    Update Service {loading && <i className="bx bx-loader bx-spin"/>}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </PageLayout>
  )
}

export default ServiceInfo;
