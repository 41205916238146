import {createSlice, Dispatch, Store} from '@reduxjs/toolkit'
import {NavigateFunction} from "react-router-dom";
import axios from "axios";

import TokenHelper from "../../utils/helpers/tokenHelper";
import ProfileService from "../../utils/services/profile.service";
import CookieHelper from "../../utils/helpers/cookieHelper";
import {AppKeys} from "../../utils/helpers/constants";
import {MockProfile, MockTemperature, MockWeather} from "../../mocks/MockProfile";
import {getBaseUrl} from "../../utils/services/axios.service";


const initialState: IAuthState = {
  token: '',
  userProfile: MockProfile,
  weatherForecast: MockWeather,
  temperatureForecast: MockTemperature,
  loadingMatrices: false,
  permissionMatrices: []
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state: IAuthState, {payload: access}): void => {
      state.token = access
    },
    setProfile: (state: IAuthState, {payload: userProfile}): void => {
      state.userProfile = userProfile
    },
    setForecast: (state: IAuthState, {payload: {weather, main}}): void => {
      state.weatherForecast = weather[0]
      state.temperatureForecast = main
    },
    setPermissionMatrix: (state: IAuthState, {payload: matrices}): void => {
      state.loadingMatrices = true
      state.permissionMatrices = matrices
    }
  }
})

export const onAuthentication = (dispatch: Dispatch, access: string): void => {
  TokenHelper.saveToken(access)
  const userAuthenticated: boolean = TokenHelper.authenticationValid()
  if (userAuthenticated) getProfile(dispatch).catch(err => console.log(err))
}

export const onRTKPersist = (store: Store): void => {
  let initTheme: string = CookieHelper.get(AppKeys.THEME)
  document.body.setAttribute('data-bs-theme', initTheme)
  let userAuthenticated: boolean = TokenHelper.authenticationValid()
  if (userAuthenticated) getProfile(store.dispatch).catch(err => console.log(err))
}

export const getProfile = async (dispatch: Dispatch): Promise<void> => {
  try {
    let token = TokenHelper.getToken()
    await Promise.all([await ProfileService.getProfile(), await ProfileService.getWeatherInfo()]).then(response => {
      let profile = response[0]['data']['data'], forecast = response[1]['data']
      dispatch(setAuth(token))
      dispatch(setProfile(profile))
      dispatch(setForecast(forecast))
    });
  } catch (error: any) {
    if (error && error.status === 401) {
      CookieHelper.remove(AppKeys.TOKEN)
      window.location.href = '/auth/login';
    } else console.error(error)
  }
}

export const removeAuthentication = async (navigate: NavigateFunction, type: string): Promise<void> => {
  let token = TokenHelper.getToken(), headers: IDynamicFields = {Authorization: `Bearer ${token}`}
  let url: string = type === 'ALL' ? `${getBaseUrl().cas}auth-service/logout` : `${getBaseUrl().cas}auth-service/logout-devices`
  await axios.get(url, {headers});
  CookieHelper.remove(AppKeys.TOKEN)
  localStorage.clear()
  navigate('/auth/login')
}

export const {setAuth, setProfile, setForecast, setPermissionMatrix} = authSlice.actions

export default authSlice.reducer;
