import Gravatar from "../../assets/images/pngs/gravatar.png";
import {toTitleCase} from "../../utils/helpers/logicHelper";

interface Props {
  profile: IOrganisation
}

const OrgProfileCard = ({profile}: Props) => {

  return (
    <div className="card">
      <div className="card-body p-0">
        <div className="user-profile-img">
          <img src={profile.logo ?? Gravatar} className="profile-img rounded-top" style={{height: '120px'}} alt=""/>
          <div className="overlay-content rounded-top"/>
        </div>
        <div className="p-4 pt-0">
          <div className="mt-n5 position-relative text-center border-bottom pb-3">
            <img src={profile.favicon ?? Gravatar} alt="" className="avatar-xl rounded-circle img-thumbnail"/>
            <div className="mt-3">
              <h5 className="mb-1">{toTitleCase(profile.name)}</h5>
              <span className="badge rounded-pill bg-primary mx-1 pointer-event">{profile.reference}</span>
            </div>
          </div>
          <div className="table-responsive mt-3 pb-3">
            <table className="table align-middle table-sm table-nowrap table-borderless mb-0">
              <tbody>
              <tr>
                <th className="fw-bold">Type</th>
                <td className="text-muted">{profile.org_flow}</td>
              </tr>
              <tr>
                <th className="fw-bold">Manager</th>
                <td className="text-muted">{profile.manager?.first_name} {profile.manager?.last_name}</td>
              </tr>
              <tr>
                <th className="fw-bold">Manager Email</th>
                <td className="text-muted">{profile.manager?.email ?? '-'}</td>
              </tr>
              <tr>
                <th className="fw-bold">Manager Phone</th>
                <td className="text-muted">{profile.manager?.phone ?? '-'}</td>
              </tr>
              <tr>
                <th className="fw-bold">Support Email</th>
                <td className="text-muted">{profile.support_email ?? '-'}</td>
              </tr>
              <tr>
                <th className="fw-bold">Support Phone</th>
                <td className="text-muted">{profile.support_phone ?? '-'}</td>
              </tr>
              <tr>
                <th className="fw-bold">Country</th>
                <td className="text-muted">{profile.country?.name ?? '-'}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrgProfileCard;
