import PageLayout from "../../../components/layouts/PageLayout";
import ComingSoon from "../../../components/modules/ComingSoon";
import {MenuType} from "../../../utils/helpers/constants";

const TenantPermissions = () => {
  return (
    <PageLayout active={MenuType.SAAS} title="Siju by truQ - Tenant Permissions">
      <ComingSoon pageName="Tenant Permissions"/>
    </PageLayout>
  )
}

export default TenantPermissions;
