import {ChangeEvent, useState} from "react";
import toast from "react-hot-toast";
import Modal from 'react-bootstrap/Modal';
import Select, {SingleValue} from "react-select";

import {useUpdateTopicMutation} from "../../store/slices/backoffice.slice";
import {MediaActionFill, MediaActionTypes} from "../../mocks/MockEditor";
import {SelectStyles} from "../../utils/helpers/selectStyles";
import {generateSlug, validateURLPath} from "../../utils/helpers/logicHelper";

interface Props {
  topic: ITopics
  onchange: () => void
}

const NewTopicActionModal = ({topic, onchange}: Props) => {

  const selectStyles = SelectStyles();
  const [show, setShow] = useState<boolean>(true);
  const [title, setTitle] = useState<string>("");
  const [path, setPath] = useState<string>("");
  const [type, updateActionType] = useState<IDynamicFields | null>(null)
  const [fill, updateActionFill] = useState<IDynamicFields | null>(null)

  const [updateTopicMutation, {isLoading}] = useUpdateTopicMutation();

  const handleClose = (): void => {
    setShow(false);
    onchange();
  }

  const addAction = async (): Promise<void> => {
    try {
      let alt: string = generateSlug(title), url: string = validateURLPath(path);
      let action: ITopicActions = {title, url, alt, type: type?.value};
      let key: string = (type && type.value === 'YOUTUBE') || (type && type.value === 'MEDIA') ? 'media_action' : 'actions';
      if (fill) action.fill = fill.value;
      let result = await updateTopicMutation({filter: topic.topic, body: {[key]: [action]}}).unwrap()
      toast.success(result.message)
      handleClose()
    } catch (err: any) {
      toast.error(err.data.message)
    }
  }

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton><Modal.Title>Add New Action</Modal.Title></Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12 mb-3">
            <label htmlFor="formrow-email-input" className="form-label">Select Action Type</label>
            <Select options={MediaActionTypes}
                    isClearable={false}
                    getOptionLabel={(each: IDynamicFields): string => `${each.label} (${each.description})`}
                    minMenuHeight={250}
                    styles={selectStyles}
                    menuPlacement="bottom"
                    name="type"
                    placeholder="Select action type..."
                    onChange={(option: SingleValue<IDynamicFields>) => updateActionType(option)}/>
          </div>
          <div className="col-md-12 mb-3">
            <label htmlFor="formrow-email-input" className="form-label">Action Button Title</label>
            <input type="text" className="form-control" placeholder="Enter Action Title"
                   value={title} onChange={(e: ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
            />
          </div>
          <div className="col-md-12 mb-3">
            <label htmlFor="formrow-email-input" className="form-label">Action URL</label>
            <input type="text" className="form-control" placeholder="Enter Action URL"
                   value={path} onChange={(e: ChangeEvent<HTMLInputElement>) => setPath(e.target.value)}
            />
          </div>
          {type && type.value === 'BUTTON' &&
            <div className="col-md-12 mb-3">
              <label htmlFor="formrow-email-input" className="form-label">Select Action Fill Color</label>
              <Select options={MediaActionFill}
                      isClearable={false}
                      minMenuHeight={250}
                      styles={selectStyles}
                      menuPlacement="bottom"
                      name="fill"
                      placeholder="Select action fill..."
                      onChange={(option: SingleValue<IDynamicFields>) => updateActionFill(option)}/>
            </div>
          }
        </div>
        <div className="mt-4 float-end">
          <button onClick={addAction} className="btn btn-md btn-outline-primary ms-1">
            Create Action {isLoading && <i className="bx bx-loader bx-spin"/>}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default NewTopicActionModal;
