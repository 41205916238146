import PageLayout from "../../../components/layouts/PageLayout";
import ComingSoon from "../../../components/modules/ComingSoon";
import {MenuType} from "../../../utils/helpers/constants";


const Home = () => {
  return (
    <PageLayout active={MenuType.HOME} title="Hi, Welcome Back!">
      <ComingSoon pageName={MenuType.HOME}/>
    </PageLayout>
  )
}

export default Home;
