import {useState} from 'react';
import DatePicker from "react-datepicker";

interface Props {
  selected: Date
  dropDownMode: "select" | "scroll" | undefined
  getDate: (index: Date) => void
  className: string
  placeholder: string
  dateFormat?: string
  timeFormat?: string
  timeIntervals?: number
  showTimeSelect?: boolean
  showYearDropdown?: boolean
  setMinDate?: Date
  setMaxDate?: Date
  withPortal?: boolean
  filterTime?: any
}

const DatePickerHook = (props: Props) => {
  const [date, setDate] = useState<Date>(new Date());

  const updateGetDate = (date: Date): void => {
    props.getDate(date);
    setDate(date);
  }

  const filterPassedTime = (time: any): boolean => {
    const currentDate: Date = new Date();
    const selectedDate: Date = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <div className="form-floating react-datepicker">
      <DatePicker selected={props.selected ? props.selected : date}
                  onChange={(date: Date) => updateGetDate(date)}
                  className={props.className}
                  placeholderText={props.placeholder ? props.placeholder : "Select date"}
                  dateFormat={props.dateFormat ? props.dateFormat : "dd/MM/yyyy"}
                  timeFormat={props.timeFormat ? props.timeFormat : "HH:mm"}
                  timeIntervals={props.timeIntervals ? props.timeIntervals : 30}
                  showTimeSelect={props.showTimeSelect}
                  showYearDropdown={props.showYearDropdown}
                  dropdownMode={props.dropDownMode}
                  minDate={props.setMinDate}
                  maxDate={props.setMaxDate}
                  withPortal={props.withPortal}
                  filterTime={filterPassedTime}
      />
    </div>
  );
}

export default DatePickerHook;
