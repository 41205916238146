import moment from "moment";
import {CellContext, ColumnDef, ColumnHelper, createColumnHelper} from "@tanstack/react-table";
import {formatAmountToString, toTitleCase, truncateText} from "../../utils/helpers/logicHelper";

const column: ColumnHelper<ITrip> = createColumnHelper<ITrip>()

export const TripColumns: ColumnDef<ITrip, string>[] = [
  column.accessor('reference', {
    header: () => <span>Ref</span>,
    cell: (info: CellContext<ITrip, string>) => info.getValue(),
    footer: info => info.column.id,
  }),
  column.accessor('user.first_name', {
    header: () => <span>Customer</span>,
    cell: (info: CellContext<ITrip, string>) => (
      <span className="text-truncate">
        {info.row.original.user ? toTitleCase(info.row.original.user.first_name) : '-'} {info.row.original.user ? toTitleCase(info.row.original.user.last_name) : '-'}
      </span>
    ),
    footer: info => info.column.id,
  }),
  column.accessor('source.address', {
    header: () => <span>Pick Up</span>,
    cell: info => <span className="text-truncate">{truncateText(info.getValue(), 30)}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('destination.address', {
    header: () => <span>Drop Off</span>,
    cell: info => <span className="text-truncate">{truncateText(info.getValue(), 30)}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('quote.charge', {
    header: () => <span>Amount</span>,
    cell: (info: CellContext<ITrip, string>): string => `${info.row.original.quote.currency}${formatAmountToString(info.getValue())}`,
    footer: info => info.column.id,
  }),
  column.accessor('driver.user.first_name', {
    header: () => <span>Driver</span>,
    cell: (info: CellContext<ITrip, string>): string => {
      if (!info.row.original.driver || !info.row.original.driver.user) return '-'
      else return `${toTitleCase(info.row.original.driver.user.first_name)} ${toTitleCase(info.row.original.driver.user.last_name)}`
    },
    footer: info => info.column.id,
  }),
  column.accessor('status', {
    header: () => <span>Status</span>,
    cell: (info: CellContext<ITrip, string>) => <span className="text-truncate">{info.getValue()}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('created_at', {
    header: () => <span>Created On</span>,
    cell: info => <span className="text-truncate">{moment(info.getValue()).format('lll')}</span>,
    footer: info => info.column.id,
  }),
];


