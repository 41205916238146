export const MockOverview: IOverview = {
  organisations: {},
  finance: {balance: "0.00", currency: 'NGN'},
  drivers: {},
  fleet: {},
  business: {},
  trips: {}
}

export const TimeType: IInsightsPreset = {
  PRESET: "PRESET",
  CUSTOM: "CUSTOM",
};

export const TimeRange: IInsightsRange[] = [
  {label: 'Current Month', value: 'current_month', type: TimeType.PRESET},
  {label: 'Last Month', value: 'last_month', type: TimeType.PRESET},
  {label: 'Current Year', value: 'current_year', type: TimeType.PRESET},
  {label: 'Last Year', value: 'last_year', type: TimeType.PRESET},
  {label: 'Month', value: 'month', type: TimeType.CUSTOM},
  {label: 'Quarter', value: 'quarter', type: TimeType.CUSTOM},
  {label: 'Half Year', value: 'half_year', type: TimeType.CUSTOM}
];

export const MonthRange: IInsightsRange[] = [
  {label: 'January', value: 'January', type: TimeType.PRESET},
  {label: 'February', value: 'February', type: TimeType.PRESET},
  {label: 'March', value: 'March', type: TimeType.PRESET},
  {label: 'April', value: 'April', type: TimeType.PRESET},
  {label: 'May', value: 'May', type: TimeType.PRESET},
  {label: 'June', value: 'June', type: TimeType.PRESET},
  {label: 'July', value: 'July', type: TimeType.PRESET},
  {label: 'August', value: 'August', type: TimeType.PRESET},
  {label: 'September', value: 'September', type: TimeType.PRESET},
  {label: 'October', value: 'October', type: TimeType.PRESET},
  {label: 'November', value: 'November', type: TimeType.PRESET},
  {label: 'December', value: 'December', type: TimeType.PRESET},
];

export const QuarterRange: IInsightsRange[] = [
  {label: 'Q1 (Jan - Mar)', value: '1', type: TimeType.PRESET},
  {label: 'Q2 (Apr - June)', value: '2', type: TimeType.PRESET},
  {label: 'Q3 (July - Sept)', value: '3', type: TimeType.PRESET},
  {label: 'Q4 (Oct - Dec)', value: '4', type: TimeType.PRESET}
];

export const YearRange: IInsightsRange[] = [
  {label: 'H1 (Jan - June)', value: '1', type: TimeType.PRESET},
  {label: 'H2 (July - Dec)', value: '2', type: TimeType.PRESET}
];
