import {useState} from "react";
import moment from "moment";
import Offcanvas from 'react-bootstrap/Offcanvas';

interface Props {
  updates: ILeadUpdates[]
  onchange: () => void
}

const LeadStatusCanvas = ({updates, onchange}: Props) => {

  const [show, setShow] = useState<boolean>(true);
  const handleClose = () => {
    setShow(false);
    onchange();
  }

  return (
    <Offcanvas show={show} onHide={handleClose} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Lead Acquisition Updates</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {updates.map((item: ILeadUpdates, index: number) =>
          <blockquote key={index} className="blockquote font-size-14 mb-0">
            <p>{item.message}</p>
            <footer className="blockquote-footer mt-0 font-size-12">
              {item.status} | {moment(item.time).format('LLL')}
            </footer>
          </blockquote>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default LeadStatusCanvas;
