import {ChangeEvent, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import toast from "react-hot-toast";

import {useUpdateDriverLeadMutation} from "../../store/slices/backoffice.slice";

interface Props {
  driverLead: IDriverLead
  onchange: () => void
}

const PostMessageModal = ({driverLead, onchange}: Props) => {

  const [show, setShow] = useState<boolean>(true);
  const [message, setMessage] = useState<string>('');
  const [updateLeadMutation, {isLoading}] = useUpdateDriverLeadMutation();

  const handleClose = () => {
    setShow(false);
    onchange();
  }

  const sendMessage = async (): Promise<void> => {
    try {
      let filter: string = `add-message/${driverLead.id}`, body: { message: string } = {message}
      await updateLeadMutation({filter, body}).unwrap();
      handleClose();
    } catch (err: any) {
      toast.error(err.data.message)
    }
  }

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton><Modal.Title>Post Message</Modal.Title></Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <div className="mb-3">
              <textarea className="form-control" placeholder="Compose Message.." value={message}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setMessage(e.target.value)}></textarea>
            </div>
          </div>
        </div>
        <div className="mt-2 float-end">
          <button className="btn btn-primary w-md" onClick={sendMessage}>
            Send Message {isLoading && <i className="bx bx-loader bx-spin"/>}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default PostMessageModal;
