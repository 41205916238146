import {useState} from "react";
import {useDispatch} from "react-redux";
import {Dispatch} from "@reduxjs/toolkit";
import Select, {SingleValue} from "react-select";
import toast from "react-hot-toast";
import Modal from 'react-bootstrap/Modal';
import axios from "axios";

import TokenHelper from "../../utils/helpers/tokenHelper";
import {getBaseUrl} from "../../utils/services/axios.service";
import {dispatchCountry} from "../../store/slices/country.slice";
import {SelectStyles} from "../../utils/helpers/selectStyles";

interface Props {
  country: ICountry
  services: IService[]
  onchange: () => void
}

const AddServiceModal = ({country, services, onchange}: Props) => {

  const dispatch: Dispatch = useDispatch(), selectStyles: IDynamicFields = SelectStyles();
  const [show, setShow] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [service, setService] = useState<IService | null>(null);

  const handleClose = (): void => {
    setShow(false);
    onchange();
  }

  const addService = async (): Promise<void> => {
    try {
      setLoading(true);
      let token = TokenHelper.getToken(), headers: IDynamicFields = {Authorization: `Bearer ${token}`}
      let url: string = `${getBaseUrl().dodge}locale-service/configs/services/country/configure/`
      let payload: IDynamicFields = {price: "0", country: country.country_code, reference: service?.reference}
      payload.interval = "monthly";
      await axios.post(url, payload, {headers})
      await dispatchCountry(dispatch, country.country_code)
      setLoading(false);
      handleClose();
    } catch (error: any) {
      toast.error(error.message);
    }
  }

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton><Modal.Title>Add a service to {country.name}</Modal.Title></Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <label htmlFor="formrow-email-input" className="form-label">Configurable Services</label>
            <Select
              aria-label="messaging-size"
              options={services}
              value={service}
              getOptionLabel={(each: IService): string => `${each.name} (${each.description ?? ''})`}
              isClearable={false}
              isSearchable={true}
              maxMenuHeight={250}
              styles={selectStyles}
              menuPlacement="bottom"
              onChange={(option: SingleValue<IService>) => setService(option)}
            />
          </div>
        </div>
        <div className="mt-4 float-end">
          <button onClick={addService} className="btn btn-md btn-outline-primary ms-1">
            Add Service {loading && <i className="bx bx-loader bx-spin"/>}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AddServiceModal;
