import {ReactElement} from "react";
import moment from "moment";
import {verifyTimestamp} from "../../utils/helpers/logicHelper";

interface Props {
  vehicles: IVehiclePartner[]
}

const OwnedVehiclesCard = ({vehicles}: Props) => {

  let renderDocumentInfo = (vehicle: IVehiclePartner, type: string, expiry: string): ReactElement => {
    let stamp: Date = verifyTimestamp(vehicle.extras[expiry] ?? '')
    let showStamp: string | null = vehicle.extras[expiry] ? moment(stamp).format('LL') : null
    return vehicle.extras[type] ?
      <><i className="bx bx-check-circle text-success"/> {showStamp}</> :
      <>- {showStamp}</>
  }

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="font-size-16 mb-4">Onboarded Vehicles</h5>
        <div className="table-responsive">
          <table className="table table-nowrap table-hover mb-1">
            <thead className="bg-light">
            <tr>
              <th scope="col">Registration Number</th>
              <th scope="col">Category</th>
              <th scope="col">Tonnage</th>
              <th scope="col">Insurance Document</th>
              <th scope="col">Hackney</th>
              <th scope="col">Worthiness</th>
              <th scope="col">Onboarding Date</th>
            </tr>
            </thead>
            <tbody>
            {vehicles.map((item: IVehiclePartner, index: number) =>
              <tr key={index}>
                <th scope="row">{item.extras.registration_number}</th>
                <th scope="row">{item.extras.category}</th>
                <th scope="row">{item.extras.tonnage} tonnes</th>
                <th scope="row">
                  {renderDocumentInfo(item, 'insurance_document', 'insurance_document_expiry_date')}
                </th>
                <th scope="row">
                  {renderDocumentInfo(item, 'hackney_permit', 'hackney_permit_document_expiry_date')}
                </th>
                <th scope="row">
                  {renderDocumentInfo(item, 'road_worthiness', 'road_worthiness_document_expiry_date')}
                </th>
                <th scope="row">{item.created_at ? moment(item.created_at).format('LL') : '-'}</th>
              </tr>
            )}
            </tbody>
          </table>
        </div>

      </div>
    </div>
  )
}

export default OwnedVehiclesCard;
