import {NavigateFunction, useNavigate} from "react-router-dom";

import PageLayout from "../../../components/layouts/PageLayout";
import {MenuType} from "../../../utils/helpers/constants";
import {useGetServicesQuery} from "../../../store/slices/dodge.slice";
import {orgFlowMap} from "../../../utils/helpers/mappers";


const Services = () => {
  const navigate: NavigateFunction = useNavigate();
  const {data: services, isLoading, isSuccess} = useGetServicesQuery('')

  return (
    <PageLayout active={MenuType.SAAS} title="Siju by truQ - Services" loading={isLoading}>
      {isSuccess &&
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Configurable Services</h4>
                  <p className="card-title-desc">List of available configurable services on Siju</p>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped mb-0">
                      <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Name</th>
                        <th>Reference</th>
                        <th>Flow</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                      </thead>
                      <tbody>
                      {services.data.map((item: IService, index: number) =>
                        <tr key={index}>
                          <th scope="row">{index + 1}.</th>
                          <td>{item.name}</td>
                          <td>{item.reference}</td>
                          <td>{orgFlowMap[item.org_flow]['label']}</td>
                          <td>{item.status ? 'Active' : 'Inactive'}</td>
                          <td>
                            <span className="text-center pointer">
                              <button className="btn btn-sm btn-primary"
                                      onClick={() => navigate(`/saas/service/${item.reference}`, {state: item.reference})}>
                                View Service
                              </button>
                            </span>
                          </td>
                        </tr>
                      )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </PageLayout>
  )
}

export default Services;
