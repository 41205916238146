import {ChangeEvent, Fragment, useEffect, useState} from "react";
import {DebounceHook} from "../hooks/DebounceHook";

interface Props {
  searchType: 'DOCUMENT' | 'VOUCHER' | 'ANY'
  navSearch?: ((params: string) => void) | undefined
}

const TopBarSearch = ({searchType, navSearch}: Props) => {

  let defaultText: string = 'Search for anything...'
  const [show, setShow] = useState<boolean>(false)
  const [search, setSearch] = useState<string>("")
  const [debounceField, setDebounceField] = useState("");
  const debounceText = DebounceHook({value: debounceField, delay: 350});
  const showSearchBox = () => setShow(!show)

  useEffect(() => {
    if (debounceText && navSearch) navSearch(debounceText)
  }, [debounceText]);

  const onKeySearch = (value: string): void => {
    setSearch(value)
    setDebounceField(value);
  }

  const getDefaultText = (): string => {
    if (searchType === 'DOCUMENT') defaultText = 'Search for an expiring document...'
    else if (searchType === 'VOUCHER') defaultText = 'Search for a voucher...'
    return defaultText
  }

  return (
    <Fragment>
      <div className="dropdown d-inline-block">
        <div className={`dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 ${show ? 'show' : ''}`}>
          <form className="p-2">
            <div className="search-box">
              <div className="position-relative">
                <input type="text" value={search} className="form-control rounded bg-light border-0"
                       onChange={(event: ChangeEvent<HTMLInputElement>) => onKeySearch(event.target.value)}
                       placeholder={getDefaultText()}/>
                <i className="bx bx-search search-icon"></i>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="dropdown d-inline-block">
        <button type="button" onClick={showSearchBox} className="btn header-item noti-icon" data-bs-toggle="dropdown"
                aria-haspopup="true">
          <i className="bx bx-search icon-sm align-middle"></i>
        </button>
      </div>
    </Fragment>
  )
}


export default TopBarSearch;
