import {NavigateFunction, useNavigate} from "react-router-dom";
import moment from "moment";
import {toTitleCase, verifyTimestamp} from "../../../../utils/helpers/logicHelper";

interface Props {
  gridIndex: number
  document: IDriverPartner
  loading: boolean
  actionIndex: number
  type: "expiredDriversDocs" | "expiringDriversDocs" | "expiredVehicleDocs" | "expiringVehicleDocs"
  sendNotification: (document: IDriverPartner, docType: string, badgeText: string, key: number) => void
}

const DocumentExpiryGrid = ({gridIndex, document, type, loading, actionIndex, sendNotification}: Props) => {
  const navigate: NavigateFunction = useNavigate(), date: Date = new Date();
  const badgeText: string = type === 'expiredDriversDocs' || type === 'expiredVehicleDocs' ? 'EXPIRED' : 'EXPIRING';
  const badgeBg: string = type === 'expiredDriversDocs' || type === 'expiredVehicleDocs' ? 'bg-danger-subtle' : 'bg-warning-subtle';
  const badgeColor: string = type === 'expiredDriversDocs' || type === 'expiredVehicleDocs' ? 'text-danger' : 'text-warning';
  const docType: string = type === 'expiredDriversDocs' || type === 'expiringDriversDocs' ? 'license_number' : 'registration_number';

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex align-items-center">
          <div className="flex-1">
            <h5 className="font-size-16 mb-1 pointer-event"
                onClick={() => navigate(`/supply/driver/${document.email}`, {state: document.id})}>
              {toTitleCase(document.first_name)} {toTitleCase(document.last_name)}
            </h5>
            <span className={`badge ${badgeBg} ${badgeColor} mb-0`}>{badgeText} - {document.extras[docType]}</span>
          </div>
        </div>
        <div className="mt-3 pt-1">
          {docType === 'license_number' &&
            <p className="mb-0 mt-2">
              <i className="bx bx-file font-size-15 align-middle pe-2 text-primary"></i> LICENSE NUMBER
            </p>
          }
          {docType === 'registration_number' && verifyTimestamp(document.extras.insurance_document_expiry_date ?? '') < date &&
            <p className="mb-0 mt-2">
              <i className="bx bx-file font-size-15 align-middle pe-2 text-primary"></i>INSURANCE DOCUMENT
            </p>
          }
          {docType === 'registration_number' && verifyTimestamp(document.extras.hackney_permit_document_expiry_date ?? '') < date &&
            <p className="mb-0 mt-2">
              <i className="bx bx-file font-size-15 align-middle pe-2 text-primary"></i>HACKNEY PERMIT DOCUMENT
            </p>
          }
          {docType === 'registration_number' && verifyTimestamp(document.extras.road_worthiness_document_expiry_date ?? '') < date &&
            <p className="mb-0 mt-2">
              <i className="bx bx-file font-size-15 align-middle pe-2 text-primary"></i>ROAD WORTHINESS DOCUMENT
            </p>
          }

          <p className="mb-0 mt-2">
            <i className="bx bx-calendar font-size-15 align-middle pe-2 text-primary"></i>
            {moment(document.extras.license_expires_on).format('LL')}
          </p>
          <p className="mb-0 mt-2">
            <i className="bx bx-phone font-size-15 align-middle pe-2 text-primary"></i>
            {document.phone}
          </p>
          <p className="mb-0 mt-2">
            <i className="bx bx-mail-send font-size-15 align-middle pe-2 text-primary"></i>
            {document.email}
          </p>
        </div>

        <div className="d-flex gap-2 pt-4">
          <button disabled type="button" className="btn btn-subtle-primary btn-sm w-50">
            Renew Document
          </button>
          <button type="button" onClick={() => sendNotification(document, docType, badgeText, gridIndex)}
                  className="btn btn-primary btn-sm w-50">
            Notify Driver {loading && gridIndex === actionIndex && <i className="bx bx-loader bx-spin"/>}
          </button>
        </div>
      </div>
    </div>
  )
}

export default DocumentExpiryGrid;
