import {XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line} from 'recharts';
import {AxisDomain} from "recharts/types/util/types";

import {toTitleCase} from "../../utils/helpers/logicHelper";
import CustomTooltip from "./CustomTooltip";

interface Props {
  name: string
  data: any
  yDomain: AxisDomain
  yKey: IYKey[]
}

const LineCharts = ({name, data, yDomain, yKey}: Props) => {

  const XAxisFormatter = (value: string): string => toTitleCase(value);

  const YAxisFormatter = (value: number): string => {
    return new Intl.NumberFormat("en-US", {notation: "compact", compactDisplay: "short"}).format(value)
  };

  return (
    <div className="card card-body card-h-100 pb-0">
      <h5 className="card-title mb-4">{name}</h5>
      <div style={{width: '100%', height: '100%'}}>
        <ResponsiveContainer>
          <LineChart width={500} height={300} data={data} margin={{top: 10, right: 5, left: 5, bottom: 10}}>
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="name" tickFormatter={(value) => XAxisFormatter(value)}/>
            <YAxis type="number" domain={yDomain} allowDataOverflow={false}
                   tickFormatter={(value) => YAxisFormatter(value)}/>
            <Tooltip content={<CustomTooltip/>}/>
            <Legend/>
            {yKey.map((eachKey: IYKey, index: number) =>
              <Line key={index} name={eachKey.name} dataKey={eachKey.key} stroke={eachKey.stroke}/>
            )}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default LineCharts;
