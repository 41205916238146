interface Props {
  size: number
  pageIndex: number
  count: number
  pageSize: number
  setPageIndex: (index: number) => void
}

const Pagination = ({pageIndex, size, count, pageSize, setPageIndex}: Props) => {

  const prePageCount: number = pageIndex === 1 ? pageIndex : size * (pageIndex - 1) + 1;
  const postPageCount: number | null = pageSize === 1 ? null : prePageCount + (pageSize - 1);
  const canPrevious: boolean = pageIndex > 1, canNext: boolean = Math.ceil(count / size) !== pageIndex

  return (
    <div className="row">
      <div className="col-7">
        Showing {prePageCount} - {postPageCount} of {count}
      </div>
      <div className="col-5">
        <div className="btn-group float-end">
          <button type="button" disabled={!canPrevious} onClick={() => setPageIndex(pageIndex - 1)}
                  className="btn btn-sm btn-success"><i className="bx bx-left-arrow"></i>
          </button>
          <button type="button" disabled={!canNext} onClick={() => setPageIndex(pageIndex + 1)}
                  className="btn btn-sm btn-success"><i className="bx bx-right-arrow"></i>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Pagination;
