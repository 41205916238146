import {ChangeEvent, useState} from "react";
import toast from "react-hot-toast";
import Modal from 'react-bootstrap/Modal';

import {useCreateTopicMutation} from "../../store/slices/backoffice.slice";

interface Props {
  onset: (newTopic: ITopics) => void
  onchange: () => void
}

const NewTopicModal = ({onset, onchange}: Props) => {

  const [show, setShow] = useState<boolean>(true);
  const [name, setName] = useState<string>("");
  const [intro, setIntro] = useState<string>("");
  const [createTopic, {isLoading}] = useCreateTopicMutation()

  const handleClose = (): void => {
    setShow(false);
    onchange();
  }

  const addContent = async (): Promise<void> => {
    try {
      let results = await createTopic({name, intro}).unwrap()
      onset(results.data)
      handleClose()
    } catch (err: any) {
      toast.error(err.data.message)
    }
  }

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton><Modal.Title>Add New Topic</Modal.Title></Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12 mb-3">
            <label htmlFor="formrow-email-input" className="form-label">Topic Name</label>
            <input type="text" className="form-control" placeholder="Enter Content Name"
                   value={name} onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
            />
          </div>
          <div className="col-md-12 mb-3">
            <label htmlFor="formrow-email-input" className="form-label">Topic Intro</label>
            <textarea rows={4} className="form-control" placeholder="Enter Topic Intro"
                      value={intro} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setIntro(e.target.value)}
            />
          </div>
        </div>
        <div className="mt-4 float-end">
          <button onClick={addContent} className="btn btn-md btn-outline-primary ms-1">
            Create Topic {isLoading && <i className="bx bx-loader bx-spin"/>}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default NewTopicModal;
