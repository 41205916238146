const OpacityLoader = () => (
    <div className="overlay">
    <div className="position-absolute h-100 d-flex align-items-center justify-content-center">
      <div className="wave"></div>
      <div className="wave"></div>
      <div className="wave"></div>
      <div className="wave"></div>
      <div className="wave"></div>
      <div className="wave"></div>
      <div className="wave"></div>
      <div className="wave"></div>
      <div className="wave"></div>
      <div className="wave"></div>
    </div>
    </div>
);

export default OpacityLoader;