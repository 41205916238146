import {NavigateFunction, useNavigate} from "react-router-dom";
import moment from "moment";

import Gravatar from "../../assets/images/pngs/gravatar.png";
import ToolTipComponent from "../modules/ToolTipComponent";
import {formatAmountToString, toTitleCase} from "../../utils/helpers/logicHelper";


interface Props {
  profile: IDriverPartner
  points: IDriverPoints
  creditProperties: ICreditProperties
}

const ProfileCard = ({profile, points, creditProperties}: Props) => {
  const navigate: NavigateFunction = useNavigate();
  const date: Date = new Date(), year: number = date.getFullYear(), month: string = moment().format('MMMM')

  const pushNotification = (): void => {
    navigate(`/messaging/create`, {state: {type: 'APP', size: 'SINGLE', email: profile.email}})
  }

  return (
    <div className="card">
      <div className="card-body p-0">
        <div className="user-profile-img">
          <img src={Gravatar} className="profile-img rounded-top" style={{height: '120px'}} alt=""/>
          <div className="overlay-content rounded-top"/>
        </div>
        <div className="p-4 pt-0">
          <div className="mt-n5 position-relative text-center border-bottom pb-3">
            <img src={Gravatar} alt="" className="avatar-xl rounded-circle img-thumbnail"/>
            <div className="mt-3">
              <h5 className="mb-1">{toTitleCase(profile.first_name)} {toTitleCase(profile.last_name)}</h5>
              <ToolTipComponent text={`Credit Ratings: ${profile.first_name} has acquired ${points.credit_score} credit 
              worthiness points to facilitate credit based performance`} placement="bottom">
                <span className="badge rounded-pill bg-primary mx-1 pointer-event">
                  {points.credit_score} <i className="bx bxs-credit-card text-white"/>
                </span>
              </ToolTipComponent>
              <ToolTipComponent
                text={`Early Earning Ratings: ${profile.first_name} has acquired ${creditProperties.earning_score} 
              worthiness points for this month ${month} to facilitate early earning access rewards`} placement="bottom">
                <span className="badge rounded-pill bg-dark mx-1 pointer-event">
                  {creditProperties.earning_score} <i className="bx bx-time text-white"/>
                </span>
              </ToolTipComponent>
              <span className="badge rounded-pill bg-warning">
                {points.ratings} <i className="bx bxs-star text-white"/>
              </span>
            </div>
          </div>
          <div className="table-responsive mt-3 border-bottom pb-3">
            <table className="table align-middle table-sm table-nowrap table-borderless table-centered mb-0">
              <tbody>
              <tr>
                <th className="fw-bold">Phone</th>
                <td className="text-muted">{profile.phone}</td>
              </tr>
              <tr>
                <th className="fw-bold">Assignment</th>
                <td className="text-muted">{profile.type} Partner</td>
              </tr>
              <tr>
                <th className="fw-bold">Assigned Vehicle</th>
                <td className="text-muted">
                  {profile.assigned_vehicle ? <i className="bx bx-check-circle text-success"/> :
                    <i className="bx bx-block text-danger"/>
                  }
                </td>
              </tr>
              <tr>
                <th className="fw-bold">Drivers License</th>
                <td className="text-muted">
                  {profile.extras.license_scan ? <i className="bx bx-check-circle text-success"/> :
                    <i className="bx bx-block text-danger"/>
                  }
                </td>
              </tr>
              <tr>
                <th className="fw-bold">Eligible Earnings</th>
                <td className="text-muted">NGN{formatAmountToString(creditProperties.eligible_earning)}</td>
              </tr>
              <tr>
                <th className="fw-bold">Secured Earnings</th>
                <td className="text-muted">NGN{formatAmountToString(creditProperties.secured_earning)}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className="p-3 mt-3">
            <div className="row text-center">
              <div className="col-4 border-end">
                <div className="p-1">
                  <h5 className="mb-1">{points.total_points}</h5>
                  <p className="text-muted mb-0">Reward Points</p>
                </div>
              </div>
              <div className="col-4 border-end">
                <div className="p-1">
                  <h5 className="mb-1">{points[year][month]}</h5>
                  <p className="text-muted mb-0">{month} {year}</p>
                </div>
              </div>
              <div className="col-4">
                <div className="p-1">
                  <h5 className="mb-1">{points[year]['total']}</h5>
                  <p className="text-muted mb-0">Points in {year}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-2 text-center border-bottom pb-4">
            <button onClick={pushNotification}
                    className="btn btn-outline-primary btn-sm ms-1">
              Push Notification<i className="bx bx-notification ms-1 align-middle"></i>
            </button>
            <button disabled className="btn btn-outline-info btn-sm ms-1">
              Send SMS<i className="bx bx-message ms-1 align-middle"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileCard;
