import {AdvancedMarker, APIProvider, Map} from '@vis.gl/react-google-maps';
import {AppKeys} from "../../utils/helpers/constants";

interface Props {
  source: any
  destination: any
}

const MapHook = ({source, destination}: Props) => {

  const position: { lat: number, lng: number } = {lat: source.latitude, lng: source.longitude};

  return (
    <APIProvider apiKey={AppKeys.MAPS_KEY}>
      <Map defaultCenter={position} defaultZoom={14} mapId="MAP_ID" gestureHandling="greedy" >
        <AdvancedMarker position={position}/>
        <AdvancedMarker position={position}/>
      </Map>
    </APIProvider>
  );
}

export default MapHook;
