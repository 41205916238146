import {MouseEvent, useState} from "react";
import {useSelector} from "react-redux";
import {NavigateFunction, NavLink, Params, useNavigate, useParams} from "react-router-dom";

import OrgWallet from "./misc/OrgWallet";
import PageLayout from "../../../components/layouts/PageLayout";
import OrgProfileCard from "../../../components/cards/OrgProfileCard";
import WalletEntries from "./misc/WalletEntries";
import WalletTransactionCanvas from "../../../components/offcanvas/WalletTransactionCanvas";
import {MenuType} from "../../../utils/helpers/constants";
import {MockOrganisation, MockOrganisationWallet} from "../../../mocks/MockOrganisation";
import {
  useGetOrganisationMembersQuery, useGetOrganisationQuery,
  useGetOrganisationWalletEntriesQuery, useGetOrganisationWalletQuery
} from "../../../store/slices/dodge.slice";


const OrganisationProfile = () => {
  const {reference}: Readonly<Params> = useParams(), navigate: NavigateFunction = useNavigate();
  const navTabs: string[] = ['Organisation Wallet', 'Wallet Entries']
  const userProfile: IProfile = useSelector((state: IStoreState) => state.auth.userProfile)
  const [activeNav, setActiveNav] = useState<string>("Organisation Wallet");
  const [transactionRender, setTransactionRender] = useState<boolean>(false);
  const {data: org, isLoading: loadingOrg} = useGetOrganisationQuery(reference)
  const {data: orgMember, isLoading: loadingMember} = useGetOrganisationMembersQuery(reference)
  const {data: orgWallet, isLoading: loadingWallet} = useGetOrganisationWalletQuery(reference)
  const {data: walletEntries, isLoading: loadingEntries} = useGetOrganisationWalletEntriesQuery(reference)
  if (!reference) navigate('/saas/organisations')

  const switchActiveNav = (event: MouseEvent, selected: string): void => {
    event.preventDefault()
    setActiveNav(selected)
  }

  const transactionCanvas = transactionRender &&
    <WalletTransactionCanvas organisation={reference ?? ""} onchange={() => setTransactionRender(false)}/>

  return (
    <PageLayout backEnabled active={MenuType.SAAS} title={`Siju by truQ - ${reference}`}
                loading={loadingOrg || loadingMember || loadingWallet || loadingEntries}>
      {transactionCanvas}
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-6"/>
          <div className="col-md-6">
            {userProfile.permissions?.edit_admin && userProfile.is_superadmin &&
              <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                <button className="btn btn-primary" onClick={() => setTransactionRender(true)}>
                  <i className="bx bx-credit-card me-1"></i>Wallet Transactions
                </button>
              </div>
            }
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <OrgProfileCard profile={org?.data ?? MockOrganisation}/>
          </div>
          <div className="col-md-8">
            <div className="card">
              <div className="card-body">
                <ul className="nav nav-pills nav-justified">
                  {navTabs.map((item: string, index: number) =>
                    <li key={index} className="nav-item" role="presentation">
                      <NavLink className={`nav-link ${activeNav === item ? 'active' : ''}`} to="/"
                               onClick={(event: MouseEvent<HTMLAnchorElement>) => switchActiveNav(event, item)}>
                        <span>{item}</span>
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </div>

            <div className="tab-content">
              <div className={`tab-pane ${activeNav === 'Organisation Wallet' ? 'active show' : ''}`} role="tabpanel">
                <OrgWallet wallet={orgWallet?.data ?? MockOrganisationWallet} members={orgMember?.data ?? []}/>
              </div>
            </div>
            <div className="tab-content">
              <div className={`tab-pane ${activeNav === 'Wallet Entries' ? 'active show' : ''}`} role="tabpanel">
                <WalletEntries entries={walletEntries?.data ?? []} count={walletEntries?.count ?? 0}
                               loading={loadingEntries}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default OrganisationProfile;
