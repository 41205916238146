import {MouseEvent} from "react";
import {NavLink} from "react-router-dom";

import ColouredVector from "../../assets/images/pngs/coloured-vector.png";
import LogoVector from "../../assets/images/pngs/logo-vector.png";
import {MenuType} from "../../utils/helpers/constants";

interface Props {
  active: string
  profile: IProfile
}

const SideMenu = ({active, profile}: Props) => {

  return (
    <div className="vertical-menu">
      <div className="navbar-brand-box">
        <NavLink to="/" className="logo logo-dark">
          <span className="logo-sm"><img src={ColouredVector} alt="brand" height="26"/></span>
        </NavLink>
        <NavLink to="/" className="logo logo-light">
          <span className="logo-sm"><img src={LogoVector} alt="brand" height="26"/></span>
        </NavLink>
      </div>

      <button type="button" className="btn btn-sm px-3 font-size-24 header-item waves-effect vertical-menu-btn">
        <i className="bx bx-menu align-middle"></i>
      </button>
      {profile?.permissions &&
        <div className="sidebar-menu-scroll">
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title" data-key="t-menu">Dashboard</li>
              <li className={active === MenuType.HOME ? 'mm-active' : ''}>
                <NavLink to="/">
                  <i className="bx bx-home  icon nav-icon"></i>
                  <span className="menu-item" data-key="t-dashboard">Dashboard</span>
                </NavLink>
                <ul className="sub-menu">
                  {/*<li className="mm-active"><NavLink to="/" data-key="t-inbox">Home</NavLink></li>*/}
                  <li><NavLink to="/" data-key="t-inbox">Home</NavLink></li>
                  {profile.permissions.view_insight &&
                    <li><NavLink to="/siju" data-key="t-inbox">Siju Insights</NavLink></li>}
                </ul>
              </li>

              <li className="menu-title" data-key="t-applications">Services</li>
              {profile.permissions.view_trip &&
                <li className={active === MenuType.TRIPS ? 'mm-active' : ''}>
                  <NavLink to="/trips" onClick={(e: MouseEvent<HTMLAnchorElement>) => e.preventDefault()}
                           className="has-arrow mm-collapsed">
                    <i className="bx bx-map-alt icon nav-icon"></i>
                    <span className="menu-item" data-key="t-email">Move by truQ</span>
                  </NavLink>
                  <ul className="sub-menu">
                    <li><NavLink to="/trips/standard" data-key="t-inbox">House Moves</NavLink></li>
                  </ul>
                </li>
              }

              {profile.permissions.view_supply &&
                <li className={active === MenuType.SUPPLY ? 'mm-active' : ''}>
                  <NavLink to="/supply" onClick={(e: MouseEvent<HTMLAnchorElement>) => e.preventDefault()}
                           className="has-arrow mm-collapsed">
                    <i className="bx bxs-truck icon nav-icon"></i>
                    <span className="menu-item" data-key="t-email">Drive Hub</span>
                  </NavLink>
                  <ul className="sub-menu">
                    <li><NavLink to="/supply/leads" data-key="t-inbox">Supply Leads</NavLink></li>
                    <li><NavLink to="/supply/performance" data-key="t-inbox">Performance Reward</NavLink></li>
                    <li><NavLink to="/supply/expiring-documents" data-key="t-inbox">Expiring Documents</NavLink></li>
                  </ul>
                </li>
              }

              {profile.permissions.view_admin &&
                <li className={active === MenuType.SAAS ? 'mm-active' : ''}>
                  <NavLink to="/saas" onClick={(e: MouseEvent<HTMLAnchorElement>) => e.preventDefault()}
                           className="has-arrow mm-collapsed">
                    <i className="bx bxs-business icon nav-icon"></i>
                    <span className="menu-item" data-key="t-email">Siju by truQ</span>
                  </NavLink>
                  <ul className="sub-menu">
                    <li><NavLink to="/saas/organisations" data-key="t-orgs">Organisations</NavLink></li>
                    <li><NavLink to="/saas/services" data-key="t-services">Services</NavLink></li>
                    <li><NavLink to="/saas/permissions" data-key="t-services">Tenant Permissions</NavLink></li>
                    <li><NavLink to="/saas/supported-countries" data-key="t-co">Supported Countries</NavLink></li>
                  </ul>
                </li>
              }

              {profile.permissions.view_blog &&
                <li className={active === MenuType.DOCS ? 'mm-active' : ''}>
                  <NavLink to="/editors" onClick={(e: MouseEvent<HTMLAnchorElement>) => e.preventDefault()}
                           className="has-arrow mm-collapsed">
                    <i className="bx bx-edit icon nav-icon"></i>
                    <span className="menu-item" data-key="t-email">Editors</span>
                  </NavLink>
                  <ul className="sub-menu">
                    <li><NavLink to="/documentation/topics" data-key="t-docs">Documentations</NavLink></li>
                  </ul>
                </li>
              }

              {profile.permissions.edit_permission &&
                <li className={active === MenuType.MESSAGING ? 'mm-active' : ''}>
                  <NavLink to="/messaging">
                    <i className="bx bx-message icon nav-icon"></i>
                    <span className="menu-item" data-key="t-horizontal">Messaging</span>
                  </NavLink>
                </li>
              }

              <li className="menu-title" data-key="t-layouts">Finance</li>
              <li className={active === MenuType.VOUCHER ? 'mm-active' : ''}>
                <NavLink to="/vouchers">
                  <i className="bx bx-receipt icon nav-icon"></i>
                  <span className="menu-item" data-key="t-horizontal">Payment Vouchers</span>
                </NavLink>
              </li>

              {profile.permissions.view_permission && profile.permissions.view_admin &&
                <>
                  <li className="menu-title" data-key="t-layouts">truQStars</li>
                  <li className={active === MenuType.STAFF ? 'mm-active' : ''}>
                    <NavLink to="/staff" onClick={(e: MouseEvent<HTMLAnchorElement>) => e.preventDefault()}
                             className="has-arrow mm-collapsed">
                      <i className="bx bx-user-check icon nav-icon"></i>
                      <span className="menu-item" data-key="t-email">Staff</span>
                    </NavLink>
                    <ul className="sub-menu">
                      <li><NavLink to="/staff" data-key="t-inbox">Permissions</NavLink></li>
                      <li><NavLink to="/signatories" data-key="t-inbox">Signatories</NavLink></li>
                    </ul>
                  </li>
                </>
              }
            </ul>
          </div>
        </div>
      }

    </div>
  )
}

export default SideMenu;
