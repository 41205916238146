import moment from "moment";
import {toTitleCase} from "../../utils/helpers/logicHelper";

interface Props {
  vehicle: IVehiclePartner
}

const AssignedVehicleCard = ({vehicle}: Props) => (
  <div className="card card-body">
    <h5 className="font-size-16">Vehicle - {vehicle.extras['registration_number']} </h5>
    <div className="row">
      <ul className="list-unstyled mb-0 pt-1">
        <li className="py-1">Category: {vehicle.extras.category}</li>
        <li className="py-1">Tonnage: {vehicle.extras.tonnage}</li>
        <li className="py-1">Vehicle Insurance Document {vehicle.extras.insurance_document ?
          <i className="bx bx-check-circle text-success"/> : <i className="bx bx-block text-danger"/>}
        </li>
        <li className="py-1">Hackney Permit Document {vehicle.extras.hackney_permit ?
          <i className="bx bx-check-circle text-success"/> : <i className="bx bx-block text-danger"/>}
        </li>
        <li className="py-1">Road Worthiness Document {vehicle.extras.road_worthiness ?
          <i className="bx bx-check-circle text-success"/> : <i className="bx bx-block text-danger"/>}
        </li>
      </ul>
    </div>

    <h5 className="font-size-16 mt-3">Owner</h5>
    <div className="row">
      <ul className="list-unstyled mb-0 pt-1">
        <li className="py-1">Name: {toTitleCase(vehicle.first_name)} {toTitleCase(vehicle.last_name)}</li>
        <li className="py-1">Email: {vehicle.email}</li>
        <li className="py-1">Phone: {vehicle.phone}</li>
        <li className="py-1">Onboarded On: {moment(vehicle.created_at).format('lll')}</li>
      </ul>
    </div>
  </div>
)

export default AssignedVehicleCard;
