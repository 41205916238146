import {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch} from "@reduxjs/toolkit";

import PageLayout from "../../../components/layouts/PageLayout";
import CountryServices from "./misc/CountryServices";
import CountryConfigs from "./misc/CountryConfigs";
import AddServiceModal from "../../../components/modals/AddServiceModal";
import {dispatchCountry} from "../../../store/slices/country.slice";
import {MenuType} from "../../../utils/helpers/constants";
import {useGetCountriesQuery, useGetServicesQuery} from "../../../store/slices/dodge.slice";

const SupportedCountries = () => {

  const dispatch: Dispatch = useDispatch();
  const userProfile: IProfile = useSelector((state: IStoreState) => state.auth.userProfile)
  const country: ICountry = useSelector((state: IStoreState) => state.country.country)
  const services: ICountryService[] = useSelector((state: IStoreState) => state.country.services)

  const [addRender, setAddRender] = useState<boolean>(false)
  const {data: countries, isLoading, isSuccess} = useGetCountriesQuery('')
  const {data: servs, isLoading: loadingServices, isSuccess: loadedServices} = useGetServicesQuery('')

  useEffect((): void => {
    if (isSuccess && countries?.data.length > 0) getCountryInformation().catch(err => console.log(err))
  }, [isSuccess]);

  const getCountryInformation = async (): Promise<void> => await dispatchCountry(dispatch, countries?.data[0]['country_code'])
  const getCountry = async (newCountry: ICountry): Promise<void> => {
    if (country.country_code !== newCountry.country_code) await dispatchCountry(dispatch, newCountry.country_code)
  }

  const addServiceModal = addRender &&
    <AddServiceModal country={country} services={loadedServices ? servs?.data : []}
                     onchange={() => setAddRender(false)}/>


  return (
    <PageLayout active={MenuType.SAAS} title={`Siju by truQ - Supported Countries - ${country.name}`}
                loading={isLoading || loadingServices}>
      {isSuccess && loadedServices && country.id !== "0" &&
        <div className="container-fluid">
          {addServiceModal}
          <div className="row align-items-center">
            <div className="col-md-6"/>
            <div className="col-md-6">
              <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                {userProfile.is_superadmin &&
                  <Fragment>
                    <button className="btn btn-primary pointer-event" onClick={() => setAddRender(true)}>
                      <i className="bx bxs-cog me-1"></i> Add Service
                    </button>
                    <button className="btn btn-danger pointer-event">
                      <i className="bx bx-trash me-1"></i> Delete Country
                    </button>
                  </Fragment>
                }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="card card-body card-h-100">
                <button className="btn btn-primary w-100 mb-4">
                  <i className="bx bx-plus"/> Add Country
                </button>
                <div className="text-muted">Select a country to see country configurations</div>
                <div>
                  {countries.data.map((item: ICountry, index: number) =>
                    <div key={index} className="event-card bg-success pointer-event" onClick={() => getCountry(item)}>
                      <i className="bx bxs-circle font-size-11 me-2"></i>{item.name}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="card card-body card-h-100">
                <div className="row">
                  <CountryConfigs country={country}/>
                  <CountryServices country={country} services={services}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </PageLayout>
  )
}

export default SupportedCountries;
