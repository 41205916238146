import {ColumnDef, ColumnHelper, createColumnHelper} from "@tanstack/react-table";
import moment from "moment";
import {toTitleCase} from "../../utils/helpers/logicHelper";


const column: ColumnHelper<IDriverLead> = createColumnHelper<IDriverLead>()

export const DriverLeadsColumn: ColumnDef<IDriverLead, string>[] = [
  column.accessor('first_name', {
    header: () => <span>Name</span>,
    cell: info => <span
      className="text-truncate">{toTitleCase(info.row.original.first_name)} {toTitleCase(info.row.original.last_name)}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('email', {
    header: () => <span>Email Address</span>,
    cell: info => <span className="text-truncate">{info.getValue()}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('extras.phone', {
    header: () => <span>Phone</span>,
    cell: info => <span className="text-truncate">
      {info.row.original.phone ? `${info.row.original.phone}` : '-'}
    </span>,
    footer: info => info.column.id,
  }),
  column.accessor('extras.state', {
    header: () => <span>State</span>,
    cell: info => <span className="text-truncate">
      {info.row.original.extras.state ? `${info.row.original.extras.state}` : '-'}
    </span>,
    footer: info => info.column.id,
  }),

  column.accessor('extras.category_size', {
    header: () => <span>Category</span>,
    cell: info => <span className="text-truncate">
      {info.row.original.extras.category_size ? `${info.row.original.extras.category_size} tonnes` : '-'}
    </span>,
    footer: info => info.column.id,
  }),
  column.accessor('created_at', {
    header: () => <span>Created On</span>,
    cell: info => <span className="text-truncate">{moment(info.getValue()).format('LL')}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('status', {
    header: () => <span>Status</span>,
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
];
