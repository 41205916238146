import {useEffect, useState} from "react";
import Select, {SingleValue} from "react-select";
import {Location, useLocation} from "react-router-dom";

import PageLayout from "../../../components/layouts/PageLayout";
import SendInApp from "./atom/SendInApp";
import {SelectStyles} from "../../../utils/helpers/selectStyles";
import {MenuType} from "../../../utils/helpers/constants";
import {MessagingSize, MessagingTypes} from "../../../mocks/Messaging";


const CreateMessaging = () => {
  const location: Location<any> = useLocation(), selectStyles: IDynamicFields = SelectStyles();
  const [type, setMessagingType] = useState<IMessagingType | null>(null)
  const [size, setMessagingSize] = useState<IMessagingSize | null>(null)

  useEffect(() => {
    if (location.state && (location.state.type === "APP" || location.state.type === "SMS")) {
      let appType: IMessagingType | undefined = MessagingTypes.find((each: IMessagingType): boolean => each.value === location.state.type);
      let appSize: IMessagingSize | undefined = MessagingSize.find((each: IMessagingSize): boolean => each.value === location.state.size);
      setMessagingType(appType ?? null)
      setMessagingSize(appSize ?? null)
    }
  }, []);

  return (
    <PageLayout active={MenuType.MESSAGING} backEnabled title="Compose Message">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Compose Message</h4>
                <p className="card-title-desc">Compose single or bulk emails, SMS or inApp push notifications</p>
              </div>
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="formrow-email-input" className="form-label">Select Messaging Type</label>
                        <Select
                          aria-label="messaging-type"
                          options={MessagingTypes}
                          value={type}
                          getOptionLabel={(each: IMessagingType): string => `${each['name']}`}
                          isClearable={false}
                          isSearchable={true}
                          styles={selectStyles}
                          maxMenuHeight={250}
                          menuPlacement="bottom"
                          onChange={(option: SingleValue<IMessagingType>) => setMessagingType(option)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="formrow-email-input" className="form-label">Messaging Size</label>
                        <Select
                          aria-label="messaging-size"
                          options={MessagingSize}
                          value={size}
                          getOptionLabel={(each: IMessagingSize): string => `${each.name} (${each.description})`}
                          isClearable={false}
                          isSearchable={true}
                          maxMenuHeight={250}
                          styles={selectStyles}
                          menuPlacement="bottom"
                          onChange={(option: SingleValue<IMessagingSize>) => setMessagingSize(option)}
                        />
                      </div>
                    </div>
                  </div>
                  {type?.value === 'APP' && <SendInApp size={size?.value} initProps={location.state}/>}
                </form>
              </div>

            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default CreateMessaging;
