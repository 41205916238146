import {Fragment} from "react";
import NumberCard from "../../../../components/cards/NumberCard";
import {formatAmountToString} from "../../../../utils/helpers/logicHelper";

interface Props {
  wallet: IOrganisationWallet
  members: IOrganisationMember[]
}

const OrgWallet = ({wallet, members}: Props) => (
  <Fragment>
    <div className="row">
      <div className="col-md-4">
        <NumberCard text="Ledger Balance" value={`${wallet.currency}${formatAmountToString(wallet.ledger_balance)}`}/>
      </div>
      <div className="col-md-4">
        <NumberCard text="Available Balance"
                    value={`${wallet.currency}${formatAmountToString(wallet.available_balance)}`}/>
      </div>
      <div className="col-md-4">
        <NumberCard text="Credit Balance" value={`${wallet.currency}${formatAmountToString(wallet.credit_balance)}`}/>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <div className="card card-body">
          <h5 className="font-size-16 mb-4">Wallet Information</h5>
          <ul className="list-unstyled mb-0 pt-1">
            <li className="py-1">
              Withdraw Status: {wallet.withdrawal_configs.can_withdraw ?
              <i className="bx bx-check-circle text-success"/> : <i className="bx bx-block text-danger"/>}
            </li>
            <li className="py-1">
              Advanced Payment Status: {wallet.advanced_payment_configs.advanced_payment_status ?
              <i className="bx bx-check-circle text-success"/> : <i className="bx bx-block text-danger"/>}
            </li>
            <li className="py-1">
              Advanced Payment Interest: {wallet.advanced_payment_configs.interest_rate ?? 0}%
            </li>
            <li className="py-1">
              Advanced Payment Outstanding Cap:&nbsp;
              {wallet.currency}{formatAmountToString(wallet.advanced_payment_configs.outstanding_cap) ?? 0}
            </li>
          </ul>
          <h5 className="card-title mt-4">Team Members</h5>
          <div className="table-responsive">
            <table className="table align-middle table-nowrap mb-0">
              <thead className="bg-light">
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th scope="col">Role</th>
              </tr>
              </thead>
              <tbody>
              {members.map((member: IOrganisationMember, index: number) => (
                <tr key={index}>
                  <td><h5 className="font-size-14 m-0">{member.first_name} {member.last_name}</h5></td>
                  <td> {member.email}</td>
                  <td> {member.phone}</td>
                  <td>
                    <div className="badge bg-primary-subtle text-primary font-size-11">{member.role.name}</div>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
)

export default OrgWallet;
