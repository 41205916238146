import {ChangeEvent, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import toast from "react-hot-toast";
import Select, {SingleValue} from "react-select";

import PartnerService from "../../utils/services/partner.service";
import {AssignSearchParams} from "../../mocks/MockTrip";
import {Loading} from "../../utils/helpers/constants";
import {MockPartner} from "../../mocks/MockProfile";
import {useUpdateTripMutation} from "../../store/slices/backoffice.slice";
import {SelectStyles} from "../../utils/helpers/selectStyles";

interface Props {
  trip: ITrip
  onchange: () => void
}

const AssignVehicleModal = ({trip, onchange}: Props) => {

  const selectStyles: IDynamicFields = SelectStyles();
  const [show, setShow] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams, setSearchParam] = useState<ITripSearchParams | null>()
  const [value, setValue] = useState<string>('')
  const [entity, setEntity] = useState<IPartnerProfile>(MockPartner)
  const [assignDriver] = useUpdateTripMutation();

  const handleClose = () => {
    setShow(false);
    onchange();
  }

  const searchAgain = (): void => setEntity(MockPartner)
  const updateSearchParam = (option: SingleValue<ITripSearchParams>): void => setSearchParam(option)

  const findAssignment = async (): Promise<void> => {
    setLoading(true)
    let payload: ISearchRequest = {searchModel: "DRIVER", searchType: searchParams?.value, searchValue: value}
    const {data: responseData} = await PartnerService.searchDriver(payload);
    if (responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
    else if (responseData.data.length === 0) toast.error('No driver with the profile information found');
    else setEntity(responseData.data[0])
    setLoading(false)
  }

  const assignVehicle = async (): Promise<void> => {
    try {
      let filter: string = `partners/assign-driver`
      let body: { driver: string, trip_id: string } = {driver: entity.user.email, trip_id: trip.id}
      await assignDriver({filter, body}).unwrap()
      handleClose()
    } catch (err: any) {
      toast.error(err.data.message)
    }
  }

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton><Modal.Title>Assign Vehicle to Trip</Modal.Title></Modal.Header>
      <Modal.Body>
        {entity.id ?
          <div className="row mb-2">
            <div className="col-sm-12">
              <div className="alert alert-success alert-dismissible fade show px-4 mb-0 text-center" role="alert">
                <i className="bx bx-check-double d-block display-4 mt-2 mb-3 text-success"></i>
                <h5 className="text-success">User Found</h5>
                <p><b>{entity.user.first_name} {entity.user.last_name}</b></p>
                <p><b>{entity.user.email} | {entity.user.phone}</b></p>
              </div>
            </div>
          </div> :
          <div className="row mb-2">
            <div className="col-md-12">
              <div className="mb-3">
                <label htmlFor="formrow-email-input" className="form-label">Select Search Parameter</label>
                <Select
                  aria-label="signatory"
                  options={AssignSearchParams}
                  getOptionLabel={(each: ITripSearchParams): string => `${each.label} (${each.description})`}
                  isClearable={false}
                  isSearchable={true}
                  maxMenuHeight={250}
                  styles={selectStyles}
                  menuPlacement="bottom"
                  onChange={(option: SingleValue<ITripSearchParams>) => updateSearchParam(option)}
                />
              </div>
            </div>
            {searchParams &&
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="formrow-password-input" className="form-label">{searchParams?.label}</label>
                  <input type="text" className="form-control" placeholder={searchParams?.description} value={value}
                         onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
                  />
                </div>
              </div>
            }
          </div>
        }
        <div className="mt-2 float-end">
          {entity.id ?
            <>
              <button className="btn btn-info w-md" onClick={searchAgain}>Search Again</button>
              <button className="btn btn-success w-md mx-1" onClick={assignVehicle}>
                Assign Vehicle {loading && <i className="bx bx-loader bx-spin"/>}
              </button>
            </> :
            <button className="btn btn-primary w-md" onClick={findAssignment}>
              Find Vehicle {loading && <i className="bx bx-loader bx-spin"/>}
            </button>
          }
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AssignVehicleModal;
