interface Props {
  icon?: string
  text: string
  value: string
}

const NumberCard = ({icon, text, value}: Props) => (
  <div className="card card-body">
    <div className="d-flex justify-content-between">
      <div>
        <h6 className="font-size-15">{text}</h6>
        <h4 className="mt-3 pt-1 mb-0 font-size-22">{value}</h4>
      </div>
      {icon &&
        <div className="avatar">
          <div className="avatar-title rounded bg-primary-subtle">
            <i className={`bx ${icon} font-size-24 mb-0 text-primary`}/>
          </div>
        </div>
      }
    </div>
  </div>
)

export default NumberCard;
